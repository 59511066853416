import React from 'react';
import './style.scss';
import {observer} from 'mobx-react';

import { Worker } from '@react-pdf-viewer/core';
import { Viewer, SpecialZoomLevel, ProgressBar } from '@react-pdf-viewer/core';
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';


import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

/* const renderPage = (props) => (
    <>
        {props.canvasLayer.children}
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
            }
        }>
            <div
                style={{
                    color: 'rgba(0, 0, 0, 0.2)',
                    fontSize: `${8 * props.scale}rem`,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    transform: 'rotate(-45deg)',
                    userSelect: 'none',
                }}
            >
                Draft
            </div>
        </div>
        {props.annotationLayer.children}
        {props.textLayer.children}
    </>
); */

const PDFViewer = observer((props) => {
    const $ = require('jquery');
    const renderToolbar = ((Toolbar) => (
        <>
            <Toolbar>
                {
                    (slots) => {
                        const {
                            CurrentPageInput, Download, EnterFullScreen, GoToNextPage, GoToPreviousPage,
                            NumberOfPages, Print, ShowSearchPopover, Zoom, ZoomIn,
                            ZoomOut, CurrentPageLabel
                        } = slots;
                        return (
                            <div className="MainToolbar">
                                <div className="ToolbarBtns">
                                    <div style={{ padding: '0px 2px' }}>
                                        <ShowSearchPopover />
                                    </div>
                                    <div style={{ padding: '0px 2px' }}>
                                        <ZoomOut />
                                    </div>
                                    <div style={{ padding: '0px 2px' }}>
                                        <Zoom />
                                    </div>
                                    <div style={{ padding: '0px 2px' }}>
                                        <ZoomIn />
                                    </div>
                                    <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                        <GoToPreviousPage />
                                    </div>
                                    <div style={{ padding: '0px 2px' }}>
                                        <CurrentPageInput /> / <NumberOfPages />
                                    </div>
                                    <div style={{ padding: '0px 2px' }}>
                                        <GoToNextPage />
                                    </div>
                                    <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                        <EnterFullScreen />
                                    </div>
                                    <div style={{ padding: '0px 2px' }}>
                                        <Download />
                                    </div>
                                    <div style={{ padding: '0px 2px' }}>
                                        <Print />
                                    </div>    
                                </div>
                            </div>
                        )
                    }
                }
            </Toolbar>
            
        </>
    ));
    const bookmarkPluginInstance = bookmarkPlugin();
    
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: defaultTabs => [
            // Remove the attachments tab (`defaultTabs[2]`)
            defaultTabs[0], // Thumbnails tab
            //defaultTabs[1], // Bookmarks tab
        ],
        renderToolbar
    });
    const { activateTab } = defaultLayoutPluginInstance;

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <div className="pdfViewer">
                <Viewer 
                    fileUrl={props.pdfURL}
                    plugins={[
                        defaultLayoutPluginInstance,
                        bookmarkPluginInstance,
                    ]}
                    defaultScale={SpecialZoomLevel.PageFit}
                    //renderPage={renderPage}
                    onDocumentLoad={()=>{ activateTab(0) }}
                />
            </div>
        </Worker>
    );
});

export default PDFViewer;