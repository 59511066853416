import React from 'react';

import './styles.scss';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import meetingStore from '../../../../stores/meetingStore';
import MenteereLoader from '../../../../components/MenteereLoader';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import noResultFound from '../../../../assets/images/noResultFound.svg';
import moment from 'moment';
import mentorshipStore from '../../../../stores/mentorshipStore';
import Rating from '@material-ui/lab/Rating';

@observer class RatingsAndReviews extends React.Component {

    componentWillMount() {
        mentorshipStore.getMyRatings();
    }

    render() {
        let Model = mentorshipStore.RatingsAndReviewsList;
        return (
            <DashboardBody>
                <div className="row RatingsAndReviewsPage">
                    <div className="col-md-12">
                        <div className="pageTitle mb-4">
                            <h4>Ratings & Reviews</h4>
                        </div>
                    </div>
                    {Model.loading ? (
                        <div className="text-center mb-5 mt-5 col-md-12">
                            <MenteereLoader type="spinner" />
                        </div>
                    ) : null}

                    <div className="col-md-12">
                        {!Model.loading && Model.data != null ? (
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>Overall star ratings for:</h5>
                                    <div class="RatingBoxes mt-5">
                                        <div className="RateItm">
                                            <label>Teaching Approach</label>
                                            <Rating
                                                name="rate-item-1"
                                                value={Model.data.skillRatings.Area1}
                                                readOnly
                                            />
                                        </div>
                                        <div className="RateItm">
                                            <label>Subject Knowledge</label>
                                            <Rating
                                                name="rate-item-2"
                                                value={Model.data.skillRatings.Area2}
                                                readOnly
                                            />
                                        </div>
                                        <div className="RateItm">
                                            <label>Communication</label>
                                            <Rating
                                                name="rate-item-3"
                                                value={Model.data.skillRatings.Area3}
                                                readOnly
                                            />
                                        </div>
                                        <div className="RateItm">
                                            <label>Friendliness</label>
                                            <Rating
                                                name="rate-item-4"
                                                value={Model.data.skillRatings.Area4}
                                                readOnly
                                            />
                                        </div>
                                        <div className="RateItm">
                                            <label>Approachability</label>
                                            <Rating
                                                name="rate-item-5"
                                                value={Model.data.skillRatings.Area5}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="TopReviewsSec">
                                        <h5>Top Reviews</h5>
                                        <br />
                                        {Model.data.list.map((item) =>
                                            <div className="row RateItem">
                                                <div className="col-md-1">
                                                    <div className="UserImg">
                                                        <img src={item.UserProfilePicURL} />
                                                    </div>
                                                </div>
                                                <div className="col-md-11">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-6">
                                                            <strong>{item.UserName}</strong>
                                                        </div>
                                                        <div className="col-md-6 text-right">
                                                            <p>{item.ReviewDate}</p>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="descBox">
                                                                <p>{item.UserReview}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {Model.data.length == 0 ? (
                                        <div className="noResultsFound col-md-12 text-center">
                                            <img src={noResultFound} />
                                            <h4>It's a bit empty here.... </h4>
                                            <p>No Ratings yet</p>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </DashboardBody>
        );
    }
}
export default RatingsAndReviews;

