import { makeAutoObservable } from 'mobx';
import Service from '../shared/service/service';

class Store {

    constructor() {
        makeAutoObservable(this);
    }

    MySubscriptions={
        infoLoading: true,
        data: null
    }

    getMySubscriptions() {
        this.MySubscriptions.infoLoading = true;
        Service.get(
        'getMySubscription',
        (status, response) => {
            this.MySubscriptions.infoLoading = false;
            this.MySubscriptions.data = response.data;
        },
        ).catch(error => {
            this.MySubscriptions.infoLoading = false;
            Service.handleError(error);
        });
    }

}

const subscriptionStore = new Store();
export default subscriptionStore;