import React from 'react';
import {observer} from 'mobx-react';
import MenteeDashboardBody from '../../../../components/Containers/MenteeDashboardBody';
import MenteereLoader from '../../../../components/MenteereLoader';
import { Link } from 'react-router-dom';
import './styles.scss';
import notify from '../../../../components/notify';
import myprofilebanner from '../../../../assets/images/myprofilebanner.svg';
import pencil from '../../../../assets/images/pencil.svg';
import menteerProfileStore from '../../../../stores/menteer/profileStore';
import ProfilePictureBadge from '../../../../components/ProfilePictureBadge';
import {Modal} from 'react-bootstrap';
import BoundInput from '../../../../components/Form/BoundInput';

const $ = require('jquery');

@observer class MenteeMyProfile extends React.Component {

    componentDidMount() {
        this.getMyProfile();
    }

    getMyProfile() {
        menteerProfileStore.getMyProfile();
    }

    changePassword() {
        if(menteerProfileStore.myprofile.ChangePassInput.oldPassword == '') {
            notify('Please enter Old Password', 'danger');
            return false;
        }
        if(menteerProfileStore.myprofile.ChangePassInput.newPassword == '') {
            notify('Please enter New Password', 'danger');
            return false;
        }
        if(menteerProfileStore.myprofile.ChangePassInput.confirmPassword == '') {
            notify('Please Confirm Password', 'danger');
            return false;
        }
        menteerProfileStore.updatePassword(()=> {
            notify('Passwords Updated Successfully');
            menteerProfileStore.myprofile.showChangePasswordPop = false;
        });
    }

    render() {
        const Model = menteerProfileStore.myprofile.data;
        return (
            <MenteeDashboardBody>
                <div className="MenteeMyProfile">
                {!menteerProfileStore.myprofile.infoLoading && menteerProfileStore.myprofile.data != null?(
                    <div className="row">                        
                        <div className="col-md-12 mb-2">                           
                            <div className=" row welcomeBg">
                                <div className="col-md-3">
                                    <img src={myprofilebanner} className="img-responsive MiniPic"/>
                                </div>
                                <div className="col-md-9 padd25">
                                    <h4 className="WelText">Welcome</h4> 
                                    <p className="paraWel">View/ Edit your profile</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 mb-4">
                            <h4>Personal Information</h4>
                        </div>
                        <div className="col-md-4">
                            {/* <Link className="editbtn"><img src={pencil} /> Edit</Link> */}
                            <button className="editbtn" onClick={()=> { menteerProfileStore.myprofile.showChangePasswordPop = true }}><img src={pencil} /> Change Password</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div className="col-md-2">
                            <ProfilePictureBadge />
                        </div>
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="">&nbsp;</p>
                                </div>
                                <div className="col-md-4 ProfileMeta mb-4">
                                    <p><strong>Name:</strong> {Model.userMeta.name}</p>
                                    <p><strong>Account Type:</strong> {Model.userMeta.is_mentor?'Menteer':'Mentee'}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <p>&nbsp;</p>
                        </div>
                        <div className="col-md-10 mb-4">
                            <h4>Account Details</h4>
                        </div>
                        <div className="col-md-2">
                            {/* <Link className="editbtn"><img src={pencil} /> Edit</Link> */}
                        </div>
                        <div className="col-md-2 ProfileMeta">
                            <p>Email</p>
                            <span>&nbsp;</span>
                            <p>Password</p>
                        </div>
                        <div className="col-md-2 ProfileMeta op7">
                            <p>{Model.userMeta.email}</p>
                            <span>&nbsp;</span>
                            <p>********</p>
                        </div>
                    </div>
                    ):(
                        <MenteereLoader />
                    )}
                </div>
                <Modal backdrop="static" show={menteerProfileStore.myprofile.showChangePasswordPop} dialogClassName="VerifyEmailPopup">
                        <Modal.Body>
                            <div class="row">
                                <div class="col-md-10 mb-4">
                                    <h4>Change Password</h4>
                                </div>
                                <div class="col-md-2 PopCloseIcon" onClick={()=>{ menteerProfileStore.myprofile.showChangePasswordPop = false }}>
                                    <span className="icon icon-close"></span>
                                </div>
                                <div class="col-md-12 mt-2">
                                    <label>Old Password</label>
                                    <BoundInput
                                        model={menteerProfileStore.myprofile.ChangePassInput}
                                        property="oldPassword"
                                    />
                                </div>
                                <div class="col-md-12 mt-2">
                                    <label>New Password</label>
                                    <BoundInput 
                                        model={menteerProfileStore.myprofile.ChangePassInput}
                                        property="newPassword"
                                    />
                                </div>
                                <div class="col-md-12 mt-2">
                                    <label>Confirm Password</label>
                                    <BoundInput 
                                        model={menteerProfileStore.myprofile.ChangePassInput}
                                        property="confirmPassword"
                                    />
                                </div>
                                <div class="col-md-12 mt-4 mb-2">
                                    <button type="button" class="btn btn-primary btn-bit-wide" onClick={()=> {
                                        this.changePassword();
                                    }} disabled={menteerProfileStore.myprofile.changePassLoading}>Update Password</button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
            </MenteeDashboardBody>
        );
    }
}

export default MenteeMyProfile;