import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import fb from '../../../assets/images/fb.svg';
import insta from '../../../assets/images/insta.svg';
import twitter from '../../../assets/images/twitter.svg';
import linkedinicon from '../../../assets/images/linkedinicon.svg';
import FooterBG from '../../../assets/images/FooterBG.svg';
import logo from '../../../assets/images/logo.png';
import './styles.scss';
import Snackbar from '@material-ui/core/Snackbar';
import rootStore from '../../../stores/rootStore';
import { Modal } from 'react-bootstrap';
import MenteereLoader from '../../MenteereLoader';
import { TextField } from '@material-ui/core';

@observer class Footer extends React.Component {

    render() {
        return (
            <footer className="rowes siteFooter" style={{ backgroundImage: `url(${FooterBG})` }}>
                <Snackbar
                    anchorOrigin={{ vertical: rootStore.toast.verpos, horizontal: rootStore.toast.horpos }}
                    open={rootStore.toast.visible}
                    onClose={() => { rootStore.toast.visible = false }}
                    autoHideDuration={3000}
                    message={rootStore.toast.text}
                    key={'vertical + horizontal'}
                />
                <Modal backdrop="static" show={rootStore.playlistPopup.visible} dialogClassName="PlaylistPopup">
                    <Modal.Body>
                        <div class="row">
                            <div class="col-md-10 mb-4">
                                <h5>Save to...</h5>
                            </div>
                            <div class="col-md-2 PopCloseIcon" onClick={() => { rootStore.playlistPopup.visible = false }}>
                                <span className="icon icon-close"></span>
                            </div>
                            <div class="col-md-12">
                                <div className="playlistItems">

                                    {rootStore.playlistPopup.playlistItemsLoading ? (
                                        <div className="text-center mb-5 mt-5">
                                            <MenteereLoader type="spinner" />
                                        </div>
                                    ) : null}
                                    {!rootStore.playlistPopup.playlistItemsLoading && rootStore.playlistPopup.playlistItems != null ? rootStore.playlistPopup.playlistItems.map((item) =>
                                        <div className="itmm" onClick={() => {
                                            rootStore.playlistPopup.inputs.playlist_id = item.id;
                                            rootStore.addToPlaylist();
                                        }}>{item.title}</div>
                                    ) : null}

                                    {!rootStore.playlistPopup.playlistItemsLoading && rootStore.playlistPopup.playlistItems != null ? (
                                        <>
                                            {rootStore.playlistPopup.playlistItems.length == 0 ? (
                                                <p className="mb-5 mt-4 text-center op4"><strong>No playlist here</strong></p>
                                            ) : null}
                                        </>
                                    ) : null}
                                </div>
                            </div>
                            {rootStore.playlistPopup.showCreatePlaylist ? (
                                <div className="col-md-12 createNewPlaylist">
                                    <TextField
                                        className="fullWidthInput"
                                        label="Enter Playlist Name"
                                        //helperText="*"
                                        value={rootStore.playlistPopup.inputs.title}
                                        onChange={(e) => {
                                            rootStore.playlistPopup.inputs.title = e.target.value;
                                        }}
                                        autoFocus
                                    />
                                    <button className="btn btn-primary float-right mt-4 mb-2" disabled={rootStore.playlistPopup.loading} onClick={() => {
                                        rootStore.addToPlaylist();
                                    }}>Create</button>
                                </div>
                            ) : (
                                <button className="CreateNewBtn" onClick={() => {
                                    rootStore.playlistPopup.showCreatePlaylist = true
                                }}>+ Create new playlist</button>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <img src={logo} />
                            <div className="SocialLinks">
                                <a href="#" target="_blank"><img src={fb} /></a>
                                <a href="#" target="_blank"><img src={insta} /></a>
                                <a href="#" target="_blank"><img src={twitter} /></a>
                                <a href="#" target="_blank"><img src={linkedinicon} className="img-responsive icon-width" /></a>
                            </div>
                        </div>
                        <div className="col-md-1">&nbsp;</div>
                        <div className="col-md-2">
                            <Link to={`${process.env.PUBLIC_URL}/aboutus`}>About Us</Link>
                            <Link to={`${process.env.PUBLIC_URL}/experts`}>Experts</Link>
                            <Link to={`${process.env.PUBLIC_URL}/notes`}>Notes</Link>
                            <Link to={`${process.env.PUBLIC_URL}/contactus`}>Contact</Link>
                        </div>
                        <div className="col-md-3 mt">
                            <p><a className="hoveColor" href="tel:+1 510-642-6000">+1 510-642-6000</a></p>
                            <p><a className="hoveColor" href="mailto:team@menteere.com">team@menteere.com</a></p>
                            {/* <Link href="tel:+1 510-642-6000">+1 510-642-6000</Link>
                            <Link href="mailto:team@menteere.com">team@menteere.com</Link> */}
                            {/* <Link>FIND A STORE</Link> */}
                        </div>
                        <div className="col-md-3">
                            <strong>Legal</strong>
                            <Link to={`${process.env.PUBLIC_URL}/termsofuse`}>Terms & Conditions</Link>
                            <Link to={`${process.env.PUBLIC_URL}/privacypolicy`}>Privacy Policy</Link>
                        </div>
                    </div>
                </div>
                <div className="footStrip">
                    <div className="container">
                        <div className="row">
                            <div className=" col-md-12">
                                <div className="SocialLinksTwo">
                                    <a href="#" target="_blank"><img src={fb} /></a>
                                    <a href="#" target="_blank"><img src={insta} /></a>
                                    <a href="#" target="_blank"><img src={twitter} /></a>
                                    <a href="#" target="_blank"><img src={linkedinicon} className="img-responsive icon-width"/></a>
                                </div>
                                <p>© Menteree 2020 | All rights Reserved | <Link to={`${process.env.PUBLIC_URL}/privacypolicy`}>Privacy Policy</Link> | <Link to={`${process.env.PUBLIC_URL}/termsofuse`}>Terms & Conditions</Link></p>
                            </div>                            
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default Footer;