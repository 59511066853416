import React from 'react';
import lastReadNotesBanner from '../../../../assets/images/lastReadNotesBanner.svg';
import OpenBook from '../../../../assets/images/OpenBook.svg';
import './styles.scss';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import notesStore from '../../../../stores/notesStore';
import MenteereLoader from '../../../../components/MenteereLoader';
import NoteBox from '../../../../components/Notes/NoteBox';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import noResultFound from '../../../../assets/images/noResultFound.svg';


@observer class MenteereLastReadNotes extends React.Component {

    componentWillMount() {
        notesStore.getLastReadNotes();
    }

    render() {
        return (
            <DashboardBody>
                <div className="row LastReadNotesPage">
                    <div className="col-md-12">                   
                        <div className=" row BookBg">
                            <div className="col-md-2"> 
                                <img src={OpenBook} class="openBook img-responsive" />                              
                            </div>
                            <div className="col-md-10 padd25">
                                <h4 className="NoteText">Last Read Notes</h4> 
                                <p className="paranotes">Explore all your last read notes under one roof and if you experience any doubt in any chapter book a call with the menteer today!</p>
                            </div>
                        </div>                        
                        <div className="pageTitle mb-4">
                            <h4>Last Read Notes</h4>
                        </div>
                    </div>
                    {notesStore.lastReadNotes.loading ? (
                        <div className="text-center mb-5 mt-5 col-md-12">
                            <MenteereLoader type="spinner" />
                        </div>
                    ) : null}

                    <div className="col-md-12">
                        {!notesStore.lastReadNotes.loading && notesStore.lastReadNotes.data != null ? (
                            <div className="row">
                                {notesStore.lastReadNotes.data.map((item) =>
                                    <div className="col-md-4">
                                        <NoteBox data={item} />
                                    </div>
                                )}
                                {notesStore.lastReadNotes.data.length == 0 ? (
                                    <div className="noResultsFound col-md-12 text-center">
                                        <img src={noResultFound} />
                                        <h4>It's a bit empty here.... </h4>
                                        <p>Explore notes from the student of top universities</p>
                                        <Link to={`${process.env.PUBLIC_URL}/notes`} className="btn btn-primary">Explore Notes</Link>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>

                </div>
            </DashboardBody>
        );
    }
}

export default MenteereLastReadNotes;

