import React from 'react';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import './style.scss';
import {observer} from 'mobx-react';
import upDownload from '../../../../assets/images/upDownload.svg';
import BoundInput from '../../../../components/Form/BoundInput';
import notesStore from '../../../../stores/notesStore';
import BoundTextarea from '../../../../components/Form/BoundTextarea';
import notify from '../../../../components/notify';
import { Link } from 'react-router-dom';

import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'


@observer class EditNoteStep1 extends React.Component {

    componentDidMount() {
        notesStore.getNoteInfo(this.props.match.params.id, (resp)=> {
            notesStore.getCurriculums();
            notesStore.getSubjects();
            notesStore.getThemeBySubjectID(notesStore.myNoteInfo.data.subject_id);
            notesStore.getTopicByThemeID(notesStore.myNoteInfo.data.theme_id);
        });
    }

    submit(id) {
        notesStore.updateNote(id, ()=> {
           this.props.history.push(`${process.env.PUBLIC_URL}/editNoteStep2/${id}`);
        });
    }

    uploadNotePDF(id, file) {
        console.log(file);
        notesStore.uploadNotePDF(id, file, (resp)=> {
            notify('Note uploaded successfully');
        });
    }

    render() {

        return (
            <DashboardBody>
                <div className="MyNotesPage">
                    <div className="pageTitle">
                        <h3>Edit Notes</h3>
                    </div>
                    {!notesStore.myNoteInfo.loading && notesStore.myNoteInfo.data!=null?(
                    <div className="row">
                        <div className="col-md-6 form-group mb-5">
                            <select className="form-control" value={notesStore.myNoteInfo.data.note_type} onChange={(e)=> { notesStore.myNoteInfo.data.note_type = e.target.value }}>
                                <option value="">--SELECT NOTE TYPE--</option>
                                <option value={1}>Digital</option>
                                <option value={2}>Hand Written</option>
                            </select>
                        </div>
                        <div className="col-md-6 form-group">
                            <select className="form-control" value={notesStore.myNoteInfo.data.curriculum_id} onChange={(e)=> { notesStore.myNoteInfo.data.curriculum_id = e.target.value }}>
                                <option value="">--SELECT CURRICULUM--</option>
                                {notesStore.createNoteModel.curriculum.data!=null?notesStore.createNoteModel.curriculum.data.map((item) =>
                                    <option value={item.id}>{item.title}</option>
                                ):null}
                            </select>
                            {notesStore.createNoteModel.curriculum.loading?(
                                <i className="SelectLoader fa fa-spinner"></i>
                            ):null}
                        </div>
                        <div className="col-md-4 form-group">
                            <select className="form-control" value={notesStore.myNoteInfo.data.subject_id} onChange={(e)=> { 
                                notesStore.myNoteInfo.data.subject_id = e.target.value;
                                notesStore.getThemeBySubjectID(e.target.value);
                            }}>
                                <option value="">--SELECT SUBJECT--</option>
                                {notesStore.createNoteModel.subject.data!=null?notesStore.createNoteModel.subject.data.map((item) =>
                                    <option value={item.id}>{item.title}</option>
                                ):null}
                            </select>
                            {notesStore.createNoteModel.subject.loading?(
                                <i className="SelectLoader fa fa-spinner"></i>
                            ):null}
                        </div>
                        <div className="col-md-4 form-group">
                            <select className="form-control" value={notesStore.myNoteInfo.data.theme_id} onChange={(e)=> { 
                                notesStore.myNoteInfo.data.theme_id = e.target.value;
                                notesStore.getTopicByThemeID(e.target.value);
                            }}>
                                <option value="">--SELECT THEME--</option>
                                {notesStore.createNoteModel.theme.data!=null?notesStore.createNoteModel.theme.data.map((item) =>
                                    <option value={item.id}>{item.title}</option>
                                ):null}
                            </select>
                            {notesStore.createNoteModel.theme.loading?(
                                <i className="SelectLoader fa fa-spinner"></i>
                            ):null}
                        </div>
                        <div className="col-md-4 form-group">
                            <select className="form-control" value={notesStore.myNoteInfo.data.topic_id} onChange={(e)=> { notesStore.myNoteInfo.data.topic_id = e.target.value }}>
                                <option value="">--SELECT TOPIC--</option>
                                {notesStore.createNoteModel.topic.data!=null?notesStore.createNoteModel.topic.data.map((item) =>
                                    <option value={item.id}>{item.title}</option>
                                ):null}
                            </select>
                            {notesStore.createNoteModel.topic.loading?(
                                <i className="SelectLoader fa fa-spinner"></i>
                            ):null}
                        </div>
                        
                        <div className="col-md-6 form-group mt-4">
                            <label className="mb-2">Title of Your Notes*</label>
                            <BoundInput
                                data-style="underline"
                                model={notesStore.myNoteInfo.data}
                                property="title"
                                placeholder="Make it Interesting! Mentees will see this first when looking through notes (20-30 words)"
                            />
                        </div>
                        
                        <div className="col-md-8 form-group">
                            <label className="mb-4 mt-4">Description of Your Notes*</label>
                            <BoundTextarea
                                rows="4"
                                model={notesStore.myNoteInfo.data}
                                property="desc"
                                placeholder="Add a Description to your note which will be displayed to the site (120-130 words)"
                            />
                        </div>

                        <div className="col-md-8 form-group mt-3">
                            <label className="cursor-pointer">
                                <p className="PickCover"><img src={upDownload} className="upDownload" />Add PDF For Your Notes</p>
                                <input 
                                    type="file"
                                    id="uploadPdfInput"
                                    style={{ opacity: 0 }}
                                    onChange={(e) => {
                                        this.uploadNotePDF(notesStore.myNoteInfo.data.id, e.target.files[0]);
                                    }}
                                />
                            </label>
                            <br/>
                            {/* <span className="spanColor">Add a nice cover page to your notes. Good cover pages can attract more clicks, which is more profit!</span>                                           */}
                            {notesStore.uploadNotePDFModel.loading?(
                                <div className="col-md-12 loaderContainer">
                                    <Loader indeterminate active>Uploading File</Loader>
                                </div>
                            ):(
                                <>
                                    {notesStore.myNoteInfo.data.doc != ''?(
                                        <div className="uploadedFileContainer mt-3">
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png" className="fileThumbnail" />
                                            <div className="fileOverlay">
                                                <a href={notesStore.myNoteInfo.data.doc} target="_blank">View</a>
                                            </div>
                                        </div>
                                    ):null}
                                </>
                            )}
                        </div>

                        <div className="col-md-12 mt-4">
                            <button className="btn btn-primary btn-bit-wide ContinueBtn" onClick={()=> { this.submit(notesStore.myNoteInfo.data.id); }} disabled={notesStore.myNoteInfo.submitLoading}>Save & Continue</button>
                        </div>
                    </div>
                    ):'loading'}
                </div>
            </DashboardBody>
        );
    }
}

export default EditNoteStep1;