import { store } from "react-notifications-component";
import rootStore from "../stores/rootStore";

export default function notify(title='', type='success', desc=' ') {
    switch(type) {
        case 'snack':
            rootStore.toast.text = title;
            rootStore.toast.visible = true;
        break;
        default:
            store.addNotification({
                title: title,
                message: desc,
                type: type,
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: false,
                    showIcon: true
                }
            });
        break;
    }
};