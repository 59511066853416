import React, { Component } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

function ExpertsListNoResultsState() {
        return (
            <div className="NoResultsFound">
                No Results Found
            </div>
        );
}

export default ExpertsListNoResultsState;