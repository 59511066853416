import React from 'react';
import {observer} from 'mobx-react';
import { Redirect } from 'react-router-dom';
import videoCallStore from '../../stores/videoCallStore';
import './styles.scss';

@observer class VideoCallOperations extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {videoCallStore.redirect?<Redirect to={videoCallStore.redirect} />:null}
                {videoCallStore.showCallPop?(
                <div className="callAlertPop">
                    <div className="popBody">
                        <h2>Incoming Call</h2>
                        <div className="acBtns">
                            <button className="acceptCall" onClick={()=>{ videoCallStore.acceptIncomingCall() }}>Accept</button>
                            <button className="rejectCall" onClick={()=>{ videoCallStore.rejectIncomingCall() }}>Decline</button>
                        </div>
                    </div>   
                </div>
                ):null}
            </>
        );
    }
}
export default VideoCallOperations;