import React, { Component } from 'react';
import './style.scss';

import LeftQuote from '../../../assets/images/LeftQuote.svg'
import OBJECTS from '../../../assets/images/OBJECTS.svg'
import what from '../../../assets/images/what.svg'
import linkedin from '../../../assets/images/linkedin.svg'
import gmail from '../../../assets/images/gmail.svg'
import sukkriti from '../../../assets/images/sukkriti.png'
import Arunava from '../../../assets/images/arunava.png'
import Anshika from '../../../assets/images/anshika.png'
import Siddhant from '../../../assets/images/sidhant.png'



function MeetOur() {

    return(
        <div className="row OurMeet col-md-12">
            <div className="container">                
                <div className="row heddText">
                    <div className="col-md-12 text-center">
                        <h4 className="">Meet Our Squad</h4>
                    </div>
                </div> 
                <div className="row MeetText">
                    <div className="col-md-4">
                        <img src={sukkriti} className="Max-Width"/>
                    </div>
                    <div className="col-md-8">
                        <div className="HeddingText">
                            <h3>Sukkriti Nath</h3>
                            <p>Sukkriti Nath conceptualized Menteeré in early 2020. She is an undergraduate at UC Berkeley intending to major in Business Administration and Legal Studies. Within Business, her area of expertise is Marketing. Currently, she is a Social Media Content Manager for a health-tech firm; and a Marketing Consultant for.....</p>
                            <div className="row SocialIcons">
                                <div className="col-sm-6 col-md-6">
                                    <img src={linkedin} className="Max-Width"/>
                                    <img src={gmail} className="Max-Width"/>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row MeetText">                    
                    <div className="col-md-8">
                        <div className="HeddingText">
                            <h3>Anshika Malhotra</h3>
                            <p>Anshika Malhotra is pursuing Law (LLB) at UCL and is a founding member at Menteeré. Her skills span leadership, communication, and marketing as she takes pride in having independently designed and curated a self sanitising door handle in a world stationed at the juncture of contamination.....</p>
                            <div className="row SocialIcons">
                                <div className="col-sm-6 col-md-6">
                                    <img src={linkedin} className="Max-Width"/>
                                    <img src={gmail} className="Max-Width"/>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img src={Anshika} className="Max-Width"/>
                    </div>
                </div>
                <div className="row MeetText">
                <div className="col-md-4">
                        <img src={Arunava} className="Max-Width"/>
                    </div>
                    <div className="col-md-8">
                        <div className="HeddingText">
                            <h3>Arunava Nag</h3>
                            <p>Arunava Nag is a freshman in UC Berkeley, looking to double major in Statistics and Business and Administration. His skill set ranges from leadership to experience in procuring credit, funding, organizing teams and navigating the tax system. As the founder of a coins and collectables company that has...</p>
                            <div className="row SocialIcons">
                                <div className="col-sm-6 col-md-6">
                                    <img src={linkedin} className="Max-Width"/>
                                    <img src={gmail} className="Max-Width"/>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <div className="row MeetText">
                    <div className="col-md-8">
                        <div className="HeddingText">
                            <h3>Siddhant Sharma</h3>
                            <p>Siddhant is a freshman at UC Berkeley majoring in Electrical Engineering and Computer Science. He has experience with a wide array of technological stacks, from website development to prototyping to machine learning. On the business side, he has interned at a transportation logistics startup....</p>
                            <div className="row SocialIcons">
                                <div className="col-sm-6 col-md-6">
                                    <img src={linkedin} className="Max-Width"/>
                                    <img src={gmail} className="Max-Width"/>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div className="col-md-4">
                        <img src={Siddhant} className="Max-Width"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MeetOur;