import React from 'react';
import DashboardBody from '../../../components/Containers/DashboardBody/DashboardBody';
import greencheck from '../../../assets/images/greencheck.svg';
import './style.scss';

class VerifyProfileThankYou extends React.Component {

    render() {
        return (
            <DashboardBody>
                <div className="VerifyProfileThankYou">
                    <div className="col-md-12 text-center mt-5">
                        <img src={greencheck} className="mb-4" />
                        <h2>Your account is under review!</h2>
                        <p className="ParaText">We will verify your documents and let you know whenyou can start<br></br> mentoring students and publishing notes!</p>
                    </div>
                </div>
            </DashboardBody>
        );
    }
}

export default VerifyProfileThankYou;