import { makeAutoObservable } from 'mobx';
import Service from '../../shared/service/service';

class Store {

    constructor() {
        makeAutoObservable(this);
    }

    editProfileModel={
        infoLoading: false,
        submitLoading: false,
        doc_uploads: {},
        infoData: null
    };

    myprofile={
        infoLoading: true,
        data: null,
        showChangePasswordPop: false,
        changePassLoading: false,
        ChangePassInput: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        ChangePassInitialInput: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        }
    }

    getMyProfile() {
        this.myprofile.infoLoading = true;
        Service.get(
        'myProfile',
        (status, response) => {
            this.myprofile.infoLoading = false;
            this.myprofile.data = response.data;
        },
        ).catch(error => {
            this.myprofile.infoLoading = false;
            Service.handleError(error);
        });
    }

    getPrefilledInfo() {
        this.editProfileModel.infoLoading = true;
        Service.get(
        'edit_verify_user_step_1',
        (status, response) => {
            this.editProfileModel.infoLoading = false;
            this.editProfileModel.infoData = response.data;
            console.log(response.data);
        },
        ).catch(error => {
            this.editProfileModel.infoLoading = false;
            Service.handleError(error);
            //GLOBAL.CustomToast.show(this.errorMsg);
        });
    }

    update(callback) {
        this.editProfileModel.submitLoading = true;
        Service.post(
        'verify_user_step_1',
        this.editProfileModel.infoData.prefilledInfo,
        (status, response) => {
            this.editProfileModel.submitLoading = false;
            return callback();
        },
        ).catch(error => {
            this.editProfileModel.submitLoading = false;
            Service.handleError(error);
            //GLOBAL.CustomToast.show(this.errorMsg);
        });
    }

    updatePassword(callback) {
        this.myprofile.changePassLoading = true;
        Service.post(
        'changePassword',
        this.myprofile.ChangePassInput,
        (status, response) => {
            this.myprofile.changePassLoading = false;
            this.myprofile.ChangePassInput = this.myprofile.ChangePassInitialInput;
            return callback();
        },
        ).catch(error => {
            this.myprofile.changePassLoading = false;
            Service.handleError(error);
        });
    }

}

const menteerProfileStore = new Store();
export default menteerProfileStore;