import React from 'react';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import './style.scss';
import {observer} from 'mobx-react';
import BoundInput from '../../../../components/Form/BoundInput';
import notesStore from '../../../../stores/notesStore';
import { Link } from 'react-router-dom';
import BoundTextarea from '../../../../components/Form/BoundTextarea';

@observer class AddNewNote extends React.Component {

    componentDidMount() {
        notesStore.getCurriculums();
        notesStore.getSubjects();
    }

    submit() {
        notesStore.storeNote((id)=> {
           this.props.history.push(`${process.env.PUBLIC_URL}/editNoteStep1/${id}`);
        });
    }

    render() {

        return (
            <DashboardBody>
                <div className="MyNotesPage">
                    <div className="pageTitle mb-5">
                        <h3>Add Notes</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group mb-5">
                            <select className="form-control" value={notesStore.createNoteModel.inputs.note_type} onChange={(e)=> { notesStore.createNoteModel.inputs.note_type = e.target.value }}>
                                <option value="">--SELECT NOTE TYPE--</option>
                                <option value={1}>Digital</option>
                                <option value={2}>Hand Written</option>
                            </select>
                        </div>
                        <div className="col-md-6 form-group">
                            <select className="form-control" value={notesStore.createNoteModel.inputs.curriculum_id} onChange={(e)=> { notesStore.createNoteModel.inputs.curriculum_id = e.target.value }}>
                                <option value="">--SELECT CURRICULUM--</option>
                                {notesStore.createNoteModel.curriculum.data!=null?notesStore.createNoteModel.curriculum.data.map((item) =>
                                    <option value={item.id}>{item.title}</option>
                                ):null}
                            </select>
                            {notesStore.createNoteModel.curriculum.loading?(
                                <i className="SelectLoader fa fa-spinner"></i>
                            ):null}
                        </div>
                        <div className="col-md-4 form-group">
                            <select className="form-control" value={notesStore.createNoteModel.inputs.subject_id} onChange={(e)=> { 
                                notesStore.createNoteModel.inputs.subject_id = e.target.value;
                                notesStore.getThemeBySubjectID(e.target.value);
                            }}>
                                <option value="">--SELECT SUBJECT--</option>
                                {notesStore.createNoteModel.subject.data!=null?notesStore.createNoteModel.subject.data.map((item) =>
                                    <option value={item.id}>{item.title}</option>
                                ):null}
                            </select>
                            {notesStore.createNoteModel.subject.loading?(
                                <i className="SelectLoader fa fa-spinner"></i>
                            ):null}
                        </div>
                        <div className="col-md-4 form-group">
                            <select className="form-control" value={notesStore.createNoteModel.inputs.theme_id} onChange={(e)=> { 
                                notesStore.createNoteModel.inputs.theme_id = e.target.value;
                                notesStore.getTopicByThemeID(e.target.value);
                            }}>
                                <option value="">--SELECT THEME--</option>
                                {notesStore.createNoteModel.theme.data!=null?notesStore.createNoteModel.theme.data.map((item) =>
                                    <option value={item.id}>{item.title}</option>
                                ):null}
                            </select>
                            {notesStore.createNoteModel.theme.loading?(
                                <i className="SelectLoader fa fa-spinner"></i>
                            ):null}
                        </div>
                        <div className="col-md-4 form-group">
                            <select className="form-control" value={notesStore.createNoteModel.inputs.topic_id} onChange={(e)=> { notesStore.createNoteModel.inputs.topic_id = e.target.value }}>
                                <option value="">--SELECT TOPIC--</option>
                                {notesStore.createNoteModel.topic.data!=null?notesStore.createNoteModel.topic.data.map((item) =>
                                    <option value={item.id}>{item.title}</option>
                                ):null}
                            </select>
                            {notesStore.createNoteModel.topic.loading?(
                                <i className="SelectLoader fa fa-spinner"></i>
                            ):null}
                        </div>
                        <div className="col-md-6 form-group mt-4">
                            <label className="mb-2">Title of Your Notes*</label>
                            <BoundInput
                                data-style="underline"
                                model={notesStore.createNoteModel.inputs}
                                property="title"
                                placeholder="Make it Interesting! Mentees will see this first when looking through notes (20-30 words)"
                            />
                        </div>
                        
                        <div className="col-md-8 form-group">
                            <label className="mb-4 mt-4">Description of Your Notes*</label>
                            <BoundTextarea
                                rows="4"
                                model={notesStore.createNoteModel.inputs}
                                property="desc"
                                placeholder="Add a Description to your note which will be displayed to the site (120-130 words)"
                            />
                        </div>

                        <div className="col-md-12">
                            <button className="btn btn-primary btn-bit-wide" onClick={()=> { this.submit(); }} disabled={notesStore.createNoteModel.submitLoading}>Save & Continue</button>
                        </div>
                    </div>
                </div>
            </DashboardBody>
        );
    }
}

export default AddNewNote;