import React from 'react';
import DashboardBody from '../../../components/Containers/DashboardBody/DashboardBody';
import rightArrowWhite from '../../../assets/images/rightArrowWhite.svg';
import startmentoring from '../../../assets/images/startmentoring.svg';
import verifytoday from '../../../assets/images/verifytoday.svg';
import v_underReview from '../../../assets/images/v_underReview.svg';
import v_rejected from '../../../assets/images/v_rejected.svg';
import dashverifyemail from '../../../assets/images/dashverifyemail.svg';
import check from '../../../assets/images/check.svg';
import yournotes from '../../../assets/images/yournotes.svg';



import './style.scss';
import { Link } from 'react-router-dom';
import {observer} from 'mobx-react';
import dashboardStore from '../../../stores/dashboardStore';
import notify from '../../../components/notify';
import {Modal} from 'react-bootstrap';
import BoundInput from '../../../components/Form/BoundInput';

@observer class Dashboard extends React.Component {

    componentWillMount() {
        dashboardStore.getDashboard();
    }

    verifyEmail() {
        if(dashboardStore.verifyEmailModel.input.otp == ''){
            notify('Please enter OTP', 'danger');
            return false;
        }
        dashboardStore.verifyEmail(()=> {
            dashboardStore.dashModel.verifyemailPopVisible = false;
            dashboardStore.dashModel.data.userInfo.email_verified = true;
            notify('Email has been verified successfully', 'success');
        });
    }

    render() {
        let data = dashboardStore.dashModel.data;
        return (
            <DashboardBody>
                {!dashboardStore.dashModel.infoLoading && dashboardStore.dashModel.data!=null?(
                <div className="DashboardPage">
                    <div className="pageTitle">
                        <h4>Welcome {data.userInfo.name} {data.userInfo.verification_status == 1?(<img src={check} className="checkIcon" />):null}</h4>
                    </div>
                    <div className="row statsBoxes">
                            <div className="statsBox col-md-3">
                                <div className="statsInBox">
                                    <p>{dashboardStore.dashModel.data.meta.notesUploadCount}</p>
                                    <span>Notes Uploaded</span>
                                    <div className="arrow">
                                        <img src={rightArrowWhite} />
                                    </div>
                                </div>
                            </div>
                            <div className="statsBox col-md-3">
                                <div className="statsInBox">
                                    <p>{dashboardStore.dashModel.data.meta.mentorshipGivenCount}</p>
                                    <span>Mentorships Given</span>
                                    <div className="arrow">
                                        <img src={rightArrowWhite} />
                                    </div>
                                </div>
                            </div>
                            <div className="statsBox col-md-3">
                                <div className="statsInBox">
                                    <p>{dashboardStore.dashModel.data.meta.mentorshipTakenCount}</p>
                                    <span>Mentorships Taken</span>
                                    <div className="arrow">
                                        <img src={rightArrowWhite} />
                                    </div>
                                </div>
                            </div>
                            <div className="statsBox col-md-3">
                                <div className="statsInBox">
                                    <p>{dashboardStore.dashModel.data.meta.currentRating||0}</p>
                                    <span>Current Ratings</span>
                                    <div className="arrow">
                                        <img src={rightArrowWhite} />
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3 boxpic">
                            <Link to={`${process.env.PUBLIC_URL}/mynotes`}>
                                <img src={startmentoring} className="SvgSet" />                                
                            </Link>
                        </div>
                        {data.userInfo.verification_status == 0?(
                            <div className="col-md-6 boxpic">
                                <Link to={`${process.env.PUBLIC_URL}/verify-profile-1`}>
                                    <img src={verifytoday} />
                                </Link>
                            </div>
                        ):null}
                        {data.userInfo.verification_status == 2?(
                            <div className="col-md-6 boxpic">
                                <img src={v_underReview} />
                            </div>
                        ):null}
                        {data.userInfo.verification_status == -1?(
                            <div className="col-md-6 boxpic">
                                <img src={v_rejected} />
                            </div>
                        ):null}                          
                        {!data.userInfo.email_verified?(
                            <div className="col-md-6 boxpic">
                                <div className="VeriEmailbtn" onClick={()=> {
                                    dashboardStore.dashModel.verifyemailPopVisible = true;
                                    dashboardStore.initVerifyEmail();
                                    }}>
                                    <img src={dashverifyemail} />
                                </div>
                            </div>
                        ):null}
                        
                    </div>
                </div>
                ):'loading'}
                <Modal backdrop="static" show={dashboardStore.dashModel.verifyemailPopVisible} dialogClassName="VerifyEmailPopup">
                    <Modal.Body>
                        <div class="row">
                            <div class="col-md-10 mb-4">
                                <h4>Verify Email</h4>
                            </div>
                            <div class="col-md-2 PopCloseIcon" onClick={()=>{ dashboardStore.dashModel.verifyemailPopVisible = false }}>
                                <span className="icon icon-close"></span>
                            </div>
                            <div class="col-md-12 mt-2">
                                <BoundInput 
                                    model={dashboardStore.verifyEmailModel.input}
                                    property="otp"
                                    placeholder="Enter OTP"
                                />
                            </div>
                            <div class="col-md-12 mt-4 mb-2">
                                <button type="button" class="btn btn-primary btn-bit-wide" onClick={()=> {
                                    this.verifyEmail();
                                }} disabled={dashboardStore.verifyEmailModel.loading}>Submit</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </DashboardBody>
        );
    }
}

export default Dashboard;

