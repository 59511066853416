import React from 'react';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let GetBaseFromPdf = async(url, pageNum) => {

    const loadingTask = pdfjs.getDocument(url);
    const pdf = await loadingTask.promise;

    const canvas = document.createElement('canvas');
    try {
        const page = await pdf.getPage(pageNum);
        var thumbnailWidth = 300;
        
        var w = thumbnailWidth;
        var scale = w / page.getViewport({ scale: 1 }).width;
        const viewport = page.getViewport({ scale: scale });

        var pageWidth = viewport.width;
        var pageHeight = viewport.height;
        var h = w / (pageWidth / pageHeight);
        canvas.height = h;
        canvas.width = w;
        canvas.style.height = h + "px";
        canvas.style.width = w + "px";

        let i = await page.render({
            canvasContext: canvas.getContext('2d'),
            viewport: viewport
        });

        return await i.promise.then(function () { 
            return canvas.toDataURL();
        });
    } catch (e) {
        return '';
    }   
  
}
export default GetBaseFromPdf;