import React from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';

const BoundInput = observer(({ property, model, ...props }) => 
    <input
        type="text"
        className="form-control"
        name={property}
        value={model[property]}
        onChange={e =>
            (model[property] = e.target.value)
        }
        {...props}
    />
);

BoundInput.propTypes = {
    model: PropTypes.object.isRequired,
    property: PropTypes.string.isRequired
}

export default BoundInput;