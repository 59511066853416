import { makeAutoObservable } from 'mobx';
import Service from '../shared/service/service';
import axios from 'axios';
import CONSTANTS from '../shared/constants';
import constants from '../shared/constants';
import { Router, Redirect } from 'react-router';

const baseUrl = CONSTANTS.API_BASE;

const QuickBlox = require('quickblox/quickblox').QuickBlox;
const QB = new QuickBlox();

class Store {
    constructor() {
        makeAutoObservable(this);
        this.QbInit();
    }

    redirect = null;
    showCallPop = false;
    
    callStatus = 'CHAT_INIT';
    isMute = false;
    videoMute = false;
    callSession = '';

    StatusText = {
        'CHAT_INIT': 'Connecting to Streaming Servers',
        'CHAT_INIT_SUCCESS': 'Successfully Connected to Streaming Servers',
        'STREAM_STARTED': 'Remote streaming working',
        'CALLING': 'Calling to oppenent',
        'CALL_ACCEPTED': 'Call Accepted',
        'CALL_REJECTED': 'Call Rejected',
        'CALL_NOT_ANSWERED': 'Call not answered',
        'GETTING_LOCAL_STREAM': 'Getting Local Stream Media',
        'CALL_ENDED': 'Call has been ended',
        'CALL_SESSION_CLOSED': 'Call session has been closed',
        'CALL_IN_SESSION_CONNECTING': 'Connecting to oponent',
        'CALL_IN_SESSION_CONNECTED': 'Call Session Active',
        'CALL_IN_SESSION_CLOSED': 'Connection Closed',
        'CALL_IN_SESSION_FAILED': 'Connection Failed',
        'CALL_IN_SESSION_COMPLETED': 'Opponent Session Completed',
    }

    APPLICATION_ID = constants.QUICKBLOX_APP_ID;
    AUTH_KEY = constants.QUICKBLOX_AUTH_KEY;
    AUTH_SECRET = constants.QUICKBLOX_AUTH_SECRET;
    ACCOUNT_KEY = constants.QUICKBLOX_ACCOUNT_KEY;
    CONFIG = { 
        debug: true,
        // endpoints: {
        //     api: "apicustomdomain.quickblox.com", // set custom API endpoint
        //     chat: "chatcustomdomain.quickblox.com", // set custom Chat endpoint
        // },
        chatProtocol: {
            active: 2, // set 1 to use BOSH, set 2 to use WebSockets (default)
        },
        streamManagement: {
            enable: true
        }
    };

    QbInit() {
        if(localStorage.getItem('userID')) {
            QB.init(this.APPLICATION_ID, this.AUTH_KEY, this.AUTH_SECRET, this.ACCOUNT_KEY, this.CONFIG);

            if(!localStorage.getItem('QUICK_USER_TOKEN')) {   
                var params = { login: localStorage.getItem('userID'), password: 'menteere' };  
                QB.createSession(params, function(error, result) {
                    // callback function
                    if(result != null) {
                        localStorage.setItem('QUICK_CREATE_SESSION_RESPONSE', result);
                        localStorage.setItem('QUICK_USER_TOKEN', result.token);
                    }
                });
            }

            QB.webrtc.onRemoteStreamListener = function(session, userID, remoteStream) {
                // attach the remote stream to DOM element
                session.attachMediaStream("frontVideoElem", remoteStream);
                videoCallStore.callStatus = 'STREAM_STARTED';
            };

            QB.webrtc.onAcceptCallListener = function(session, userId, extension) {
                videoCallStore.callStatus = 'CALL_ACCEPTED';
            };

            QB.webrtc.onRejectCallListener = function(session, userId, extension) {
                videoCallStore.callStatus = 'CALL_REJECTED';
            };

            QB.webrtc.onStopCallListener = function(session, userId, extension) {
                videoCallStore.callStatus = 'CALL_ENDED';
            };

            QB.webrtc.onUserNotAnswerListener = function(session, userId) {
                videoCallStore.callStatus = 'CALL_NOT_ANSWERED';
            };

            // QB.webrtc.onSessionCloseListener = function(session) {
            //     videoCallStore.callStatus = 'CALL_SESSION_CLOSED';
            // };

            var _this = this;
            QB.webrtc.onCallListener = function(session, extension) {
                _this.showCallPop = true;
                videoCallStore.callSession = session;
            };

            QB.webrtc.onSessionConnectionStateChangedListener = function(session, userId, connectionState) { 
                switch(connectionState) {
                    case 1:
                        videoCallStore.callStatus = 'CALL_IN_SESSION_CONNECTING';
                    break;
                    case 2:
                        videoCallStore.callStatus = 'CALL_IN_SESSION_CONNECTED';
                    break;
                    case 3:
                        videoCallStore.callStatus = 'CALL_IN_SESSION_CLOSED';
                    break;
                    case 4:
                        videoCallStore.callStatus = 'CALL_IN_SESSION_FAILED';
                    break;
                    case 5:
                        videoCallStore.callStatus = 'CALL_IN_SESSION_COMPLETED';
                    break;
                }
            }
            
            QB.chat.connect({ userId: localStorage.getItem('quickbloxId'), password: 'menteere' }, function (err, roster) {
                //_this.makeCall();
            });
        }
    }

    async acceptIncomingCall() {
        this.redirect = '/VideoCall';
        this.showCallPop = false;
        var mediaParams = {
          audio: true,
          video: true,
          options: {
            muted: true,
            mirror: true
          },
          elemId: 'curUserVidElem'
        };
        videoCallStore.callSession.getUserMedia(mediaParams, function(err, stream) {
            if (err) { console.log('error getting user media:', err) } else {
                setTimeout(function(){
                    videoCallStore.callSession.accept({});
                }, 2000);
            }
        });
    }

    async rejectIncomingCall() {
        this.showCallPop = false;
        videoCallStore.callSession.reject({});
    }

    async createUser(userID, Name) {
        const APPLICATION_ID = this.APPLICATION_ID;
        const AUTH_KEY = this.AUTH_KEY;
        const AUTH_SECRET = this.AUTH_SECRET;
        const ACCOUNT_KEY = this.ACCOUNT_KEY;
        const CONFIG = this.CONFIG;
        var promise = new Promise(function(resolve, reject) {
            QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG);
            QB.createSession(function(error, result) {
                var params = {
                    login: userID,
                    password: "menteere",
                    full_name: Name
                };
                QB.users.create(params, async function(error, result) {
                    if (error) {
                        console.log(error);
                        resolve('error');
                    } else {
                        resolve(result.id);
                    }
                });
            });
        })
        return promise.then(id => {
            return id;
        })
    }

    makeCall(id) {
        var calleesIds = [id]; // Users' ids
        var sessionType = QB.webrtc.CallType.VIDEO; // AUDIO is also possible
        var additionalOptions = {};
        videoCallStore.callSession = QB.webrtc.createNewSession(calleesIds, sessionType, null, additionalOptions);
        videoCallStore.callStatus = 'GETTING_LOCAL_STREAM';
        var mediaParams = {
            audio: true,
            video: true,
            options: {
                muted: false,
                mirror: false,
            },
            webrtc: {
                answerTimeInterval: 10,
                autoReject: true,
                incomingLimit: 1,
                dialingTimeInterval: 5,
                disconnectTimeInterval: 30,
                statsReportTimeInterval: false,
            }
        };
        videoCallStore.callSession.getUserMedia(mediaParams, function (error, stream) {
            if (!error) {
                videoCallStore.callSession.attachMediaStream('curUserVidElem', stream);
                videoCallStore.callStatus = 'CALLING';
                videoCallStore.callSession.call({}, function(error) {
                    console.log(error);
                });
            } else {
                console.log(error);
                alert(JSON.stringify(error));
            }
        });
    }

    muteCall() {
        videoCallStore.isMute = !videoCallStore.isMute;
        if(videoCallStore.isMute){
            videoCallStore.callSession.mute('audio');
        } else {
            videoCallStore.callSession.unmute('audio');
        }
    }

    muteVideo() {
        videoCallStore.videoMute = !videoCallStore.videoMute;
        if(videoCallStore.videoMute){
            videoCallStore.callSession.mute('video');
        } else {
            videoCallStore.callSession.unmute('video');
        }
    }

    endCall() {
        videoCallStore.callSession.stop({});
        videoCallStore.callStatus = 'CALL_ENDED';
    }
}
const videoCallStore = new Store();
export default videoCallStore;