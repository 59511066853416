import React, { Component } from 'react';
import hero from '../../assets/images/hero.jpg';

function Hero() {
        const style={
            bgHero: {
                minHeight: 700,
                alignItems: 'center',
                backgroundImage: `url(${hero})`,
                backgroundSize: 'cover',
                paddingTop:250,
            },
            title:{
                color: '#fff',
                fontWeight: 700,
                marginBottom: 20
            },
            meta:{
                color: '#fff',
                fontWeight: 400,
                opacity: 0.7,
                fontSize: 18,
                paddingLeft: 80,
                paddingRight: 80
            },
        };
        return (
            <div className="BannerPage">
                <div className="rowes bgHero" style={style.bgHero}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1 className="HeroText" style={style.title}>Learn from the best Mentors</h1>
                                <p className="HeroPara" style={style.meta}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et</p>
                                <a href="#" className="btn btn-primary BtnSets">Register</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default Hero;