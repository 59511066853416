import React from 'react';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import './style.scss';
import {observer} from 'mobx-react';
import BoundInput from '../../../../components/Form/BoundInput';
import notesStore from '../../../../stores/notesStore';
import { Link } from 'react-router-dom';
import MyNotesBanner from '../../../../assets/images/MyNotesBanner.svg';
import noResultFound from '../../../../assets/images/noResultFound.svg';
import booklover from '../../../../assets/images/booklover.svg';
import edit from '../../../../assets/images/edit.svg';
import eye from '../../../../assets/images/eye.svg';
import dele from '../../../../assets/images/dele.svg';
import notify from '../../../../components/notify';



@observer class MyNotes extends React.Component {

    componentDidMount() {
        notesStore.myNotes(()=>{

        });
    }


    render() {

        return (
            <DashboardBody>
                <div className="MyNotesPage">                      
                    <div className="row">           
                        <div className="col-md-12">                          
                            <div className="row UplodBg">
                                <div className="col-md-3">                                    
                                    <img src={booklover} className="img-responsive" /> 
                                </div>
                                <div className="col-md-9 padd25">
                                    <h4 className="NotesText">Uploaded Notes</h4> 
                                    <p className="paranotes">Explore all your uploaded notes. please verify to publish them<br></br> 20 Upload Notes</p>
                                </div>
                            </div>                            
                        </div>
                    </div>    
                    <div className="pageTitle row">
                        <div className="col-md-6">
                            <h3>My Notes</h3>
                        </div>
                        <div className="col-md-6">
                            <Link to={`${process.env.PUBLIC_URL}/addNewNote`} className="btn btn-primary float-right">Add New</Link>
                        </div>
                    </div>
                    
                    {notesStore.myNotesModel.data!=null&&!notesStore.myNotesModel.loading?(
                        <div className="row">
                            {notesStore.myNotesModel.data.map((item) => 
                                <div className="col-md-4">
                                    <div className="NoteItem">
                                        <div className="noteImgCon">
                                            <img src={`${item.cover}`} className="noteFeatImg" />
                                        </div>
                                        <div className="noteContent">
                                            <h5>{item.title}</h5>
                                            <Link className="btn btn-info editBtn" to={`${process.env.PUBLIC_URL}/editNoteStep1/${item.id}`}><img src={edit} className="IconWidth"/></Link>
                                            &nbsp;&nbsp;
                                            {item.preview != ''?(
                                                <a className="btn btn-info viewBtn" href={item.preview} target="_blank"><img src={eye} className="IconWidth"/></a>
                                            ):null}
                                            <button className="btn btn-info deleteBtn" onClick={()=>{ notesStore.deleteMyNotes(item.id, ()=> { notify('Note deleted successfully'); notesStore.myNotes(()=>{  }) }) }}><img src={dele} className="IconWidth"/></button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {notesStore.myNotesModel.data.length==0?(
                                <div className="noResultsFound col-md-12 text-center">
                                    <img src={noResultFound} />
                                    <h4>It's a bit empty here.... </h4>
                                    <p>Explore notes from the student of top universities</p>
                                    <Link to={`${process.env.PUBLIC_URL}/notes`} className="btn btn-primary">Explore Notes</Link>
                                </div>
                            ):null}
                        </div>
                    ):'loading'}
                </div>
            </DashboardBody>
        );
    }
}

export default MyNotes;