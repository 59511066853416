export default {
    PersonalDocType: 'Please Select Personal Document Type',
    PersonalDocUpload: 'Please Upload Personal Document',
    LinkedInURL: 'Please Enter LinkedIn URL',
    UniversityEmpty: 'Please Select University',
    MajorEmpty: 'Please Select Major',
    HighschoolGradeType: 'Please Select Curriculum',
    HighschoolScore: 'Please Enter High School Score',
    HighSchoolDocUpload: 'Please Upload High School Document',
    UpiEmpty: 'Please enter UPI',
}