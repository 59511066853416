import React from 'react';
import { observer } from 'mobx-react';
import Header from '../../../components/Containers/Header/Header';
import Footer from '../../../components/Containers/Footer';
import './styles.scss';
import ContactIcon1 from '../../../assets/images/ContactIcon1.svg';
import ContactIcon2 from '../../../assets/images/ContactIcon2.svg';
import ContactIcon3 from '../../../assets/images/ContactIcon3.svg';
import ContactBanner from '../../../assets/images/ContactBanner.svg';


@observer class ContactUs extends React.Component {

    render() {
        return (
            <div className="ContactUsPage">
                <Header />
                <div className="HeaderMargin"></div>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <h1 className="mb-5 ContactUs">Contact Us</h1>
                            {/* <p className="mb-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p> */}
                            <div className="cItem">
                                <img src={ContactIcon1} />

                                <p><a className="hoveColor" href="tel:+1 510-642-6000">+1 510-642-6000</a></p>
                            </div>
                            <div className="cItem">
                                <img src={ContactIcon2} />
                                <p>Berkeley, CA, United States</p>
                            </div>
                            <div className="cItem">
                                <img src={ContactIcon3} />
                                <p><a className="hoveColor" href="mailto:team@menteere.com">team@menteere.com</a></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={ContactBanner} className="contactImg" />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default ContactUs;