import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import {observer} from 'mobx-react';
import MenteereLoader from '../../../../components/MenteereLoader';
import noResultFound from '../../../../assets/images/noResultFound.svg';
import playlistIcon from '../../../../assets/images/playlistIcon.svg';

import notesStore from '../../../../stores/notesStore';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';


@observer class MenteereMyPlaylist extends React.Component {

    componentWillMount() {
        notesStore.getMyPlaylistFull();
    }

    render() {
        return (
            <DashboardBody>
                <div className="row MyPlaylistPage">
                    <div className="col-md-12">
                        <div className="pageTitle mb-4">
                            <h4>My Playlists</h4>
                        </div>
                    </div>
                    {notesStore.MyPlaylistPage.loading?(
                        <div className="text-center mb-5 mt-5">
                            <MenteereLoader type="spinner" />
                        </div>
                    ):null}

                    <div className="col-md-12">
                    {!notesStore.MyPlaylistPage.loading && notesStore.MyPlaylistPage.data != null?(
                        <div className="row">
                            {notesStore.MyPlaylistPage.data.map((item) => 
                                <Link className="col-md-3 PlaylistItem" to={`${process.env.PUBLIC_URL}/MenteereMyPlaylistDetail/${item.id}`}>
                                    <div className="CoverContainer">
                                        <img src={item.cover} />
                                        <div className="overlay">
                                            <p>{item.count}</p>
                                            <img src={playlistIcon} />
                                        </div>
                                    </div>
                                    <h6>{item.title}</h6>
                                    <span className="play-btn">View Full Playlist</span>
                                </Link>
                            )}
                            {notesStore.MyPlaylistPage.data.length==0?(
                                <div className="noResultsFound col-md-12 text-center">
                                    <img src={noResultFound} />
                                    <h4>It's a bit empty here.... </h4>
                                    <p>Explore notes from the student of top universities</p>
                                    <Link to={`${process.env.PUBLIC_URL}/notes`} className="btn btn-primary">Explore Notes</Link>
                                </div>
                            ):null}
                        </div>
                    ):null}
                    </div>
                </div>
            </DashboardBody>
        );
    }
}

export default MenteereMyPlaylist;

