import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import Service from '../shared/service/service';
import CONSTANTS from '../shared/constants';

const baseUrl = CONSTANTS.API_BASE;

class Store {

    constructor() {
        makeAutoObservable(this);
    }

    myNotesModel = {
        loading: false,
        data: null
    };

    deleteMyNotesModel = {
        loading: false,
    };

    myNoteInfo = {
        loading: false,
        data: null,
        submitLoading: false,
    };

    createNoteModel = {
        infoLoading: false,
        submitLoading: false,
        curriculum: {
            loading: false,
            data: null
        },
        subject: {
            loading: false,
            data: null
        },
        theme: {
            loading: false,
            data: null
        },
        topic: {
            loading: false,
            data: null
        },
        inputs: {
            title: '',
            desc: '',
            curriculum_id: '',
            subject_id: '',
            theme_id: '',
            topic_id: '',
            note_type: 1
        },
        initialInputs: {
            title: '',
            desc: '',
            curriculum_id: '',
            subject_id: '',
            theme_id: '',
            topic_id: '',
            note_type: 1
        },
    };

    setCoverPicModel = {
        loading: false,
        input: '',
        data: null,
    };

    setPreviewModel = {
        loading: false,
        input: '',
        data: null,
    };

    uploadNotePDFModel = {
        loading: false,
        data: null,
    };

    lastReadNotes = {
        loading: false,
        data: null
    }

    NotesListModel = {
        curriculumFilter: {
            loading: false,
            data: [],
            keywords: '',
            selectedIds: []
        },
        subjectFilter: {
            loading: false,
            data: [],
            keywords: '',
            selectedIds: []
        },
        universitiesFilter: {
            loading: false,
            data: [],
            keywords: '',
            selectedIds: []
        },
        typeFilter: {
            selectedId: 0,
        },
        dataLoading: false,
        data: [],
        limit: 20,
        offset: 0
    };

    ExpertsListModel = {
        curriculumFilter: {
            loading: false,
            data: [],
            keywords: '',
            selectedIds: []
        },
        universitiesFilter: {
            loading: false,
            data: [],
            keywords: '',
            selectedIds: []
        },
        dataLoading: false,
        data: [],
        limit: 20,
        offset: 0
    };

    NotesDetailModel = {
        loading: false,
        data: null,
        isReading: false,
        showPlansVisible: false,
        similarNotes: {
            loading: false,
            data: null
        }
    };

    ExpertDetailModel = {
        loading: false,
        data: null,
        notesByExpert: {
            loading: false,
            data: null
        }
    };

    PlansListModel = {
        loading: false,
        data: null,
        selectedID: null
    };

    BuySubscriptionModel = {
        submitLoading: false,
    };

    MyPlaylistPage = {
        loading: false,
        data: null
    };

    MyPlaylistDetailPage = {
        loading: false,
        data: null
    };

    getFilterCurriculums() {
        this.NotesListModel.curriculumFilter.loading = true;
        Service.get(
            'getCurriculums?keywords=' + this.NotesListModel.curriculumFilter.keywords,
            (status, response) => {
                this.NotesListModel.curriculumFilter.loading = false;
                this.NotesListModel.curriculumFilter.data = response.data;
            },
        ).catch(error => {
            this.NotesListModel.curriculumFilter.loading = false;
            Service.handleError(error);
        });
    }

    getExpertsFilterCurriculums() {
        this.ExpertsListModel.curriculumFilter.loading = true;
        Service.get(
            'getCurriculums?keywords=' + this.ExpertsListModel.curriculumFilter.keywords,
            (status, response) => {
                this.ExpertsListModel.curriculumFilter.loading = false;
                this.ExpertsListModel.curriculumFilter.data = response.data;
            },
        ).catch(error => {
            this.ExpertsListModel.curriculumFilter.loading = false;
            Service.handleError(error);
        });
    }

    getExpertsFilterUniversities(callback) {
        this.ExpertsListModel.universitiesFilter.loading = true;
        Service.get(
            'getUniversities?keywords=' + this.ExpertsListModel.universitiesFilter.keywords,
            (status, response) => {
                this.ExpertsListModel.universitiesFilter.loading = false;
                this.ExpertsListModel.universitiesFilter.data = response.data;
                return callback();
            },
        ).catch(error => {
            this.ExpertsListModel.universitiesFilter.loading = false;
            Service.handleError(error);
        });
    }

    getFilterUniversities() {
        this.NotesListModel.universitiesFilter.loading = true;
        Service.get(
            'getUniversities?keywords=' + this.NotesListModel.universitiesFilter.keywords,
            (status, response) => {
                this.NotesListModel.universitiesFilter.loading = false;
                this.NotesListModel.universitiesFilter.data = response.data;
            },
        ).catch(error => {
            this.NotesListModel.universitiesFilter.loading = false;
            Service.handleError(error);
        });
    }

    getFilterSubjects() {
        this.NotesListModel.subjectFilter.loading = true;
        Service.get(
            'getSubjects?keywords=' + this.NotesListModel.subjectFilter.keywords,
            (status, response) => {
                this.NotesListModel.subjectFilter.loading = false;
                this.NotesListModel.subjectFilter.data = response.data;
            },
        ).catch(error => {
            this.NotesListModel.subjectFilter.loading = false;
            Service.handleError(error);
        });
    }

    getExperts() {
        this.ExpertsListModel.dataLoading = true;
        let currs = this.ExpertsListModel.curriculumFilter.selectedIds.join(',');
        let subs = this.ExpertsListModel.universitiesFilter.selectedIds.join(',');
        let limit = this.ExpertsListModel.limit;
        let offset = this.ExpertsListModel.offset;
        let filterTxt = `curriculums=${currs}&universities=${subs}&limit=${limit}&offset=${offset}`;
        Service.get(
            'experts?' + filterTxt,
            (status, response) => {
                this.ExpertsListModel.dataLoading = false;
                this.ExpertsListModel.data = response.data;
            },
        ).catch(error => {
            this.ExpertsListModel.dataLoading = false;
            Service.handleError(error);
        });
    }

    getExpertDetail(id, callback) {
        this.ExpertDetailModel.loading = true;
        Service.get(
            'getExpertDetail/' + id,
            (status, response) => {
                this.ExpertDetailModel.loading = false;
                this.ExpertDetailModel.data = response.data;
                return callback(response.data);
            },
        ).catch(error => {
            this.ExpertDetailModel.loading = false;
            Service.handleError(error);
        });
    }

    getNotes() {
        this.NotesListModel.dataLoading = true;
        let currs = this.NotesListModel.curriculumFilter.selectedIds.join(',');
        let subs = this.NotesListModel.subjectFilter.selectedIds.join(',');
        let unis = this.NotesListModel.universitiesFilter.selectedIds.join(',');
        let note_type = this.NotesListModel.typeFilter.selectedId;
        let limit = this.NotesListModel.limit;
        let offset = this.NotesListModel.offset;
        let filterTxt = `curriculums=${currs}&subjects=${subs}&universities=${unis}&note_type=${note_type}&limit=${limit}&offset=${offset}`;
        Service.get(
            'notes?' + filterTxt,
            (status, response) => {
                this.NotesListModel.dataLoading = false;
                this.NotesListModel.data = response.data;
            },
        ).catch(error => {
            this.NotesListModel.dataLoading = false;
            Service.handleError(error);
        });
    }

    getNoteDetail(id, callback) {
        this.NotesDetailModel.loading = true;
        Service.get(
            'getNoteDetail/' + id,
            (status, response) => {
                this.NotesDetailModel.loading = false;
                this.NotesDetailModel.data = response.data;
                return callback(response.data);
            },
        ).catch(error => {
            this.NotesDetailModel.loading = false;
            Service.handleError(error);
        });
    }

    getSimilarNotes(id) {
        this.NotesDetailModel.similarNotes.loading = true;
        Service.get(
            'getSimilarNotes/' + id,
            (status, response) => {
                this.NotesDetailModel.similarNotes.loading = false;
                this.NotesDetailModel.similarNotes.data = response.data;
            },
        ).catch(error => {
            this.NotesDetailModel.similarNotes.loading = false;
            Service.handleError(error);
        });
    }

    getNotesByExpertID(id) {
        this.ExpertDetailModel.notesByExpert.loading = true;
        Service.get(
            'getNotesByExpertID/' + id,
            (status, response) => {
                this.ExpertDetailModel.notesByExpert.loading = false;
                this.ExpertDetailModel.notesByExpert.data = response.data;
            },
        ).catch(error => {
            this.ExpertDetailModel.notesByExpert.loading = false;
            Service.handleError(error);
        });
    }

    getPlans() {
        this.PlansListModel.loading = true;
        Service.get(
            'getPlans',
            (status, response) => {
                this.PlansListModel.loading = false;
                this.PlansListModel.data = response.data;
                this.PlansListModel.selectedID = response.data[0].id
            },
        ).catch(error => {
            this.PlansListModel.loading = false;
            Service.handleError(error);
        });
    }

    subscribeMe(id, callback) {
        this.BuySubscriptionModel.loading = true;
        Service.post(
            'subscribeMe',
            { id: id },
            (status, response) => {
                this.BuySubscriptionModel.loading = false;
                return callback(response.data);
            },
        ).catch(error => {
            this.BuySubscriptionModel.loading = false;
            Service.handleError(error);
        });
    }

    getCurriculums() {
        this.createNoteModel.curriculum.loading = true;
        Service.get(
            'getCurriculums',
            (status, response) => {
                this.createNoteModel.curriculum.loading = false;
                this.createNoteModel.curriculum.data = response.data;
            },
        ).catch(error => {
            this.createNoteModel.curriculum.loading = false;
            Service.handleError(error);
        });
    }

    getSubjects() {
        this.createNoteModel.subject.loading = true;
        Service.get(
            'getSubjects',
            (status, response) => {
                this.createNoteModel.subject.loading = false;
                this.createNoteModel.subject.data = response.data;
            },
        ).catch(error => {
            this.createNoteModel.subject.loading = false;
            Service.handleError(error);
        });
    }

    getThemeBySubjectID(id) {
        this.createNoteModel.theme.loading = true;
        Service.get(
            'getThemeBySubjectID/' + id,
            (status, response) => {
                this.createNoteModel.theme.loading = false;
                this.createNoteModel.theme.data = response.data;
            },
        ).catch(error => {
            this.createNoteModel.theme.loading = false;
            Service.handleError(error);
        });
    }

    getTopicByThemeID(id) {
        this.createNoteModel.topic.loading = true;
        Service.get(
            'getTopicByThemeID/' + id,
            (status, response) => {
                this.createNoteModel.topic.loading = false;
                this.createNoteModel.topic.data = response.data;
            },
        ).catch(error => {
            this.createNoteModel.topic.loading = false;
            Service.handleError(error);
        });
    }

    storeNote(callback) {
        this.createNoteModel.submitLoading = true;
        Service.post(
            'storeMyNotes',
            this.createNoteModel.inputs,
            (status, response) => {
                this.createNoteModel.submitLoading = false;
                this.createNoteModel.inputs = this.createNoteModel.initialInputs;
                return callback(response.data.id);
            },
        ).catch(error => {
            this.createNoteModel.submitLoading = false;
            Service.handleError(error);
        });
    }

    updateNote(id, callback) {
        this.myNoteInfo.submitLoading = true;
        Service.post(
            'updateMyNotes/' + id,
            {
                title: this.myNoteInfo.data.title,
                desc: this.myNoteInfo.data.desc,
                curriculum_id: this.myNoteInfo.data.curriculum_id,
                subject_id: this.myNoteInfo.data.subject_id,
                theme_id: this.myNoteInfo.data.theme_id,
                topic_id: this.myNoteInfo.data.topic_id,
            },
            (status, response) => {
                this.myNoteInfo.submitLoading = false;
                return callback(response.data.id);
            },
        ).catch(error => {
            this.myNoteInfo.submitLoading = false;
            Service.handleError(error);

        });
    }

    deleteMyNotes(id, callback) {
        this.deleteMyNotesModel.loading = true;
        Service.post(
            'deleteMyNotes/' + id,
            {},
            (status, response) => {
                this.deleteMyNotesModel.loading = false;
                return callback();
            },
        ).catch(error => {
            this.deleteMyNotesModel.loading = false;
            Service.handleError(error);

        });
    }

    setCoverPic(id, base64, callback) {
        this.setCoverPicModel.loading = true;
        Service.post(
            'setNoteCover',
            {
                id: id,
                base64: base64
            },
            (status, response) => {
                this.setCoverPicModel.loading = false;
                return callback();
            },
        ).catch(error => {
            this.setCoverPicModel.loading = false;
            Service.handleError(error);

        });
    }

    uploadNotePDF(id, file, callback) {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("id", id);
        this.uploadNotePDFModel.loading = true;
        axios.post(`${baseUrl}/uploadNotePDF`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            this.uploadNotePDFModel.loading = false;
            this.myNoteInfo.data.doc_id = response.data.data.doc_id;
            this.myNoteInfo.data.doc = response.data.data.doc;
            return callback(response.data);
        });
    }

    setNotePreview(id, base64, callback) {
        this.setPreviewModel.loading = true;
        Service.post(
            'uploadNotePreview',
            {
                id: id,
                base64: base64
            },
            (status, response) => {
                this.setPreviewModel.loading = false;
                return callback();
            },
        ).catch(error => {
            this.setPreviewModel.loading = false;
            Service.handleError(error);
        });
    }

    myNotes(callback) {
        this.myNotesModel.loading = true;
        Service.get(
            'myNotes',
            (status, response) => {
                this.myNotesModel.loading = false;
                this.myNotesModel.data = response.data;
                return callback();
            },
        ).catch(error => {
            this.myNotesModel.loading = false;
            Service.handleError(error);
        });
    }

    getNoteInfo(id, callback) {
        this.myNoteInfo.loading = true;
        Service.get(
            'noteinfo/' + id,
            (status, response) => {
                this.myNoteInfo.loading = false;
                this.myNoteInfo.data = response.data;
                return callback(response.data);
            },
        ).catch(error => {
            this.myNoteInfo.loading = false;
            Service.handleError(error);
        });
    }

    getLastReadNotes() {
        this.lastReadNotes.loading = true;
        Service.get(
            'getLastReadNote',
            (status, response) => {
                this.lastReadNotes.loading = false;
                this.lastReadNotes.data = response.data;
            },
        ).catch(error => {
            this.lastReadNotes.loading = false;
            Service.handleError(error);
        });
    }

    getMyPlaylistFull() {
        this.MyPlaylistPage.loading = true;
        Service.get(
            'getMyPlaylistsFull',
            (status, response) => {
                this.MyPlaylistPage.loading = false;
                this.MyPlaylistPage.data = response.data;
            },
        ).catch(error => {
            this.MyPlaylistPage.loading = false;
            Service.handleError(error);
        });
    }

    getMyPlaylistDetail(id) {
        this.MyPlaylistDetailPage.loading = true;
        Service.get(
            'MenteeMyPlaylistDetail/' + id,
            (status, response) => {
                this.MyPlaylistDetailPage.loading = false;
                this.MyPlaylistDetailPage.data = response.data;
            },
        ).catch(error => {
            this.MyPlaylistDetailPage.loading = false;
            Service.handleError(error);
        });
    }

    recordLastReadNote(id) {
        Service.post(
            'recordLastReadNote/',
            { id: id },
            (status, response) => {
                //
            },
        ).catch(error => {
            Service.handleError(error);
        });
    }

    updateClickCount(noteId) {
        let payload = {
            noteId: noteId
        };
        Service.post(
            'updateClickCount/', payload,
            (status, response) => { },
        ).catch(error => {
            console.log(error);
            Service.handleError(error);
        })
    }


}

const notesStore = new Store();
export default notesStore;