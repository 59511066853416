import React from 'react';
import DashboardBody from '../../../components/Containers/DashboardBody/DashboardBody';
import uploadyourpicture from '../../../assets/images/uploadyourpicture.svg';
import './style.scss';
import { Link } from 'react-router-dom';
import {observer} from 'mobx-react';
import verificationStore from '../../../stores/verificationStore';
import BoundInput from '../../../components/Form/BoundInput';
import ProfilePictureBadge from '../../../components/ProfilePictureBadge';
import MenteereLoader from '../../../components/MenteereLoader';
import notify from '../../../components/notify';
import errorMessages from '../../../shared/errorMessages';
import linkedin from '../../../assets/images/linkedin.svg'

var $ = require('jquery');

@observer class VerifyProfile extends React.Component {

    componentDidMount() {
        verificationStore.getPrefilledInfo();   
    }

    submit() {
        const prefilledInfo = verificationStore.verifyStep1Model.infoData.prefilledInfo;
        if(prefilledInfo.personal_doc_type == '' || prefilledInfo.personal_doc_type == null) {
            notify(errorMessages.PersonalDocType, 'danger');
            return false;
        }
        if($('.PersonalDocList').length == 0) {
            notify(errorMessages.PersonalDocUpload, 'danger');
            return false;
        }
        if(prefilledInfo.linkedin_url == '' || prefilledInfo.linkedin_url == null) {
            notify(errorMessages.LinkedInURL, 'danger');
            return false;
        }
        verificationStore.update(true, ()=> {
            this.props.history.push(`${process.env.PUBLIC_URL}/verify-profile-2`);
        });
    }

    uploadDoc(file, field) {
        verificationStore.uploadVdoc(file, field, (object)=> {
            notify('Document uploaded successfully');
            switch(field) {
                case 'highschool_doc_ids':
                    verificationStore.verifyStep1Model.infoData.prefilledInfo.highSchoolDocs.push(object.data);
                break;
                case 'personal_doc_ids':
                    verificationStore.verifyStep1Model.infoData.prefilledInfo.personalDocs.push(object.data);
                break;
            }
        });
    }

    render() {

        return (
            <DashboardBody>
                <div className="VerifyProfilePage">
                    <div className="col-md-12 text-center mt-4">
                        <h4>Personal details</h4>
                        <div className="verifyProgress">
                            <div className="sec active">
                                <div className="dot"></div>
                                <p>Personal Details</p>
                            </div>
                            <div className="sec">
                                <div className="dot"></div>
                                <p>Academic Details</p>
                            </div>
                            <div className="sec">
                                <div className="dot"></div>
                                <p>Bank Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="pageTitle">
                        <h4>Personal Details</h4>
                    </div>
                    {!verificationStore.verifyStep1Model.infoLoading && verificationStore.verifyStep1Model.infoData !== null?(
                    <div className="row">
                        <div className="col-md-12 uploadpicture mb-5">
                            <div className="row align-items-center">
                                <div className="col-md-2">
                                    <ProfilePictureBadge />
                                </div>
                                <div className="col-md-10">
                                    <div className="rightInfo">
                                        <h5>Upload Profile Picture</h5>
                                        <span className="op-5">Clear frontal face photos help personalize communication and show mentees that you are authentic and commited </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label>Personal Document <span className="LabelRequired">*</span></label>
                            <select className="form-control" 
                                value={verificationStore.verifyStep1Model.infoData.prefilledInfo.personal_doc_type}
                                onChange={(e)=> {
                                    verificationStore.verifyStep1Model.infoData.prefilledInfo.personal_doc_type = e.target.value;
                                }}
                            >
                                <option value={null}>--Select Document--</option>
                                {verificationStore.verifyStep1Model.infoData.metainfo.map((item) =>
                                {
                                    if(item.type == 'document_type')
                                    {
                                        return <option value={item.title}>{item.title}</option>;
                                    }
                                }
                                    
                                )}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="col-md-12">&nbsp;</label>
                            <label className="UploadFileInput form-control cursor-pointer">
                                <input type="file" id="personalDocInput" onChange={(e)=>{ this.uploadDoc(e.target.files[0], 'personal_doc_ids') }} style={{ display: 'none' }} />
                                <p>+ Add Documents &nbsp;&nbsp;&nbsp; <span className="icon icon-link"></span>
                                    {verificationStore.verifyStep1Model.loaders.personal_doc_ids?(
                                        <MenteereLoader type="spinner" />
                                    ):null}
                                </p>
                            </label>
                            {verificationStore.verifyStep1Model.infoData.prefilledInfo.personalDocs.map((item, index)=>
                                <div className="AttachItem PersonalDocList">
                                    <div className="titleSide">
                                        <p>{item.path}</p>
                                    </div>
                                    <div className="ActionBtns">
                                        <a href={item.url} target="_blank">View</a>
                                        <button onClick={()=>{
                                            verificationStore.verifyStep1Model.infoData.prefilledInfo.personalDocs.splice(index, 1);
                                            verificationStore.removeVdoc(item.doc_id, 'personal_doc_ids');
                                        }}>Delete</button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-4">
                            <label><img src={linkedin} className="linkdinIcon"/> LinkedIn Profile URL <span className="LabelRequired">*</span></label>
                            <BoundInput
                                model={verificationStore.verifyStep1Model.infoData.prefilledInfo}
                                property="linkedin_url"
                            />
                        </div>
                        <div className="col-md-12 mt-3"></div>
                        <div className="col-md-4">
                            <label>Area of expertise</label>
                            <BoundInput
                                model={verificationStore.verifyStep1Model.infoData.prefilledInfo}
                                property="tagline"
                            />
                        </div>
                        <div className="col-md-4">
                            <label>Bio</label>
                            <BoundInput
                                model={verificationStore.verifyStep1Model.infoData.prefilledInfo}
                                property="about_me"
                            />
                        </div>                        
                        <div className="col-md-12">
                            <button className="btn btn-primary float-right btn-bit-wide" onClick={()=> { this.submit(); }} disabled={verificationStore.verifyStep1Model.submitLoading}>Save & Continue</button>
                        </div>
                    </div>
                    ):('loading')}
                </div>
            </DashboardBody>
        );
    }
}

export default VerifyProfile;