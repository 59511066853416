import React from 'react';
import {observer} from 'mobx-react';
import Header from '../../../../components/Containers/Header/Header';
import './styles.scss';
import MenteereLoader from '../../../../components/MenteereLoader';
import Footer from '../../../../components/Containers/Footer';
import notify from '../../../../components/notify';
import Rating from '@material-ui/lab/Rating';
import expertStore from '../../../../stores/expertStore';
import Calendar from 'react-calendar';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';


@observer class BookExpert extends React.Component {

    componentDidMount() {
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        });
        this.setPage();
        this.setDate(new Date());
    }

    setPage() {
        expertStore.getBookExpertPageInfo(this.props.match.params.id, ()=> {
            
        });
        expertStore.getCurriculums();
        expertStore.getSubjects();
        expertStore.setWalletHrs();
    }

    async setDate(date) {
        date = moment(new Date(date)).format('YYYY-MM-DD');
        expertStore.bookExpertPage.calendarSlots.selectedDate = date;
        expertStore.bookExpertPage.calendarSlots.selectedDateUTC = new Date(date+' 00:00:00');
        expertStore.initSlots(this.props.match.params.id, ()=> { 

        });
    }

    getSlotHrs() {
        var hrs = 0;
        for(var i in expertStore.bookExpertModel.selectedSlots) {
            hrs = hrs+expertStore.bookExpertModel.selectedSlots[i].hrs;
        }
        return hrs;
    }

    getWalletHrs() {
        return expertStore.bookExpertModel.hrs_in_wallet;
    }

    getTotalHrs() {
        var slotHrs = this.getSlotHrs();
        var walletHrs = this.getWalletHrs();
        if(walletHrs >= slotHrs) {
            return 0;
        } else {
            return slotHrs - walletHrs;
        }
    }

    getTotalAmt() {
        var finalHrs = this.getTotalHrs();
        var finalAmount = finalHrs*expertStore.bookExpertPage.data.Price;
        return finalAmount;
    }

    BookExpert() {
        if(expertStore.bookExpertModel.Input.selected_curriculum === '') {
            notify('Please select Curriculum', 'danger');
            return false;
        }

        if(expertStore.bookExpertModel.Input.selected_subject === '') {
            notify('Please select Subject', 'danger');
            return false;
        }

        if(expertStore.bookExpertModel.Input.description === '') {
            notify('Please enter a short description', 'danger');
            return false;
        }

        expertStore.bookExpertModel.Input.selectedSlotIDs = expertStore.bookExpertModel.selectedSlotIDs.join(',');
        expertStore.submitBookingRequest((data)=> {
            if(data.haveToPay){
                expertStore.completeBookingPayment(data.bookingID, ()=> {
                    notify('You have successfully booked this Expert', 'snack');
                    this.props.history.push(`${process.env.PUBLIC_URL}/BookExpertThankYou`);
                });
            } else {
                notify('You have successfully booked this Expert', 'snack');
                this.props.history.push(`${process.env.PUBLIC_URL}/BookExpertThankYou`);
            }
        });
    }

    render() {
        const Model = expertStore.bookExpertPage.data;
        return (
            <div className="BookExpertPage">
                <Header />
                <div className="HeaderMargin"></div>
                <div className="container">
                    <div className="row">
                        {expertStore.bookExpertPage.loading?(
                        <div className="col-md-12">
                            <MenteereLoader type="notesdetail" />
                        </div>
                        ):null}
                        <div className="col-md-12">
                        {!expertStore.bookExpertPage.loading && expertStore.bookExpertPage.data!=null?(
                        <div className="row">
                            <div className="col-md-4 ExperPictureSide">
                                <div className="ExpertPic">
                                    <img src={Model.proPicURL} />
                                </div>
                                <div className="ExpertMeta">
                                    <h3>{Model.ExpertName}</h3>
                                    <p>{Model.universityName||'...'}</p>
                                    <Rating name="read-only" value={Model.rating} readOnly />
                                    <p className="mt-2 mb-4"><b>Consultation Fee: </b> INR {Model.Price||'...'} / Hour</p>
                                    <p>{Model.AboutExpert||'...'}</p>
                                </div>
                            </div>
                            <div className="col-md-8 ExpertBokingSide mb-5">
                                <h4>Select Date & Time</h4>
                                <div className="row">
                                    <div className="col-md-7 mb-4">
                                        <Calendar
                                            value={new Date(expertStore.bookExpertPage.calendarSlots.selectedDate)}
                                            onChange={(date) => {
                                                this.setDate(date);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-5">
                                        {expertStore.bookExpertPage.calendarSlots.loading?(
                                        <div className="col-md-12 loaderContainer" style={{ marginTop: 100 }}>
                                            <Loader indeterminate active>Fetching Data</Loader>
                                        </div>
                                        ):(
                                        <div className="DaySchedule">
                                            <div className="ScheduleHeader">
                                                <h5>{moment(new Date(expertStore.bookExpertPage.calendarSlots.selectedDate)).format('dddd - DD MMMM')}</h5>
                                            </div>
                                            <div className="ScheduleBody">
                                                {expertStore.bookExpertPage.calendarSlots.dayScheduleData.map((item) => {
                                                    return <div className="DayScItem" onClick={()=> {
                                                        if(expertStore.bookExpertModel.selectedSlotIDs.indexOf(item.id) == -1) {
                                                            expertStore.bookExpertModel.selectedSlotIDs.push(item.id);
                                                            let fromTime = item.start_time;
                                                            let toTime = item.end_time;
                                                            var timeStart = new Date(fromTime).getHours();
                                                            var timeEnd = new Date(toTime).getHours();
                                                            var hourDiff = timeEnd - timeStart;
                                                            if (hourDiff < 0) {
                                                                hourDiff = 24 + hourDiff;
                                                            }
                                                            expertStore.bookExpertModel.selectedSlots.push({
                                                                id: item.id,
                                                                text: moment(new Date(item.start_time)).format('hh:mm A')+' - '+moment(new Date(item.end_time)).format('hh:mm A')+', '+moment(new Date(expertStore.bookExpertPage.calendarSlots.selectedDate)).format('dddd - DD MMMM'),
                                                                hrs: hourDiff
                                                            });
                                                        }
                                                    }}>
                                                        <p>{moment(item.start_time).format('hh:mm A')} - {moment(item.end_time).format('hh:mm A')}</p>
                                                    </div>
                                                })}
                                                {expertStore.bookExpertPage.calendarSlots.dayScheduleData.length == 0?(
                                                    <div class="NoRes">No Slots available</div>
                                                ):null}
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                    {expertStore.bookExpertModel.selectedSlotIDs.length > 0?(
                                    <div className="col-md-12">
                                        <h4 className="mb-4">Selected Slots</h4>
                                        {expertStore.bookExpertModel.selectedSlots.map((item) => 
                                            <div className="DayScItem">
                                                <p>{item.text}</p>
                                            </div>
                                        )}
                                        <div className="row mt-4">
                                            <div className="col-md-4 mb-4">
                                                <select className="form-control" value={expertStore.bookExpertModel.Input.selected_curriculum} onChange={(e)=> {
                                                    expertStore.bookExpertModel.Input.selected_curriculum = e.target.value;
                                                }}>
                                                    <option value="">Your Curriculum</option>
                                                    {expertStore.bookExpertModel.CurriculumList.data!=null?expertStore.bookExpertModel.CurriculumList.data.map((item) => 
                                                        <option value={item.title}>{item.title}</option>
                                                    ):null}
                                                </select>
                                            </div>
                                            <div className="col-md-4">
                                                <select className="form-control" value={expertStore.bookExpertModel.Input.selected_subject} onChange={(e)=> {
                                                    expertStore.bookExpertModel.Input.selected_subject = e.target.value;
                                                }}>
                                                    <option value="">Your Subject</option>
                                                    {expertStore.bookExpertModel.SubjectList.data!=null?expertStore.bookExpertModel.SubjectList.data.map((item) => 
                                                        <option value={item.title}>{item.title}</option>
                                                    ):null}
                                                </select>
                                            </div>
                                            <div className="col-md-10">
                                                <textarea className="form-control" rows="2" placeholder="Short Description on what you need help for (100 words)"
                                                    value={expertStore.bookExpertModel.Input.description}
                                                    onChange={(e)=> { expertStore.bookExpertModel.Input.description = e.target.value }}
                                                ></textarea>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="col-md-12 PriceBox">
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <p>Slot hrs</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p>+ {this.getSlotHrs()} hr(s)</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <p>Hrs in Wallet</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p>- {this.getWalletHrs()} hr(s)</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <p>Total hrs to be paid</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p>{this.getTotalHrs()} hr(s)</p>
                                                        </div>
                                                    </div>
                                                    <div className="row AmountRow">
                                                        <div className="col-md-9">
                                                            <p>Total Amount</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p>Rs. {this.getTotalAmt()}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" className="btn btn-primary float-right mt-4" disabled={expertStore.bookExpertModel.loading} onClick={()=> {
                                                    this.BookExpert();
                                                }}>Book Now</button>
                                            </div>
                                        </div>
                                    </div>
                                    ):null}
                                </div>
                            </div>
                        </div>):null}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default BookExpert;