import React from 'react';
import {observer} from 'mobx-react';
import './styles.scss';
import pencil from '../../assets/images/pencil.svg';
import rootStore from '../../stores/rootStore';
import MenteereLoader from '../MenteereLoader';

@observer class ProfilePictureBadge extends React.Component {

    componentDidMount() {
        //this.setPicture();
    }

    UpdatePicture(file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let base = reader.result;
            rootStore.updateProfilePicture(base);
        };
    }

    render() {
        const pro_pic_url = rootStore.profileBadge.pro_pic_url;
        if(pro_pic_url == 'null') {
            return (
                <div className="ProfileBadgeContainer">
                    <div className="ProfileBadge" onClick={()=> {
                        rootStore.profileBadge.showDropDown = !rootStore.profileBadge.showDropDown
                    }}>
                        <span>{localStorage.getItem('name').charAt(0)}</span>
                        <div className="editIcon">
                            <img src={pencil} />
                        </div>
                    </div>
                    {rootStore.profileBadge.showDropDown?(
                        <div className="DownSide">
                            <div className="upTriangle"></div>
                            <label className="down-itm">
                                Change Profile Picture
                                {rootStore.profileBadge.loading?(
                                    <MenteereLoader type="spinner" />
                                ):null}
                                <input type="file" onChange={(e)=> {
                                    this.UpdatePicture(e.target.files[0]);
                                }} style={{ display: 'none' }} accept="image/x-png,image/jpeg" />
                            </label>
                        </div>
                    ):null}
                </div>
            );
        } else {
            return (
                <div className="ProfileBadgeContainer">
                    <div className="ProfileBadge pro" onClick={()=> {
                        rootStore.profileBadge.showDropDown = !rootStore.profileBadge.showDropDown
                    }}>
                        <img className="proimg" src={pro_pic_url} />
                    </div>
                    {rootStore.profileBadge.showDropDown?(
                        <div className="DownSide">
                            <div className="upTriangle"></div>
                            <label className="down-itm">
                                Change Profile Picture
                                {rootStore.profileBadge.loading?(
                                    <MenteereLoader type="spinner" />
                                ):null}
                                <input type="file" onChange={(e)=> {
                                    this.UpdatePicture(e.target.files[0]);
                                }} style={{ display: 'none' }} accept="image/x-png,image/jpeg" />
                            </label>
                        </div>
                    ):null}
                </div>
            );
        }
    }
}

export default ProfilePictureBadge;