import React from 'react';
import {observer} from 'mobx-react';
import Header from '../../../components/Containers/Header/Header';
import Footer from '../../../components/Containers/Footer';
import './styles.scss';


@observer class Termsofuse extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className="TermsofusePage">
                <Header />
                    <div className="HeaderMargin"></div>
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-md-12">
terms of use
                            </div>
                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Termsofuse;