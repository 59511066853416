import React from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';

const BoundTextarea = observer(({ property, model, ...props }) => 
    <textarea
        className="form-control"
        name={property}
        onChange={e =>
            (model[property] = e.target.value)
        }
        {...props}
    >{model[property]}</textarea>
);

BoundTextarea.propTypes = {
    model: PropTypes.object.isRequired,
    property: PropTypes.string.isRequired
}

export default BoundTextarea;