import React, { Component } from 'react';
import './style.scss';
import joinus from '../../../assets/images/joinus.svg'
import BgUnder from '../../../assets/images/BgUnder.svg';
import join from '../../../assets/images/join.png';

function JoinMenteere() {
    return(
        <div className="col-md-12 JoinUs">
            <div className="container">                
                <div className="row JoinBox">                    
                    <div className="col-md-6">
                        <img src={join} className="joinImg"></img>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-5 txt-center">
                        <h4 className="SecTitle">Join us</h4>
                        <p>Menteere is always looking for motivate individuals with unique skills. If you feel you can help make our team better, click here!</p>                          
                        <a href="#">Apply here</a>                       
                    </div>
                </div>
            </div>
        </div>
    );    
}

export default JoinMenteere;