import React, { Component } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import ExpertsListBottomBanner from '../../../assets/images/ExpertsListBottomBanner.png';

function ExpertsBottomBanner() {
        return (
            <div className="row ExpertsPageBanner mt-5 mb-5">
                <div className="container mb-5 mt-5">
                    <img src={ExpertsListBottomBanner} className="bannerImg" />
                </div>
            </div>
        );
}

export default ExpertsBottomBanner;