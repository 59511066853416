import React from 'react';
import {observer} from 'mobx-react';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import MenteereLoader from '../../../../components/MenteereLoader';
import ProfilePictureBadge from '../../../../components/ProfilePictureBadge';
import { Link } from 'react-router-dom';
import myprofilebanner from '../../../../assets/images/myprofilebanner.svg';
import pencil from '../../../../assets/images/pencil.svg';
import menteerProfileStore from '../../../../stores/menteer/profileStore';
import {Modal} from 'react-bootstrap';
import BoundInput from '../../../../components/Form/BoundInput';
import notify from '../../../../components/notify';
import constants from '../../../../shared/constants';
//import quickblox from 'quickblox/quickblox';
import './styles.scss';

//const QuickBlox = require('quickblox/quickblox').QuickBlox;
//const QB = new QuickBlox();

@observer class MenteeVideoCall extends React.Component {

    
    componentDidMount() {
            
    }

    render() {
        return (
            <div className="VideoCall">
                <video id="frontVideoElem"></video>
                <video id="curUserVidElem"></video>
            </div>
        );
    }

}

export default MenteeVideoCall;