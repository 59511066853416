import React, { Component } from 'react';
import './style.scss';
import image from '../../../assets/images/becomeMentee.svg';
import menteeMobile from '../../../assets/images/menteeMobile.png';


function BecomeAMentee() {
        return (
            <div className="rowes BecomeAMentee">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center secndhead">
                            <h1 className="SecTitle">Become a Mentee</h1>
                            <p className="SecMeta">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. </p>
                            <img src={image} className=" ImgSet" />
                            <img src={menteeMobile} className=" ImgMobile" />
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default BecomeAMentee;