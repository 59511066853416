import React from 'react';
import {observer} from 'mobx-react';
import Header from '../../../../components/Containers/Header/Header';
import {Checkbox} from '@material-ui/core';
import './styles.scss';
import notesStore from '../../../../stores/notesStore';
import BoundInput from '../../../../components/Form/BoundInput';
import MenteereLoader from '../../../../components/MenteereLoader';
import Footer from '../../../../components/Containers/Footer';
import ExpertsBanner from '../../../../components/Experts/Banner';
import ExpertsBottomBanner from '../../../../components/Experts/BottomBanner';
import ExpertsListNoResultsState from '../../../../components/Experts/ExpertsListNoResultsState';
import ExpertBox from '../../../../components/Experts/ExpertBox';

@observer class ExpertsList extends React.Component {

    componentDidMount() {
        notesStore.ExpertsListModel.universitiesFilter.selectedIds = [];
        notesStore.ExpertsListModel.curriculumFilter.selectedIds = [];
        notesStore.getExpertsFilterCurriculums();
        notesStore.getExpertsFilterUniversities(()=> {
            if(this.props.location.search != '') {
                let universityID = this.props.location.search.replace('?university_id=', '');
                notesStore.ExpertsListModel.universitiesFilter.selectedIds.push(parseInt(universityID));
                notesStore.getExperts();
            }
        });
        notesStore.getExperts();
    }

    render() {
        return (
            <div className="ExpertsListPage">
                <Header />
                <ExpertsBanner />
                <div className="col-md-12 mt-5">
                <div className="row">
                    <div className="col-md-3 FilterSide">
                        <h4>Search Your Experts</h4>

                        <div className="FilterBox">
                            <h5 className="">Curriculum</h5>
                            <span className="BgHalf"></span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search Curriculum"
                                value={notesStore.ExpertsListModel.curriculumFilter.keywords}
                                onChange={(e) => {  
                                    notesStore.ExpertsListModel.curriculumFilter.keywords = e.target.value;
                                    notesStore.getExpertsFilterCurriculums();
                                }}
                            />
                            <div className="FilterItems">
                                {notesStore.ExpertsListModel.curriculumFilter.loading?(
                                    <div className="text-center">
                                        <MenteereLoader type="spinner" />
                                    </div>
                                ):null}
                                {!notesStore.ExpertsListModel.curriculumFilter.loading&&notesStore.ExpertsListModel.curriculumFilter.data.length>0?notesStore.ExpertsListModel.curriculumFilter.data.map((item) =>
                                    <label className="FilterItem">
                                        <Checkbox
                                            checked={notesStore.ExpertsListModel.curriculumFilter.selectedIds.indexOf(item.id) > -1}
                                            onChange={(val) => { 
                                                if(notesStore.ExpertsListModel.curriculumFilter.selectedIds.indexOf(item.id) > -1) {
                                                    notesStore.ExpertsListModel.curriculumFilter.selectedIds.splice(notesStore.ExpertsListModel.curriculumFilter.selectedIds.indexOf(item.id), 1);
                                                } else {
                                                    notesStore.ExpertsListModel.curriculumFilter.selectedIds.push(item.id);
                                                }
                                                notesStore.getExperts();
                                            }}
                                            color="secondary"
                                        />
                                        <span className="f_title">{item.title}</span>
                                        <span className="count"></span>
                                    </label>
                                ):null}
                            </div>
                        </div>


                        <div className="FilterBox">
                            <h5 className="">Universities</h5>
                            <span className="BgHalf2"></span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search Universities"
                                value={notesStore.ExpertsListModel.universitiesFilter.keywords}
                                onChange={(e) => {  
                                    notesStore.ExpertsListModel.universitiesFilter.keywords = e.target.value;
                                    notesStore.getExpertsFilterUniversities(()=>{  });
                                }}
                            />
                            <div className="FilterItems">
                                {notesStore.ExpertsListModel.universitiesFilter.loading?(
                                    <div className="text-center">
                                        <MenteereLoader type="spinner" />
                                    </div>
                                ):null}
                                {!notesStore.ExpertsListModel.universitiesFilter.loading&&notesStore.ExpertsListModel.universitiesFilter.data.length>0?notesStore.ExpertsListModel.universitiesFilter.data.map((item) =>
                                    <label className="FilterItem">
                                        <Checkbox
                                            checked={notesStore.ExpertsListModel.universitiesFilter.selectedIds.indexOf(item.id) > -1}
                                            onChange={(val) => { 
                                                if(notesStore.ExpertsListModel.universitiesFilter.selectedIds.indexOf(item.id) > -1) {
                                                    notesStore.ExpertsListModel.universitiesFilter.selectedIds.splice(notesStore.ExpertsListModel.universitiesFilter.selectedIds.indexOf(item.id), 1);
                                                } else {
                                                    notesStore.ExpertsListModel.universitiesFilter.selectedIds.push(item.id);
                                                }
                                                notesStore.getExperts();
                                            }}
                                            color="secondary"
                                        />
                                        <span className="f_title">{item.title}</span>
                                        <span className="count"></span>
                                    </label>
                                ):null}
                            </div>
                        </div>

                    </div>
                    <div className="col-md-9 expertsGrid">
                        <div className="row">
                            {notesStore.ExpertsListModel.dataLoading?(
                                <MenteereLoader type="noteslisting" />
                            ):null}
                            {notesStore.ExpertsListModel.data.length>0&&!notesStore.ExpertsListModel.dataLoading?notesStore.ExpertsListModel.data.map((item) => 
                                <div className="col-md-4">
                                    <ExpertBox data={item} />
                                </div>
                            ):null}
                            {!notesStore.ExpertsListModel.dataLoading&&notesStore.ExpertsListModel.data.length==0?(
                                <ExpertsListNoResultsState />
                            ):null}
                        </div>                       
                    </div>
                </div>
                </div>
                <ExpertsBottomBanner />
                <Footer />
            </div>
        );
    }
}

export default ExpertsList;