import React from 'react';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import greencheck from '../../../../assets/images/greencheck.svg';
import verifyIcon from '../../../../assets/images/verifyIcon.svg';
import { Link } from 'react-router-dom';
import MenteereLoader from '../../../../components/MenteereLoader';
import menteerProfileStore from '../../../../stores/menteer/profileStore';
import { observer } from 'mobx-react';

@observer class AddNewNoteThankYou extends React.Component {

    componentDidMount() {
        menteerProfileStore.getMyProfile();
    }

    render() {
        return (
            <DashboardBody>
                {menteerProfileStore.myprofile.infoLoading?(
                    <MenteereLoader type="spinner" />
                ):null}
                {!menteerProfileStore.myprofile.infoLoading && menteerProfileStore.myprofile.data != null?(
                    <>
                    {menteerProfileStore.myprofile.data.userMeta.verification_status == 1?(
                        <div className="VerifyProfileThankYou">
                            <div className="col-md-12 text-center mt-5">
                                <img src={greencheck} className="mb-4" />
                                <h2>Thank You!</h2>
                                <p>Your Note is under review by Admin and We will contact you shortly</p>
                                <Link to={`${process.env.PUBLIC_URL}/addNewNote`} className="btn btn-primary">Upload More Notes</Link>
                            </div>
                        </div>
                    ):(
                        <div className="VerifyProfileThankYou">
                            <div className="col-md-12 text-center mt-5">
                                <img src={verifyIcon} className="mb-4" />
                                <h2>Verify Today!</h2>
                                <p>Your Notes are under review by Admin and Please verify your account to get them published</p>
                                <Link to={`${process.env.PUBLIC_URL}/verify-profile-1`} className="btn btn-primary">Verify Now</Link>
                            </div>
                        </div>
                    )}
                    </>
                ):null}
                
            </DashboardBody>
        );
    }
}

export default AddNewNoteThankYou;