import React, { Component } from 'react';
import {observer} from 'mobx-react';
import logo from '../../../assets/images/logo.png';
import authStore from '../../../stores/authStore';
import { Link } from 'react-router-dom';
import rootStore from '../../../stores/rootStore';
import dashBg from '../../../assets/images/dashBg.png';
import arrowDown from '../../../assets/images/arrowDown.svg';
import './style.scss';

import NotificationBell from '../../NotificationBell';
import VideoCallOperations from '../../VideoCallOperations';
const $ = require('jquery');

@observer class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggleOn: true,
            windowWidth: window.innerWidth
        };
        this.handleClick = this.handleClick.bind(this);
        
      }
    componentDidMount() {
        $('a[href="'+window.location.pathname+'"]').addClass('active');       
    }
    handleClick() {
        this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));
    } 

    render() {
        const pro_pic_url = localStorage.getItem('pro_pic_url');
        return (
            <header id="header" className="header fixed-top">
                <VideoCallOperations />
                <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                    <Link to={`${process.env.PUBLIC_URL}/`} className="logo d-flex align-items-center">
                        <img src={logo} alt="" className="sitelogo" />
                    </Link>  
                    <div className="HeadBell">
                        <ul>
                        {authStore.isLoggedIn()?(
                        <li className="Bells">
                            <div className="nav-link BellIcon">
                                <NotificationBell /> 
                            </div>
                        </li>
                        ):null}
                        </ul>                         
                    </div>                
                    <a onClick={this.handleClick} className="MyBaar">
                        <i class="fa fa-bars"></i>
                    </a>
                    <nav id="navbar" className={`navbar ${this.state.isToggleOn==true ? "" : "active-right"}`}>
                
                        <ul>
                            {authStore.isLoggedIn()?(
                                <li className="userInfo dropdown SiddNone">
                                    <div className="userImg">
                                        {pro_pic_url == 'null'?(
                                            <div className="nameAlpha">{localStorage.getItem('name').charAt(0)}</div>
                                        ):(
                                            <img src={pro_pic_url} />
                                        )}
                                    </div>
                                    <div className="infoSec">
                                        <h5>{localStorage.getItem('name')} <img src={arrowDown} /></h5>
                                        <span>{localStorage.getItem('is_mentor') == 'true'?'Menteer':'Mentee'}</span>
                                    </div>
                                    <ul>
                                        {localStorage.getItem('is_mentor') == 'true'?(
                                            <li><Link to={`${process.env.PUBLIC_URL}/dashboard`}>Dashboard</Link></li>
                                        ):(
                                            <li><Link to={`${process.env.PUBLIC_URL}/MenteeDashboard`}>Dashboard</Link></li>
                                        )}
                                        <li><a href="#about" className="logoutBtn" onClick={()=> {rootStore.signOut();}} disabled={rootStore.logout.loading}>Logout</a></li>
                                    </ul>
                                </li>
                            ):(
                            <li><Link className="getstarted scrollto SignUp" to={`${process.env.PUBLIC_URL}/login`}>Sign In / Sign up</Link></li>
                            )}
                            <li><a href="#" onClick={this.handleClick} className="nav-link"><i class="fa fa-times TimesNone"></i></a></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/`} className="nav-link">Home</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/aboutus`} className="nav-link">About</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/experts`} className="nav-link">Experts</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/notes`} className="nav-link">Notes</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/contactus`} className="nav-link">Contact</Link></li>
                                {authStore.isLoggedIn()?(
                                <li className="BellNone">
                                    <div className="nav-link BellIcon">
                                        <NotificationBell /> 
                                    </div>
                                </li>
                                ):null}
                                {authStore.isLoggedIn()?(
                                <li className="userInfo dropdown SiddNone2">
                                    <div className="userImg">
                                        {pro_pic_url == 'null'?(
                                            <div className="nameAlpha">{localStorage.getItem('name').charAt(0)}</div>
                                        ):(
                                            <img src={pro_pic_url} />
                                        )}
                                    </div>
                                    <div className="infoSec">
                                        <h5>{localStorage.getItem('name')} <img src={arrowDown} /></h5>
                                        <span>{localStorage.getItem('is_mentor') == 'true'?'Menteer':'Mentee'}</span>
                                    </div>
                                    <ul>
                                        {localStorage.getItem('is_mentor') == 'true'?(
                                            <li><Link to={`${process.env.PUBLIC_URL}/dashboard`}>Dashboard</Link></li>
                                        ):(
                                            <li><Link to={`${process.env.PUBLIC_URL}/MenteeDashboard`}>Dashboard</Link></li>
                                        )}
                                        <li><a href="#about" className="logoutBtn" onClick={()=> {rootStore.signOut();}} disabled={rootStore.logout.loading}>Logout</a></li>
                                    </ul>
                                </li>
                            ):(
                            <li><Link className="getstarted scrollto" to={`${process.env.PUBLIC_URL}/login`}>Sign In / Sign up</Link></li>
                            )}
                             
                        </ul>                    
                    </nav>
                </div>
            </header>
        );
    }
}
export default Header;