import React, { Component } from 'react';
import './style.scss';
import PlanBox from '../../../assets/images/PlanBox.png';
import PlanBoxSelected from '../../../assets/images/PlanBoxSelected.png';
import { Link } from 'react-router-dom';

function ChooseYourPlan() {
    return (
        <div className="rowes ChooseYourPlanSec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center Choose">
                        <h1 className="SecTitle">Get started now </h1>
                        <p className="SecMeta">Choose the plan that suits you best</p>
                        <div className="row PlanRow">
                            <div className="col-md-4 PadRight Mb">
                                <div className="PlanBox" style={{ backgroundImage: `url(${PlanBox})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
                                    <strong>$9.99 <span>/ Month</span></strong>
                                    <p>Unlimited access to all Notes</p>
                                    {localStorage.getItem('token') != null && localStorage.getItem('token') != '' ? (
                                        <Link className="w-btn" to={`${process.env.PUBLIC_URL}/notes/`}>Subscribe</Link>
                                    ) : (
                                        <Link className="w-btn" to={`${process.env.PUBLIC_URL}/signup`}>Register</Link>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4 Mb">
                                <div className="PlanBox selected" style={{ backgroundImage: `url(${PlanBoxSelected})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
                                    <strong>$49.99 <span>/ Month</span></strong>
                                    <p>Unlimited access to all Notes & 2 hours of Menteership</p>
                                    {localStorage.getItem('token') != null && localStorage.getItem('token') != '' ? (
                                        <Link className="w-btn" to={`${process.env.PUBLIC_URL}/notes/`}>Subscribe</Link>
                                    ) : (
                                        <Link className="w-btn" to={`${process.env.PUBLIC_URL}/signup`}>Register</Link>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4 PadLeft Mb">
                                <div className="PlanBox" style={{ backgroundImage: `url(${PlanBox})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
                                    <strong>$124.99 <span>/ Month</span></strong>
                                    <p>Unlimited access to all Notes & 6 hours of Menteership</p>
                                    {localStorage.getItem('token') != null && localStorage.getItem('token') != '' ? (
                                        <Link className="w-btn" to={`${process.env.PUBLIC_URL}/notes/`}>Subscribe</Link>
                                    ) : (
                                        <Link className="w-btn" to={`${process.env.PUBLIC_URL}/signup`}>Register</Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChooseYourPlan;