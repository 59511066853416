import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import authStore from '../../../stores/authStore';
import LoginLeftBg from '../../../assets/images/LoginLeftBg.png';
import LogoCircle from '../../../assets/images/LogoCircle.svg';
import logoWhite from'../../../assets/images/logoWhite.png';
import './Login.scss';
const $ = require('jquery');

@observer class Login extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
          isToggleOn: true,
          windowWidth: window.innerWidth
      };
      this.handleClick = this.handleClick.bind(this);
      
    }
  componentDidMount() {
      $('a[href="'+window.location.pathname+'"]').addClass('active');       
  }
  handleClick() {
      this.setState(state => ({
        isToggleOn: !state.isToggleOn
      }));
  } 
   handleKeyPress = (event) => {
      var code = event.keyCode || event.which;
      if (code === 13) {
         authStore.signIn();
      }
   }

   render() {
      const { innerWidth: width, innerHeight: height } = window;
      return (
    <form onKeyPress={this.handleKeyPress.bind(this)}>
         <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
         <div className="rowes LoginSec">
               <div className="col-md-6 CoverArea" style={{ backgroundImage: `url(${LoginLeftBg})` }}>
                  <img src={LogoCircle} className="LogoCircle" />
               </div>               
               <div className="col-md-6 MainArea">
                  <img src={logoWhite} className="Logo"/>
                  <a onClick={this.handleClick} className="MyBaar">
                     <i class="fa fa-bars"></i>
                  </a>
                     <div className={`UpperMenu ${this.state.isToggleOn==true ? "" : "active-right"}`}>
                        <li><a href="#" onClick={this.handleClick} className="nav-link"><i class="fa fa-times TimesNone"></i></a></li>
                        <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                        <Link to={`${process.env.PUBLIC_URL}/aboutus`}>About</Link>
                        <Link to={`${process.env.PUBLIC_URL}/experts`}>Experts</Link>
                        <Link to={`${process.env.PUBLIC_URL}/notes`}>Notes</Link>
                        <Link to={`${process.env.PUBLIC_URL}/contactus`}>Contact</Link>
                        <Link to={`${process.env.PUBLIC_URL}/signup`}>Sign Up</Link>
                     </div>
                        <div className="Form col-md-12">
                           <div className="FormBody">
                              <h2 className="FormTitle">Welcome Back</h2>
                                 <div className="form-group">
                                    <label>Email Address</label>
                                    <input
                                       className="form-control"
                                       type="text"
                                       value={authStore.loginMod.input.email}
                                       onChange={(e) => {
                                          authStore.loginMod.input.email = e.target.value;
                                       }}
                                    />
                                 </div>
                                 <div className="form-group">
                                    <label>Password</label>
                                    <input
                                       className="form-control"
                                       type="password"
                                       value={authStore.loginMod.input.password}
                                       onChange={(e) => {
                                          authStore.loginMod.input.password = e.target.value;
                                       }}
                                    />
                                 </div>                                 
                                 <div className="form-group">
                                    <div className="row align-items-center">
                                       <div className="col-sm-6 col-md-6">
                                          <button className="btn btn-primary px-4 SubmitBtns" type="button" onClick={() => {
                                             authStore.signIn();
                                          }} disabled={authStore.loginMod.loading}>Submit</button>

                                       </div>
                                       <div className="col-sm-6 col-md-6 text-right">
                                          <Link to={`${process.env.PUBLIC_URL}/forgotpass`} style={{ color: '#000' }}>Forgot Password</Link>
                                       </div>
                                    </div>
                                 </div>
                                 <p className="text-center">Don't have an account? <Link className="btm-link" to={`${process.env.PUBLIC_URL}/signup`}>Sign up</Link></p>
                           </div>         
                        </div>                  
                  </div>
            </div>
      </form>
      );
   }
}
export default Login;