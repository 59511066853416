import React from 'react';
import {observer} from 'mobx-react';
import Header from '../../../../components/Containers/Header/Header';
import NotesBanner from '../../../../components/Notes/Banner';
import NoteBox from '../../../../components/Notes/NoteBox';
import {Checkbox} from '@material-ui/core';
import './styles.scss';
import notesStore from '../../../../stores/notesStore';
import BoundInput from '../../../../components/Form/BoundInput';
import MenteereLoader from '../../../../components/MenteereLoader';
import NotesListNoResultsState from '../../../../components/Notes/NotesListNoResultsState';
import Footer from '../../../../components/Containers/Footer';

@observer class NotesList extends React.Component {

    componentDidMount() {
        notesStore.getFilterCurriculums();
        notesStore.getFilterSubjects();
        notesStore.getFilterUniversities();
        notesStore.getNotes();
    }

    render() {
        return (
            <div className="NotesListPage">
            <Header />
                <NotesBanner />
                <div className="col-md-12 mt-5">
                    <div className="row">
                        <div className="col-md-3 FilterSide">
                            <h4>Search Your Notes</h4>
                            <div className="FilterBox">
                                <h5>Note Type</h5>
                                <span className="BgHalf2"></span>
                                <div className="FilterItems">
                                    <label className="FilterItem">
                                        <Checkbox
                                            checked={notesStore.NotesListModel.typeFilter.selectedId==0?true:false}
                                            onChange={(val) => { 
                                                notesStore.NotesListModel.typeFilter.selectedId = 0;
                                                notesStore.getNotes();
                                            }}
                                            color="secondary"
                                        />
                                        <span className="f_title">Any</span>
                                    </label>
                                    <label className="FilterItem">
                                        <Checkbox
                                            checked={notesStore.NotesListModel.typeFilter.selectedId==1?true:false}
                                            onChange={(val) => { 
                                                notesStore.NotesListModel.typeFilter.selectedId = 1;
                                                notesStore.getNotes();
                                            }}
                                            color="secondary"
                                        />
                                        <span className="f_title">Digital Notes</span>
                                    </label>
                                    <label className="FilterItem">
                                        <Checkbox
                                            checked={notesStore.NotesListModel.typeFilter.selectedId==2?true:false}
                                            onChange={(val) => { 
                                                notesStore.NotesListModel.typeFilter.selectedId = 2;
                                                notesStore.getNotes();
                                            }}
                                            color="secondary"
                                        />
                                        <span className="f_title">Handwritten Notes</span>
                                    </label>
                                </div>
                            </div>

                            <div className="FilterBox">                           
                                <h5>Curriculum</h5>
                                <span className="BgHalf2"></span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Curriculum"
                                    value={notesStore.NotesListModel.curriculumFilter.keywords}
                                    onChange={(e) => {  
                                        notesStore.NotesListModel.curriculumFilter.keywords = e.target.value;
                                        notesStore.getFilterCurriculums();
                                    }}
                                />
                                <div className="FilterItems">
                                    {notesStore.NotesListModel.curriculumFilter.loading?(
                                        <div className="text-center">
                                            <MenteereLoader type="spinner" />
                                        </div>
                                    ):null}
                                    {!notesStore.NotesListModel.curriculumFilter.loading&&notesStore.NotesListModel.curriculumFilter.data.length>0?notesStore.NotesListModel.curriculumFilter.data.map((item) =>
                                        <label className="FilterItem">
                                            <Checkbox
                                                checked={notesStore.NotesListModel.curriculumFilter.selectedIds.indexOf(item.id) > -1}
                                                onChange={(val) => { 
                                                    if(notesStore.NotesListModel.curriculumFilter.selectedIds.indexOf(item.id) > -1) {
                                                        notesStore.NotesListModel.curriculumFilter.selectedIds.splice(notesStore.NotesListModel.curriculumFilter.selectedIds.indexOf(item.id), 1);
                                                    } else {
                                                        notesStore.NotesListModel.curriculumFilter.selectedIds.push(item.id);
                                                    }
                                                    notesStore.getNotes();
                                                }}
                                                color="secondary"
                                            />
                                            <span className="f_title">{item.title}</span>
                                            <span className="count"></span>
                                        </label>
                                    ):null}
                                </div>
                            </div>
                            <div className="FilterBox">
                                <h5>Subject</h5>
                                <span className="BgHalf"></span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Subjects"
                                    value={notesStore.NotesListModel.subjectFilter.keywords}
                                    onChange={(e) => {  
                                        notesStore.NotesListModel.subjectFilter.keywords = e.target.value;
                                        notesStore.getFilterSubjects();
                                    }}
                                />
                                <div className="FilterItems">
                                    {notesStore.NotesListModel.subjectFilter.loading?(
                                        <div className="text-center">
                                            <MenteereLoader type="spinner" />
                                        </div>
                                    ):null}
                                    {!notesStore.NotesListModel.subjectFilter.loading&&notesStore.NotesListModel.subjectFilter.data.length>0?notesStore.NotesListModel.subjectFilter.data.map((item) =>
                                        <label className="FilterItem">
                                            <Checkbox
                                                checked={notesStore.NotesListModel.subjectFilter.selectedIds.indexOf(item.id) > -1}
                                                onChange={(val) => { 
                                                    if(notesStore.NotesListModel.subjectFilter.selectedIds.indexOf(item.id) > -1) {
                                                        notesStore.NotesListModel.subjectFilter.selectedIds.splice(notesStore.NotesListModel.subjectFilter.selectedIds.indexOf(item.id), 1);
                                                    } else {
                                                        notesStore.NotesListModel.subjectFilter.selectedIds.push(item.id);
                                                    }
                                                    notesStore.getNotes();
                                                }}
                                                color="secondary"
                                            />
                                            <span className="f_title">{item.title}</span>
                                            <span className="count"></span>
                                        </label>
                                    ):null}
                                </div>
                            </div>
                            <div className="FilterBox">
                                <h5>University</h5>
                                <span className="BgHalf2"></span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Subjects"
                                    value={notesStore.NotesListModel.universitiesFilter.keywords}
                                    onChange={(e) => {  
                                        notesStore.NotesListModel.universitiesFilter.keywords = e.target.value;
                                        notesStore.getFilterUniversities();
                                    }}
                                />
                                <div className="FilterItems">
                                    {notesStore.NotesListModel.universitiesFilter.loading?(
                                        <div className="text-center">
                                            <MenteereLoader type="spinner" />
                                        </div>
                                    ):null}
                                    {!notesStore.NotesListModel.universitiesFilter.loading&&notesStore.NotesListModel.universitiesFilter.data.length>0?notesStore.NotesListModel.universitiesFilter.data.map((item) =>
                                        <label className="FilterItem">
                                            <Checkbox
                                                checked={notesStore.NotesListModel.universitiesFilter.selectedIds.indexOf(item.id) > -1}
                                                onChange={(val) => { 
                                                    if(notesStore.NotesListModel.universitiesFilter.selectedIds.indexOf(item.id) > -1) {
                                                        notesStore.NotesListModel.universitiesFilter.selectedIds.splice(notesStore.NotesListModel.universitiesFilter.selectedIds.indexOf(item.id), 1);
                                                    } else {
                                                        notesStore.NotesListModel.universitiesFilter.selectedIds.push(item.id);
                                                    }
                                                    notesStore.getNotes();
                                                }}
                                                color="secondary"
                                            />
                                            <span className="f_title">{item.title}</span>
                                            <span className="count"></span>
                                        </label>
                                    ):null}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 notesGrid">
                            <div className="row">
                                {notesStore.NotesListModel.dataLoading?(
                                    <MenteereLoader type="noteslisting" />
                                ):null}
                                {notesStore.NotesListModel.data.length>0&&!notesStore.NotesListModel.dataLoading?notesStore.NotesListModel.data.map((item) => 
                                    <div className="col-md-4">
                                        <NoteBox data={item} />
                                    </div>
                                ):null}
                                {!notesStore.NotesListModel.dataLoading&&notesStore.NotesListModel.data.length==0?(
                                    <NotesListNoResultsState />
                                ):null}
                            </div>                       
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
        );
    }
}

export default NotesList;