import axios from 'axios';
import CONSTANTS from '../constants';
import notify from '../../components/notify';

//const baseUrl = 'http://206.189.129.231/admin/api/v1';
const baseUrl = CONSTANTS.API_BASE;

class Service {
    constructor(props) {
        this.refreshHeader(()=>{
            
        });
    }

    refreshHeader(callback) {
        const sessionToken = localStorage.getItem('token');
        let srh = {
            headers: { 
                Authorization: sessionToken,
                Accept: 'application/json',
            }
        };
        let service = axios.create(srh);
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service = service;
        return callback();
    }

    handleSuccess(response) {
        return response;
    }

    handleError = (error) => {
        let code = 400;
        let message = '';
        if(typeof error.response != 'undefined') {
            if(typeof error.response.data.meta.code != 'undefined') {
                code = error.response.data.meta.code;
                message = error.response.data.meta.message;
            }
        }
        
        switch (code) {
            case 401: 
                //EventRegister.emit('SessionExpiredEvent', 'Session Expired');  
                localStorage.removeItem('token');
                window.location.href=`${process.env.PUBLIC_URL}/login`;
                break;
            case 404:
                //alert('Resource not found');
                break;
            default:
                //alert(JSON.stringify(error.response.data.error.replace(/['"]+/g, '')));
                // alert('Internal server error, please retry');
                if(message != '')
                {   
                    notify(message, 'danger');
                }
                break;
        }
        return Promise.reject(error)
    }

    redirectTo = (document, path) => {
        document.location = path
    }

    get(path, callback) {
        var respp = this.service.get(`${baseUrl}/${path}`).then(
            (response, req) => {
                callback(response.status, response.data);
            }
        );
        return respp;
    }

    patch(path, payload, callback) {
        return this.service.request({
            method: 'PATCH',
            url: `${baseUrl}/${path}`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    post(path, payload, callback) {
        return this.service.request({
            method: 'POST',
            url: `${baseUrl}/${path}`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    put(path, payload, callback) {
        return this.service.request({
            method: 'PUT',
            url: `${baseUrl}/${path}`,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }
}

export default new Service;  