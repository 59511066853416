import React from 'react';
import { observer } from 'mobx-react';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import { Link } from 'react-router-dom';
import './styles.scss';
import SetAvailBanner from '../../../../assets/images/SetAvailBanner.svg';
import Booking from '../../../../assets/images/Booking.svg';
import "react-datepicker/dist/react-datepicker.css";
import Calendar from 'react-calendar';
import mentorshipStore from '../../../../stores/mentorshipStore';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import notify from '../../../../components/notify';
import { Loader } from 'semantic-ui-react';
import Select from 'react-select';
import { Checkbox, Select as MSelect, Input, MenuItem, ListItemText, Chip } from '@material-ui/core';


const $ = require('jquery');

@observer class SetAvailability extends React.Component {

    componentDidMount() {
        this.setDate(new Date());
    }

    async setDate(date) {
        var newdate = moment(date).format('YYYY-MM-DD');
        mentorshipStore.setAvailPageModel.selectedDate = newdate;
        mentorshipStore.setAvailPageModel.selectedDateFull = date;

        mentorshipStore.setAvailPageModel.selectedDateUTC = new Date(newdate+' 00:00:00');
        
        mentorshipStore.initSlots(() => {

        });
    }

    async deleteSlot(id) {
        mentorshipStore.deleteSlot(id, () => {
            notify('Slot deleted successfully', 'success');
            this.setDate(mentorshipStore.setAvailPageModel.selectedDate);
        });
    }

    async showAddSlotModal(e) {
        mentorshipStore.setAvailPageModel.addModalVisible = true;
        mentorshipStore.setAvailPageModel.addSlotForm.formInputs.from_date = new Date(mentorshipStore.setAvailPageModel.selectedDateFull).toString();
    }

    async hideAddSlotModal() {
        mentorshipStore.setAvailPageModel.addModalVisible = false;
    }

    async submitSlotForm() {
        mentorshipStore.submitSlotForm(() => {
            notify('Slot Added successfully', 'success');
            mentorshipStore.setAvailPageModel.addModalVisible = false;
            this.setDate(mentorshipStore.setAvailPageModel.selectedDateFull);
        });
    }

    render() {
        const Model = mentorshipStore.setAvailPageModel;
        const selectedDate = Model.selectedDateFull;
        return (
            <DashboardBody>
                <div className="SetAvailabilityPage">
                    <div className="col-md-12">
                        <div className=" row welcomeBg">
                            <div className="col-md-9 padd25">
                                <h4 className="WelText">Set your availability here</h4>
                                <p className="paraWel">Tell us when you'll be available for one-on-one mentorship sessions. These slots will be visible to your potential mentees.</p>
                            </div>
                            <div className="col-md-3">
                                <img src={Booking} className="img-responsive SetRight" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            {moment(selectedDate).format('dddd - DD MMMM, YYYY')}
                            <Calendar
                                value={selectedDate}
                                onChange={(date) => {
                                    this.setDate(date);
                                }}
                            />

                        </div>

                        <div className="col-md-6">
                            {Model.loading ? (
                                <div className="col-md-12 loaderContainer" style={{ marginTop: 100 }}>
                                    <Loader indeterminate active>Fetching Data</Loader>
                                </div>
                            ) : (
                                <div className="DaySchedule">
                                    <div className="ScheduleHeader">
                                        <h4>{moment(Model.selectedDateFull).format('dddd - DD MMMM, YYYY')}</h4>
                                        <button className="btn btn-primary" onClick={(e) => { this.showAddSlotModal() }}>
                                            Add Slot
                                    </button>
                                    </div>
                                    <div className="ScheduleBody">
                                        {Model.dayScheduleData.map((item) => {
                                            if (!item.is_booked) {
                                                return <div className="DayScItem">
                                                    <p>Free Slot {moment(new Date(item.start_time)).format('hh:mm A')} to {moment(new Date(item.end_time)).format('hh:mm A')} <span className="icon-trash icon DeleteSlotIcon" onClick={() => { this.deleteSlot(item.id) }}></span></p>
                                                </div>
                                            } else {
                                                return <div className="DayScItem BookedItem">
                                                    <p>{item.bookingInfo.UserName} | {moment(new Date(item.start_time)).format('hh:mm A')} to {moment(new Date(item.end_time)).format('hh:mm A')}</p>
                                                </div>
                                            }
                                        })}
                                        {Model.dayScheduleData.length == 0 ? (
                                            <div class="NoRes">No records to display</div>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Modal backdrop="static" show={Model.addModalVisible} onHide={() => { this.hideAddSlotModal() }} dialogClassName="AddSlotModal">
                    <Modal.Body>
                        <div class="row">
                            <div class="col-md-10 mb-4">
                                <h4>Add Availability Slots</h4>
                            </div>
                            <div class="col-md-2 PopCloseIcon" onClick={() => { this.hideAddSlotModal() }}>
                                <span className="icon icon-close"></span>
                            </div>
                            <div class="col-md-3">
                                <p className="mt-2"><span class="icon icon-clock"></span> Select Time</p>
                            </div>
                            <div class="col-md-8 TimeDropDown mb-4">
                                <div style={{ flex: 1 }}>
                                    <Select
                                        options={Model.addSlotForm.timeSelectItems}
                                        value={Model.addSlotForm.timeSelectItems.filter(option => option.value === Model.addSlotForm.formInputs.from_time)}
                                        onChange={(item) => {
                                            Model.addSlotForm.formInputs.from_time = item.value;
                                            Model.addSlotForm.formInputs.from_time_full = new Date(Model.addSlotForm.formInputs.from_date);

                                            var curval = item.value;
                                            curval = curval.split(':');
                                            Model.addSlotForm.formInputs.from_time_full.setHours(curval[0]);
                                            Model.addSlotForm.formInputs.from_time_full.setMinutes(0);
                                            Model.addSlotForm.formInputs.from_time_full.setSeconds(0);

                                            curval[0] = parseInt(curval[0]) + 1;
                                            if (curval[0] < 10) { curval[0] = '0' + curval[0]; }
                                            curval = curval.join(':');
                                            Model.addSlotForm.formInputs.to_time = curval;

                                            Model.addSlotForm.formInputs.to_time_full = new Date(Model.addSlotForm.formInputs.from_date);
                                            var hrs = curval.split(':');
                                            Model.addSlotForm.formInputs.to_time_full.setHours(hrs[0]);
                                            Model.addSlotForm.formInputs.to_time_full.setMinutes(0);
                                            Model.addSlotForm.formInputs.to_time_full.setSeconds(0);
                                        }}
                                    />

                                </div>
                                <p>To</p>
                                <div style={{ flex: 1 }}>
                                    <Select
                                        options={Model.addSlotForm.timeSelectItems}
                                        value={Model.addSlotForm.timeSelectItems.filter(option => option.value === Model.addSlotForm.formInputs.to_time)}
                                        onChange={(item) => {
                                            Model.addSlotForm.formInputs.to_time = item.value;
                                            Model.addSlotForm.formInputs.to_time_full = new Date(Model.addSlotForm.formInputs.from_date);
                                            var hrs = item.value.split(':');
                                            Model.addSlotForm.formInputs.to_time_full.setHours(hrs[0]);
                                        }}
                                    />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <p className="mt-2"><span class="icon icon-calendar"></span> Select Date</p>
                            </div>
                            <div class="col-md-8 TimeDropDown mb-4">
                                <DatePicker className="form-control" dateFormat="dd-MM-yyyy" selected={new Date(Model.addSlotForm.formInputs.from_date)} onChange={date => { Model.addSlotForm.formInputs.from_date = date.toString(); }} />
                                {Model.addSlotForm.formInputs.repeat ? (<p>To</p>) : null}
                                {Model.addSlotForm.formInputs.repeat ? (
                                    <DatePicker className="form-control" dateFormat="dd-MM-yyyy" selected={new Date(Model.addSlotForm.formInputs.to_date)} onChange={date => { Model.addSlotForm.formInputs.to_date = date.toString() }} />
                                ) : null}
                            </div>
                            {Model.addSlotForm.formInputs.repeat ? (
                                <div class="col-md-3">
                                    <p className="mt-2"><span class="icon icon-calendar"></span> Days</p>
                                </div>
                            ) : null}
                            {Model.addSlotForm.formInputs.repeat ? (
                                <div class="col-md-8 mb-3">

                                    <MSelect
                                        labelId="demo-mutiple-checkbox-label"
                                        id="demo-mutiple-checkbox"
                                        multiple
                                        value={Model.addSlotForm.formInputs.selectedDays}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            Model.addSlotForm.formInputs.selectedDays = e.target.value;
                                        }}
                                        input={<Input />}
                                        renderValue={(selected) => { return 'Select Days' }}
                                    >
                                        {Model.addSlotForm.daysSelectOption.map((name) => (
                                            <MenuItem key={name.label} value={name.value}>
                                                <Checkbox checked={Model.addSlotForm.formInputs.selectedDays.indexOf(name.value) > -1} style={{ color: "#fed703" }} />
                                                <ListItemText primary={name.label} />
                                            </MenuItem>
                                        ))}
                                    </MSelect>
                                    <div className="chips mt-2">
                                        {Model.addSlotForm.formInputs.selectedDays.map((value) => {
                                            let cval = Model.addSlotForm.daysSelectOption.filter(option => option.value === value);
                                            return <Chip key={value} label={cval[0].label} className='chip' />
                                        })}
                                    </div>
                                </div>
                            ) : null}
                            <div class="col-md-3">&nbsp;</div>
                            <div class="col-md-7 TimeDropDown mb-2">
                                <label className="smlabel">
                                    <Checkbox
                                        checked={Model.addSlotForm.formInputs.repeat}
                                        onChange={(val) => { Model.addSlotForm.formInputs.repeat = !Model.addSlotForm.formInputs.repeat }}
                                        style={{ color: "#fed703" }}
                                    />
                                    Repeat Schedule</label>
                            </div>

                            <div className="col-md-12">
                                <button type="button" class="btn btn-primary btn-bit-wide" disabled={Model.addSlotForm.formSubmitting} onClick={() => {
                                    this.submitSlotForm();
                                }}>Save</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </DashboardBody>
        );
    }
}

export default SetAvailability;