import { makeAutoObservable } from 'mobx';
import Service from '../shared/service/service';
import axios from 'axios';
import CONSTANTS from '../shared/constants';
const baseUrl = CONSTANTS.API_BASE;

class Store {

    constructor() {
        makeAutoObservable(this);
    }

    verifyStep1Model={
        infoLoading: false,
        submitLoading: false,
        doc_uploads: {},
        infoData: null,
        loaders: {
            personal_doc_ids: false,
            highschool_doc_ids: false,
            other_ql_json: false,
            university_accepted_json: false
        }
    };

    getPrefilledInfo() {
        this.verifyStep1Model.infoLoading = true;
        Service.get(
        'edit_verify_user_step_1',
        (status, response) => {
            this.verifyStep1Model.infoLoading = false;
            this.verifyStep1Model.infoData = response.data;
            console.log(response.data);
        },
        ).catch(error => {
            this.verifyStep1Model.infoLoading = false;
            Service.handleError(error);
            //GLOBAL.CustomToast.show(this.errorMsg);
        });
    }

    update(submitting, callback) {
        this.verifyStep1Model.submitLoading = true;
        this.verifyStep1Model.infoData.prefilledInfo.isSubmitting = submitting;
        Service.post(
        'verify_user_step_1',
        this.verifyStep1Model.infoData.prefilledInfo,
        (status, response) => {
            this.verifyStep1Model.submitLoading = false;
            return callback();
        },
        ).catch(error => {
            this.verifyStep1Model.submitLoading = false;
            Service.handleError(error);
            //GLOBAL.CustomToast.show(this.errorMsg);
        });
    }

    uploadVdoc(file, field, callback) {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("field", field);
        this.verifyStep1Model.loaders[`${field}`] = true;
        axios.post(`${baseUrl}/upload_vdocs`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            this.verifyStep1Model.loaders[`${field}`] = false;
            return callback(response.data);
        });
    }

    uploadJSONVDoc(file, field, index, callback) {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("index", index);
        formData.append("field", field);
        this.verifyStep1Model.loaders[`${field}`] = true;
        axios.post(`${baseUrl}/upload_json_vdocs`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            this.verifyStep1Model.loaders[`${field}`] = false;
            return callback(response.data);
        });
    }

    removeVdoc(doc_id, field) {
        Service.post(
        'remove_vdocs',
        { field, doc_id },
        (status, response) => { },
        ).catch(error => {
            Service.handleError(error);
        });
    }

    removeJSONVdoc(doc_id) {
        Service.post(
        'remove_json_vdocs/'+doc_id,
        {},
        (status, response) => { },
        ).catch(error => {
            Service.handleError(error);
        });
    }
    

    

}

const verificationStore = new Store();
export default verificationStore;