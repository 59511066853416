import React from 'react';
import Header from '../../components/Containers/Header/Header';
import Hero from '../../components/Home/Hero';
import BecomeAMentee from '../../components/Home/BecomeAMentee/BecomeAMentee';
import BecomeAMenteere from '../../components/Home/BecomeAMenteere/BecomeAMenteere';
import ChooseYourPlan from '../../components/Home/ChooseYourPlan/ChooseYourPlan';
import Footer from '../../components/Containers/Footer';
import constants from '../../shared/constants';
import ExploreUniversity from '../../components/Home/ExploreUniversity';
import Mentorship from '../../components/Home/Mentorship';
import ExploreNotes from '../../components/Home/ExploreNotes';

class Home extends React.Component {

    render() {
        return (
            <>
                <Header />
                <Hero />
                <ExploreUniversity />
                <Mentorship />
                <ExploreNotes />
                <BecomeAMentee />
                <ChooseYourPlan />
                <BecomeAMenteere />                
                <Footer />
            </>
        );
    }
}

export default Home;