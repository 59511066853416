import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import ReactNotification from 'react-notifications-component';
//import {RootStateProvider} from './stores/RootStateContext';
import 'react-notifications-component/dist/theme.css';
import 'semantic-ui-css/semantic.min.css'
import 'react-calendar/dist/Calendar.css';
import './shared/styling/app.scss';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'react-owl-carousel2/lib/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <ReactNotification />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
