import React from 'react';
import { Facebook } from 'react-content-loader'
import ContentLoader from "react-content-loader";
import Loader from "react-loader-spinner";

const NotesListingSkeleton = (props) => (
    <ContentLoader 
    speed={1.0}
    viewBox="0 0 600 360"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="21" y="9" rx="12" ry="12" width="76" height="93" /> 
    <circle cx="130" cy="52" r="24" /> 
    <rect x="20" y="125" rx="7" ry="7" width="150" height="11" /> 
    <rect x="20" y="148" rx="9" ry="9" width="85" height="11" /> 
    <rect x="25" y="199" rx="12" ry="12" width="76" height="93" /> 
    <circle cx="134" cy="242" r="24" /> 
    <rect x="24" y="315" rx="7" ry="7" width="150" height="11" /> 
    <rect x="24" y="338" rx="9" ry="9" width="85" height="11" /> 
    <rect x="211" y="11" rx="12" ry="12" width="76" height="93" /> 
    <circle cx="320" cy="54" r="24" /> 
    <rect x="210" y="127" rx="7" ry="7" width="150" height="11" /> 
    <rect x="210" y="150" rx="9" ry="9" width="85" height="11" /> 
    <rect x="209" y="201" rx="12" ry="12" width="76" height="93" /> 
    <circle cx="318" cy="244" r="24" /> 
    <rect x="208" y="317" rx="7" ry="7" width="150" height="11" /> 
    <rect x="208" y="340" rx="9" ry="9" width="85" height="11" /> 
    <rect x="402" y="11" rx="12" ry="12" width="76" height="93" /> 
    <circle cx="511" cy="54" r="24" /> 
    <rect x="401" y="127" rx="7" ry="7" width="150" height="11" /> 
    <rect x="401" y="150" rx="9" ry="9" width="85" height="11" /> 
    <rect x="400" y="201" rx="12" ry="12" width="76" height="93" /> 
    <circle cx="509" cy="244" r="24" /> 
    <rect x="399" y="317" rx="7" ry="7" width="150" height="11" /> 
    <rect x="399" y="340" rx="9" ry="9" width="85" height="11" />
  </ContentLoader>
)

const NotesDetailSkeleton = (props) => (
    <ContentLoader 
    speed={1.0}
    viewBox="0 0 600 360"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="21" y="8" rx="12" ry="12" width="240" height="274" /> 
    <rect x="305" y="16" rx="0" ry="0" width="169" height="12" /> 
    <rect x="304" y="50" rx="0" ry="0" width="85" height="8" /> 
    <rect x="305" y="77" rx="0" ry="0" width="204" height="8" /> 
    <rect x="306" y="159" rx="0" ry="0" width="204" height="8" /> 
    <rect x="306" y="182" rx="0" ry="0" width="204" height="8" /> 
    <rect x="305" y="206" rx="0" ry="0" width="204" height="8" /> 
    <rect x="302" y="243" rx="19" ry="19" width="97" height="33" /> 
    <rect x="413" y="243" rx="19" ry="19" width="97" height="33" /> 
    <rect x="306" y="133" rx="0" ry="0" width="98" height="8" /> 
    <rect x="427" y="133" rx="0" ry="0" width="79" height="8" /> 
    <rect x="305" y="105" rx="0" ry="0" width="98" height="8" />
  </ContentLoader>
)

function MenteereLoader(props) {
    switch(props.type) {
        case 'spinner':
            return (
                <div className="loader">
                    <Loader type="Oval" color={props.color||'#00BFFF'} height={props.height||40} width={props.width||40} />
                </div>
            )
        break;
        case 'spinner-bars':
            return (
                <div className="loader">
                    <Loader type="Bars" color={props.color||'#00BFFF'} height={props.height||40} width={props.width||40} />
                </div>
            )
        break;
        case 'spinner-audio':
            return (
                <div className="loader">
                    <Loader type="Audio" color={props.color||'#00BFFF'} height={props.height||40} width={props.width||40} />
                </div>
            )
        break;
        case 'spinner-dots':
            return (
                <div className="loader">
                    <Loader type="ThreeDots" color={props.color||'#00BFFF'} height={props.height||40} width={props.width||40} />
                </div>
            )
        break;
        case 'spinner-watch':
            return (
                <div className="loader">
                    <Loader type="Watch" color={props.color||'#224353'} height={props.height||40} width={props.width||40} />
                </div>
            )
        break;
        case 'noteslisting':
            return (
                <NotesListingSkeleton />
            )
        break;
        case 'notesdetail':
            return (
                <NotesDetailSkeleton />
            )
        break;
        default:
            return (
                <div className="loader">
                    <Facebook />
                </div>
            )
        break;
    }
    
} 
export default MenteereLoader;