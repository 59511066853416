import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import MenteereLoader from '../../../../components/MenteereLoader';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import earningStore from '../../../../stores/menteer/earningStore';

@observer class MenteereMyEarningsNew extends React.Component {

    componentWillMount() {
        // earningStore.getMyEarnings();
        earningStore.MyTotalEarningsModel.selectedFilter = 1;
        earningStore.getMyNewTotalEarnings();
    }

    setListFilter(num) {
        earningStore.MyTotalEarningsModel.selectedFilter = num;
        earningStore.MyTotalEarningsModel.listData = earningStore.MyTotalEarningsModel.data.filter((item) => {
            if (num == 2) {
                return item.type == 'earning'
            }
            if (num == 3) {
                return item.type == 'payout'
            }
            if (num == 1) {
                return item
            }
        });
    }

    showBreakup(startDate, endDate) {
        earningStore.MyTotalEarningsModel.showBreakupPop = true;
        earningStore.getEarningBreakup(startDate, endDate);
    }

    render() {
        // let data = subscriptionStore.MySubscriptions.data;
        var curBalance = 0
        return (
            <DashboardBody>

                <div className="MenteereMyEarningsNewPage col-md-12">
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            {earningStore.MyTotalEarningsModel.infoLoading ? (
                                <MenteereLoader type="spinner" />
                            ) : null}
                            {earningStore.MyTotalEarningsModel.data != null ? (
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Payment & Ledger</h2>
                                    </div>
                                    <div className="col-md-6 Action Buttons align-items-end text-right">
                                        <h5><strong style={{ fontWeight: 900 }}>Current Balance: USD {earningStore.MyTotalEarningsModel.data[0].curBalance}</strong></h5>
                                        <div className="ActionBtns">
                                            <button className={earningStore.MyTotalEarningsModel.selectedFilter == 1 ? 'active' : ''} onClick={() => { this.setListFilter(1) }}>All</button>
                                            <button className={earningStore.MyTotalEarningsModel.selectedFilter == 2 ? 'active' : ''} onClick={() => { this.setListFilter(2) }}>Earnings</button>
                                            <button className={earningStore.MyTotalEarningsModel.selectedFilter == 3 ? 'active' : ''} onClick={() => { this.setListFilter(3) }}>Payouts</button>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <table class="table table-responsive-sm table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Items</th>
                                                    <th className="colRed">Payouts</th>
                                                    <th className="colGreen">Earnings</th>
                                                    <th>Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {earningStore.MyTotalEarningsModel.listData.map((item) => {
                                                    return <tr>
                                                        <td>{new Date(item.date).toLocaleDateString('en-GB')}</td>
                                                        <td>
                                                            {item.type == 'earning' ? <button className="LinkStyle" onClick={() => { this.showBreakup(item.startDate, item.endDate) }}>{item.desc}</button> : item.desc}</td>
                                                        <td className="colRed">
                                                            {item.type == 'payout' ?
                                                                parseFloat(item.totalProfit).toFixed(2)
                                                                : null}
                                                        </td>
                                                        <td className="colGreen">
                                                            {item.type == 'earning' ?
                                                                parseFloat(item.totalProfit).toFixed(2)
                                                                : null}
                                                        </td>
                                                        <td>{parseFloat(item.curBalance).toFixed(2)}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {earningStore.MyTotalEarningsModel.showBreakupPop ? (
                                        <div className="breakupPopup">
                                            <div className="CloseIcon" onClick={() => { earningStore.MyTotalEarningsModel.showBreakupPop = false }}><span className="icon-close icon"></span></div>
                                            <div className="breakupPopupBody ProfitBoxes">
                                                {earningStore.MyTotalEarningsModel.breakUpDataLoading ? (
                                                    <MenteereLoader type="spinner" />
                                                ) : (
                                                    <>
                                                        {earningStore.MyTotalEarningsModel.breakUpData != null ? (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <p>Total Earnings</p>
                                                                        <strong>{parseFloat(earningStore.MyTotalEarningsModel.breakUpData.totalProfit).toFixed(2)}</strong>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <p>Mentorship Earnings</p>
                                                                        <strong>{parseFloat(earningStore.MyTotalEarningsModel.breakUpData.mentorshipProfit).toFixed(2)}</strong>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <p>Notes Earnings</p>
                                                                        <strong>{parseFloat(earningStore.MyTotalEarningsModel.breakUpData.notesProfit).toFixed(2)}</strong>
                                                                    </div>
                                                                </div>
                                                                {earningStore.MyTotalEarningsModel.breakUpData.mentorshipEarningBreakup.breakup.length > 0 ? (
                                                                    <>
                                                                        <p className="mt-4">Mentorship Earning Breakup</p>
                                                                        <table class="table table-responsive-sm table-bordered">
                                                                            <thead>
                                                                                <td>Date</td>
                                                                                <td>Mentee Name</td>
                                                                                <td>Duration</td>
                                                                                <td>Earning</td>
                                                                            </thead>
                                                                            <tbody>
                                                                                {earningStore.MyTotalEarningsModel.breakUpData.mentorshipEarningBreakup.breakup.map((inItem) =>
                                                                                    <tr>
                                                                                        <td>{new Date(inItem.SlotDate).toLocaleDateString('en-GB')}</td>
                                                                                        <td>{inItem.MenteeName}</td>
                                                                                        <td>{inItem.Slotduration}</td>
                                                                                        <td>{parseFloat(inItem.absoluteEarning).toFixed(2)}</td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </>
                                                                ) : null}
                                                                {earningStore.MyTotalEarningsModel.breakUpData.notesProfitBreakup.MentorRows.length > 0 ? (
                                                                    <>
                                                                        <p className="mt-4">Notes Earning Breakup</p>
                                                                        <table class="table table-responsive-sm table-bordered">
                                                                            <thead>
                                                                                <td>Note Title</td>
                                                                                <td>Clicks</td>
                                                                                <td>Earning</td>
                                                                            </thead>
                                                                            <tbody>
                                                                                {earningStore.MyTotalEarningsModel.breakUpData.notesProfitBreakup.MentorRows.map((inItem) =>
                                                                                    <tr>
                                                                                        <td>{inItem.NoteTitle}</td>
                                                                                        <td>{inItem.ClickCount}</td>
                                                                                        <td>{parseFloat(inItem.TotalProfit).toFixed(2)}</td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </>
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </DashboardBody>
        );
    }
}

export default MenteereMyEarningsNew;

