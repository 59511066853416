import React from 'react';

import './styles.scss';
import { Link } from 'react-router-dom';
import {observer} from 'mobx-react';
import meetingStore from '../../../../stores/meetingStore';
import MenteereLoader from '../../../../components/MenteereLoader';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import noResultFound from '../../../../assets/images/noResultFound.svg';
import moment from 'moment';
import mentorshipStore from '../../../../stores/mentorshipStore';

@observer class MentorshipDetail extends React.Component {

    componentWillMount() {
        mentorshipStore.myMentorshipDetail(this.props.match.params.id);
    }

    render() {
        let Model = mentorshipStore.MentorshipDetail;
        return (
            <DashboardBody>
                <div className="row MentorshipDetailPage">
                    
                    {Model.loading?(
                        <div className="text-center mb-5 mt-5 col-md-12">
                            <MenteereLoader type="spinner" />
                        </div>
                    ):null} 

                    <div className="col-md-12">
                    {!Model.loading && Model.data != null?(
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pageTitle mb-4">
                                    <h4>Mentorship Request <span>> {Model.data.UserNiceName}</span></h4>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="UserImg">
                                    <img src={Model.data.UserProfilePicURL} />
                                </div>
                            </div>
                            <div className="col-md-10">
                                <p><strong>Name: </strong>{Model.data.UserNiceName}</p>
                                <p><strong>Curriculum: </strong>{Model.data.UserCurriculum||'...'}</p>
                                <p><strong>Requested Date and Time: </strong>{moment(new Date(Model.data.SlotStartTime)).format('dddd - DD MMMM')} - {moment(new Date(Model.data.SlotStartTime)).format('LT')} - {moment(new Date(Model.data.SlotEndTime)).format('LT')}</p>
                                <p><strong>Subject: </strong>{Model.data.UserSubject}</p>
                                <p><strong>Description: </strong></p>
                                <p>{Model.data.UserShortDescription}</p>
                                {Model.data.ActionLoading?(
                                    <div className="col-md-12">
                                        <MenteereLoader type='spinner-watch' width={25} height={25} />
                                    </div>
                                ):(
                                    <>
                                        {Model.data.SlotApprovalStatus == 1?(
                                            <Link className="joinCallBtn" to={`${process.env.PUBLIC_URL}/`}>Join Call</Link>
                                        ):null}
                                        {Model.data.SlotApprovalStatus == 0?(
                                            <div className="ActionBtns">
                                                <button type="button" className="SuccessBtn" onClick={()=> {
                                                    mentorshipStore.approveSlot(Model.data);
                                                }}>Approve</button>
                                                <span>|</span>
                                                <button type="button" className="RejectBtn" onClick={()=> {
                                                    mentorshipStore.rejectSlot(Model.data);
                                                }}>Reject</button>
                                            </div>
                                        ):null}
                                        {Model.data.SlotApprovalStatus == -1?(
                                            <p style={{ color: 'red' }}>Rejected</p>
                                        ):null}
                                    </>
                                )}
                            </div>
                        </div>
                    ):null}
                    </div>
                </div>
            </DashboardBody>
        );
    }
}
export default MentorshipDetail;

