import React, { Component } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import mTag from '../../../assets/images/mTag.svg';

function ExpertBox(props) {
    //alert(JSON.stringify(props));
    const data = props.data;
    return (
        <div class="ExpertItem">
            <Link to={`${process.env.PUBLIC_URL}/experts/${props.data.id}`}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        <img src={data.universityImageURL} className="UniversityImg" />
                    </div>
                    <div className='Rating'>
                        <Rating name="read-only" value={data.rating} readOnly />
                        {data.rating != null ?
                            <small className='RatingNumber'> {data.rating}</small>
                            : null}
                    </div>
                </div>
                <div className="ExpertPic">
                    <img src={data.proPicURL} />
                </div>
                <div className="MidInfo">
                    <div className="MidInfoIn">
                        <h4>{data.name}{/* <div className="greenDot"></div> */}</h4>
                        <div className="ScoreSec">
                            <div className="ScoreItem">
                                <strong>{data.curriculum || '...'}</strong>
                                <small>Curriculum</small>
                            </div>
                            <div className="ScoreItem">
                                <strong>{data.curriculum_score || '...'}</strong>
                                <small>Score</small>
                            </div>
                        </div>
                        <p className="tagLine">{data.tagline || '...'}</p>
                    </div>
                </div>
            </Link>
            <div>
                <Link className="ConnectBtn" to={`${process.env.PUBLIC_URL}/bookexpert/${props.data.id}`}>Connect Now</Link>
            </div>
        </div >
    );
}

export default ExpertBox;