import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import Service from '../shared/service/service';
import CONSTANTS from '../shared/constants';
const baseUrl = CONSTANTS.API_BASE;

class Store {

    constructor() {
        makeAutoObservable(this);
    }

    myMeetingsModel={
        loading: false,
        data: null
    }

    rateMeeting={
        loading: false,
        data: null,
        popupVisible: false,
        selectedID: null,
        inputs: {
            area_1: null,
            area_2: null,
            area_3: null,
            area_4: null,
            area_5: null,
            review: '',
        },
        initialInputs: {
            area_1: null,
            area_2: null,
            area_3: null,
            area_4: null,
            area_5: null,
            review: '',
        }
    }

    getMyMeetings() {
        this.myMeetingsModel.loading = true;
        Service.get(
            'myMeetings',
            (status, response) => {
                this.myMeetingsModel.loading = false;
                this.myMeetingsModel.data = response.data;
            },
        ).catch(error => {
            this.myMeetingsModel.loading = false;
            Service.handleError(error);
        });
    }

    submitRating(callback) {
        this.rateMeeting.loading = true;
        Service.post(
            `submitRating/${this.rateMeeting.selectedID}`,
            this.rateMeeting.inputs,
            (status, response) => {
                this.rateMeeting.loading = false;
                this.rateMeeting.inputs = this.rateMeeting.initialInputs;
                this.rateMeeting.selectedID = null;
                this.getMyMeetings();
                return callback();
            },
        ).catch(error => {
            this.rateMeeting.loading = false;
            Service.handleError(error);
        });
    }
    

}

const meetingStore = new Store();
export default meetingStore;