import { makeAutoObservable, configure } from 'mobx';
import Service from '../shared/service/service';
import notify from '../components/notify';

import navdashboard from '../assets/images/navdashboard.svg';
import navuser from '../assets/images/navuser.svg';
import navmentorship from '../assets/images/navmentorship.svg';
import navmessages from '../assets/images/navmessages.svg';
import navnotes from '../assets/images/navnotes.svg';
import navnotifications from '../assets/images/navnotifications.svg';
import navpayments from '../assets/images/navpayments.svg';
import navsubscription from '../assets/images/nav-subscription.svg';

class Store {

    toast = {
        text: '',
        visible: false,
        verpos: 'bottom',
        horpos: 'left',
    }

    notifications = {
        data: null,
        loading: false,
        popVisible: false,
        unreadCount: 0,
    }

    universities = {
        data: null,
        loading: false,
    }

    CurriculumList = {
        data: null,
        loading: false,
        limit: 4,
        offset: 0
    }

    NotesList = {
        data: null,
        loading: false,
        selectedCurriculum: false,
        limit: 8,
        offset: 0
    }

    MentorsList = {
        loading: false,
        data: null,
        limit: 8,
        offset: 0
    };

    MenteereSidebar = {
        items: [
            {
                title: 'Dashboard',
                image: navdashboard,
                link: `${process.env.PUBLIC_URL}/dashboard`,
                hasDropdown: false,
                dropdownActive: false,
                dropdownItems: []
            },
            {
                title: 'Profile',
                image: navuser,
                link: `${process.env.PUBLIC_URL}/MenteerMyProfile`,
                hasDropdown: false,
                dropdownActive: false,
                dropdownItems: []
            },
            {
                title: 'Manage Notes',
                image: navnotes,
                link: `${process.env.PUBLIC_URL}/mynotes`,
                hasDropdown: true,
                dropdownActive: false,
                dropdownItems: [
                    {
                        title: 'Add Notes',
                        link: `${process.env.PUBLIC_URL}/mynotes`,
                    },
                    {
                        title: 'Last Read Notes',
                        link: `${process.env.PUBLIC_URL}/MenteereLastReadNotes`,
                    },
                    {
                        title: 'My Playlist',
                        link: `${process.env.PUBLIC_URL}/MenteereMyPlaylist`,
                    },
                ]
            },
            {
                title: 'Mentorship',
                image: navmentorship,
                link: `${process.env.PUBLIC_URL}/SetAvailability`,
                hasDropdown: true,
                dropdownActive: false,
                dropdownItems: [
                    {
                        title: 'Set Availability',
                        link: `${process.env.PUBLIC_URL}/SetAvailability`,
                    },
                    {
                        title: 'My Mentorship List',
                        link: `${process.env.PUBLIC_URL}/MentorshipList`,
                    },
                    {
                        title: 'Reviews & Ratings',
                        link: `${process.env.PUBLIC_URL}/RatingsAndReviews`,
                    },
                ]
            },
            {
                title: 'My Subscription',
                image: navsubscription,
                link: `${process.env.PUBLIC_URL}/MenteereMySubscription`,
                hasDropdown: false,
                dropdownActive: false,
                dropdownItems: []
            },
            {
                title: 'My Meetings',
                image: navsubscription,
                link: `${process.env.PUBLIC_URL}/MenteereMyMeetings`,
                hasDropdown: false,
                dropdownActive: false,
                dropdownItems: []
            },
            // {
            //     title: 'My Earnings',
            //     image: navsubscription,
            //     link: `${process.env.PUBLIC_URL}/MenteereMyEarnings`,
            //     hasDropdown: false,
            //     dropdownActive: false,
            //     dropdownItems: []
            // },
            {
                title: 'My Earnings',
                image: navsubscription,
                link: `${process.env.PUBLIC_URL}/MenteereMyEarningsNew`,
                hasDropdown: false,
                dropdownActive: false,
                dropdownItems: []
            }
        ]
    }

    MenteeSidebar = {
        items: [
            {
                title: 'Dashboard',
                image: navdashboard,
                link: `${process.env.PUBLIC_URL}/MenteeDashboard`,
                hasDropdown: false,
                dropdownActive: false,
                dropdownItems: []
            },
            {
                title: 'Profile',
                image: navuser,
                link: `${process.env.PUBLIC_URL}/MenteeMyProfile`,
                hasDropdown: false,
                dropdownActive: false,
                dropdownItems: []
            },
            {
                title: 'My Subscription',
                image: navsubscription,
                link: `${process.env.PUBLIC_URL}/MenteeMySubscription`,
                hasDropdown: false,
                dropdownActive: false,
                dropdownItems: []
            },
            {
                title: 'My Notes',
                image: navnotes,
                link: `${process.env.PUBLIC_URL}/MenteeLastReadNotes`,
                hasDropdown: true,
                dropdownActive: false,
                dropdownItems: [
                    {
                        title: 'Last Read Notes',
                        link: `${process.env.PUBLIC_URL}/MenteeLastReadNotes`,
                    },
                    {
                        title: 'My Playlist',
                        link: `${process.env.PUBLIC_URL}/MenteeMyPlaylist`,
                    },
                ]
            },
            {
                title: 'My Meetings',
                image: navsubscription,
                link: `${process.env.PUBLIC_URL}/MenteeMyMeetings`,
                hasDropdown: false,
                dropdownActive: false,
                dropdownItems: []
            },
        ]
    }

    profileBadge = {
        showDropDown: false,
        loading: false,
        pro_pic_url: localStorage.getItem('pro_pic_url')
    }

    playlistPopup = {
        visible: false,
        playlistItems: null,
        playlistItemsLoading: false,
        loading: false,
        showCreatePlaylist: false,
        inputs: {
            playlist_id: null,
            title: '',
            note_id: null,
        },
        initialInputs: {
            playlist_id: null,
            title: '',
            note_id: null,
        }
    }

    constructor() {
        makeAutoObservable(this);
        configure({
            enforceActions: "never",
        })
    }

    logout = {
        loading: false
    }

    getUniversities() {
        this.universities.loading = true;
        Service.get(
            'getUniversities',
            (status, response) => {
                this.universities.loading = false;
                this.universities.data = response.data;
            },
        ).catch(error => {
            this.universities.loading = false;
            Service.handleError(error);
        });
    }

    updateProfilePicture(base) {
        this.profileBadge.loading = true;
        Service.post(
            'updateProfilePicture',
            {
                base64: base
            },
            (status, response) => {
                notify('Profile Picture updated successfully', 'snack');
                this.profileBadge.loading = false;
                this.profileBadge.showDropDown = false;
                this.profileBadge.pro_pic_url = response.data;
                localStorage.setItem('pro_pic_url', response.data);
            },
        ).catch(error => {
            this.profileBadge.loading = false;
            Service.handleError(error);
        });
    }

    getMentors() {
        this.MentorsList.loading = true;
        let limit = this.MentorsList.limit;
        let offset = this.MentorsList.offset;
        let filterTxt = `curriculums=&universities=&limit=${limit}&offset=${offset}`;
        Service.get(
            'experts?' + filterTxt,
            (status, response) => {
                this.MentorsList.loading = false;
                this.MentorsList.data = response.data;
            },
        ).catch(error => {
            this.MentorsList.loading = false;
            Service.handleError(error);
        });
    }

    getNotes() {
        this.NotesList.loading = true;
        let limit = this.NotesList.loading;
        let offset = this.NotesList.loading;
        let filterTxt = `curriculums=${this.NotesList.selectedCurriculum}&limit=${limit}&offset=${offset}`;
        Service.get(
            'notes?' + filterTxt,
            (status, response) => {
                this.NotesList.loading = false;
                this.NotesList.data = response.data;
            },
        ).catch(error => {
            this.NotesList.loading = false;
            Service.handleError(error);
        });
    }

    getCurriculums(callback) {
        this.CurriculumList.loading = true;
        let limit = this.CurriculumList.limit;
        let offset = this.CurriculumList.offset;
        let filterTxt = `limit=${limit}&offset=${offset}`;
        Service.get(
            'getCurriculums?' + filterTxt,
            (status, response) => {
                this.CurriculumList.loading = false;
                this.CurriculumList.data = response.data;
                return callback(response.data);
            },
        ).catch(error => {
            this.CurriculumList.loading = false;
            Service.handleError(error);
        });
    }

    isLoggedIn() {
        const token = localStorage.getItem('token');
        if (!token || token === '' || token === null) {
            return false;
        }
        return true;
    }

    showAddToPlaylist(noteid) {
        this.playlistPopup.inputs.note_id = noteid;
        this.playlistPopup.visible = true;

        this.playlistPopup.playlistItemsLoading = true;
        Service.get(
            'getMyPlaylists',
            (status, response) => {
                this.playlistPopup.playlistItemsLoading = false;
                this.playlistPopup.playlistItems = response.data;
            },
        ).catch(error => {
            this.playlistPopup.playlistItemsLoading = false;
            Service.handleError(error);
        });
    }

    addToPlaylist() {
        this.playlistPopup.loading = true;
        this.playlistPopup.visible = false;
        Service.post(
            'addNoteToPlaylist',
            this.playlistPopup.inputs,
            (status, response) => {
                this.playlistPopup.loading = false;
                this.playlistPopup.inputs = this.playlistPopup.initialInputs;
                this.playlistPopup.inputs.title = '';
                this.playlistPopup.showCreatePlaylist = false;
                notify('Added to Playlist Successfully', 'snack');
            },
        ).catch(error => {
            this.playlistPopup.loading = false;
            Service.handleError(error);
        });
    }

    getMyNotifications() {
        this.notifications.loading = true;
        Service.get(
            'notifications',
            (status, response) => {
                this.notifications.loading = false;
                this.notifications.data = response.data;
            },
        ).catch(error => {
            this.notifications.loading = false;
            Service.handleError(error);
        });
    }

    updateMyNotifications(id, callback) {
        Service.post(
            'updateMyNotifications',
            {
                id: id
            },
            (status, response) => {
                this.notifications.loading = false;
                return callback();
            },
        ).catch(error => {
            this.notifications.loading = false;
            Service.handleError(error);
        });
    }




    signOut(callback) {
        this.logout.loading = true;
        Service.post(
            'signout',
            {},
            (status, response) => {
                this.logout.loading = false;
                localStorage.removeItem('token');
                window.location.href = `${process.env.PUBLIC_URL}/`;
            },
        ).catch(error => {
            this.logout.loading = false;
            localStorage.removeItem('token');
            window.location.href = `${process.env.PUBLIC_URL}/`;
            //Service.handleError(error);
        });
    }
}

const rootStore = new Store();
export default rootStore;