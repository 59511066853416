import React from 'react';
import {observer} from 'mobx-react';
import notify from '../../../../components/notify';
import constants from '../../../../shared/constants';
import endcall from '../../../../assets/images/endcall.svg';
import './styles.scss';
import { makeAutoObservable } from 'mobx';
import videoCallStore from '../../../../stores/videoCallStore';


@observer class VideoCall extends React.Component {

    componentDidMount() {
        videoCallStore.redirect = null;
        let key = this.props.location.search;
        if(key != '') {
            key = key.replace('?call=', '');
            videoCallStore.makeCall(parseInt(key));
        }
    }

    render() {
        return (
            <div className="VideoCall">
                <div className="UpperSection">
                    <video id="frontVideoElem"></video>
                    <video id="curUserVidElem"></video>
                </div>
                <div className="ActionBtns">
                    <div className="StatusText">
                        <p>{videoCallStore.StatusText[videoCallStore.callStatus]}</p>
                    </div>
                    <div className="centerSec">
                        <button className={`MuteCallBtn ${videoCallStore.isMute?'active':''}`} onClick={()=> { videoCallStore.muteCall() }}>
                            <span className="icon icon-microphone"></span>
                        </button>
                        <button className="EndCallBtn" onClick={()=> { videoCallStore.endCall() }}>
                            <img src={endcall} />
                        </button>
                        <button className={`ToggleScreenCallBtn ${videoCallStore.videoMute?'active':''}`} onClick={()=> { videoCallStore.muteVideo() }}>
                            <span className="icon icon-camrecorder"></span>
                        </button>
                    </div>
                    {/* <div className="rightSec">
                        <button className="btn btn-primary">Call Oponent</button>
                    </div> */}
                </div>
            </div>
        );
    }

}

export default VideoCall;