import { makeAutoObservable } from 'mobx';
import Service from '../shared/service/service';

class Store {

    constructor() {
        makeAutoObservable(this);
    }

    dashModel={
        infoLoading: false,
        data: null,
        verifyemailPopVisible: false
    };

    verifyEmailModel={
        loading: false,
        data: null,
        input: {
            otp: ''
        }
    };

    getDashboard() {
        this.dashModel.infoLoading = true;
        Service.get(
        'dashboard',
        (status, response) => {
            this.dashModel.infoLoading = false;
            this.dashModel.data = response.data;
            console.log(response.data);
        },
        ).catch(error => {
            this.dashModel.infoLoading = false;
            Service.handleError(error);
        });
    }

    initVerifyEmail() {
        Service.get(
        'verifyemailrequest',
        (status, response) => {},
        ).catch(error => {
            Service.handleError(error);
        });
    }

    verifyEmail(callback) {
        this.verifyEmailModel.loading = true;
        Service.post(
        'verifyemail',
        this.verifyEmailModel.input,
        (status, response) => {
            this.verifyEmailModel.loading = false;
            return callback();
        },
        ).catch(error => {
            this.verifyEmailModel.loading = false;
            Service.handleError(error);
        });
    }

}

const dashboardStore = new Store();
export default dashboardStore;