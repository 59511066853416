import React, { Component } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import ExpertsPageBanner from '../../../assets/images/ExpertsPageBanner.png';

function ExpertsBanner() {
        return (
            <div className="row ExpertsPageBanner">
                <img src={ExpertsPageBanner} className="bannerImg" />
            </div>
        );
}

export default ExpertsBanner;