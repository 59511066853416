export default {
    // API_BASE: 'http://localhost:3000/api/v1',
    API_BASE: 'https://menteerebackend.gomilestone.com:3000/api/v1',
    METATAGS: {
        home: {
            title: 'Some Meta Title',
            description: 'I am a description, and I can create multiple tags',
            canonical: 'http://example.com/path/to/page',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        },
        about: {
            title: 'About Meta Title',
            description: 'I am a description, and I can create multiple tags',
            canonical: 'http://example.com/path/to/page',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        },
    },
    QUICKBLOX_APP_ID: 89316,
    QUICKBLOX_AUTH_KEY: 'KYxqDuSGXzJzmZj',
    QUICKBLOX_AUTH_SECRET: 'Cz6S9Up9mB5qAej',
    QUICKBLOX_ACCOUNT_KEY: 'cHp7nr67bt8xbMuky2Ya'
};