import React, { Component } from 'react';
import {Link} from 'react-router-dom'; 
import {observer} from 'mobx-react';
import authStore from '../../../stores/authStore';
import LoginLeftBg from '../../../assets/images/LoginLeftBg.png';
import LogoCircle from '../../../assets/images/LogoCircle.svg';
import './styles.scss';
import notify from '../../../components/notify';

@observer class ForgotPassword extends React.Component {

    submit() {
        if(authStore.forgotPassMod.showOTPInput){
            if( authStore.forgotPassMod.input.email == '' ) {
                notify('Please enter Email first', 'danger');
                return false;
            }
            if( authStore.forgotPassMod.input.otp == '' ) {
                notify('Please enter OTP', 'danger');
                return false;
            }
            if( authStore.forgotPassMod.input.passwprd == '' ) {
                notify('Please enter Pasword', 'danger');
                return false;
            }
            if( authStore.forgotPassMod.input.confirmPassword == '' ) {
                notify('Please confirm password', 'danger');
                return false;
            }
            authStore.updatePasswordRequest(()=> {
                notify('Password Successfully Updated', 'success');
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            });
        } else {
            if( authStore.forgotPassMod.input.email == '' ) {
                notify('Please enter Email first', 'danger');
                return false;
            }
            authStore.submitForgotPasswordRequest();
        }
    }

    render() {
      const { innerWidth: width, innerHeight: height } = window;
        return (
           <div className="row ForgotPasswordSec" style={{ minHeight: height }}>
              <div className="col-md-6 CoverArea" style={{ backgroundImage: `url(${LoginLeftBg})` }}>
                 <img src={LogoCircle} className="LogoCircle" />
              </div>
              <div className="col-md-6 MainArea">
                 <div className="UpperMenu">
                    <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                    <Link to={`${process.env.PUBLIC_URL}/aboutus`}>About</Link>
                    <Link to={`${process.env.PUBLIC_URL}/experts`}>Experts</Link>
                    <Link to={`${process.env.PUBLIC_URL}/notes`}>Notes</Link>
                    <Link to={`${process.env.PUBLIC_URL}/contactus`}>Contact</Link>
                    <Link to={`${process.env.PUBLIC_URL}/signup`}>Sign Up</Link>
                 </div>
                 <div className="Form col-md-12">
                     <div className="FormBody">
                        <h2 className="FormTitle">Forgot Password</h2>
                        <div className="form-group">
                           <label>Email Address</label>
                           <input 
                              className="form-control" 
                              type="text" 
                              value={authStore.forgotPassMod.input.email} 
                              onChange={(e) => {
                                authStore.forgotPassMod.input.email = e.target.value;
                              }} 
                           />
                        </div>
                        {authStore.forgotPassMod.showOTPInput?(
                        <div className="form-group">
                           <label>OTP</label>
                           <input 
                              className="form-control" 
                              type="text" 
                              value={authStore.forgotPassMod.input.otp} 
                              onChange={(e) => {
                                authStore.forgotPassMod.input.otp = e.target.value;
                              }} 
                           />
                        </div>
                        ):null}

                        {authStore.forgotPassMod.showOTPInput?(
                        <div className="form-group">
                           <label>Password</label>
                           <input 
                              className="form-control" 
                              type="password" 
                              value={authStore.forgotPassMod.input.password} 
                              onChange={(e) => {
                                authStore.forgotPassMod.input.password = e.target.value;
                              }} 
                           />
                        </div>
                        ):null}

                        {authStore.forgotPassMod.showOTPInput?(
                        <div className="form-group">
                           <label>Confirm Password</label>
                           <input 
                              className="form-control" 
                              type="password" 
                              value={authStore.forgotPassMod.input.confirmPassword} 
                              onChange={(e) => {
                                authStore.forgotPassMod.input.confirmPassword = e.target.value;
                              }} 
                           />
                        </div>
                        ):null}

                        <div className="form-group">
                           <div className="row align-items-center">
                              <div className="col-md-6">
                                 <button className="btn btn-primary px-4" type="button" onClick={()=> { this.submit() }} disabled={authStore.forgotPassMod.loading}>{authStore.forgotPassMod.showOTPInput?'Submit':'Get OTP'}</button>
                              </div>
                           </div>
                        </div>
                        <p className="text-center">Remember Password? <Link className="btm-link" to={`${process.env.PUBLIC_URL}/login`}>Login</Link></p>
                     </div>
                  </div>
              </div>
           </div>
       /*  <div className="col-md-4">
            <div className="card-group">
               <div className="card p-4">
                  <div className="card-body">
                     <img src={logo} alt="logo" width="150" className="c-sidebar-brand-full" />
                     
                     <p className="text-muted">Sign In to your account</p>
                     <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                              <span className="c-icon icon-user"></span>
                           </span>
                        </div>
                        <input 
                            className="form-control" 
                            type="text" 
                            placeholder="Username" 
                            value={authStore.loginMod.input.email} 
                            onChange={(e) => {
                                authStore.loginMod.input.email = e.target.value;
                            }} 
                        />
                     </div>
                     <div className="input-group mb-4">
                        <div className="input-group-prepend">
                           <span className="input-group-text">
                              <span className="c-icon icon-lock"></span>
                           </span>
                        </div>
                        <input 
                            className="form-control" 
                            type="password" 
                            placeholder="Password" 
                            value={authStore.loginMod.input.password} 
                            onChange={(e) => {
                                authStore.loginMod.input.password = e.target.value;
                            }} 
                        />
                     </div>
                     <div className="row">
                        <div className="col-6">
                           <button className="btn btn-info px-4" type="button" onClick={()=>{
                               authStore.signIn();
                           }}>Login</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
        </div> */
        );
    }
}
export default ForgotPassword;