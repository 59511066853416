import React, { Component } from 'react';
import {observer} from 'mobx-react';
import rootStore from '../../../stores/rootStore';
import {Link} from 'react-router-dom';
import './styles.scss';
import Slider from "react-slick";
import ExpertBox from '../../Experts/ExpertBox';
import Mentorshiptitle from '../../../assets/images/Mentorshiptitle.svg';

@observer class Mentorship extends React.Component {

    componentDidMount() {
        rootStore.getMentors();
    }

    render() {
        const options = {
            centerMode: true, 
            centerPadding:'200px',          
            slidesToShow: 3,
            initialSlide: 0,
            prevArrow: <div class='nav-btn prev-slide'><span class='icon icon-arrow-left'></span></div>,
            nextArrow: <div class='nav-btn next-slide'><span class='icon icon-arrow-right'></span></div>,
            adaptiveHeight: false,
            dots: true,   
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {                     
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      dots: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      centerPadding: '0px',
                      dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      centerPadding: '0px',
                      dots: true
                    }
                },
                {
                    breakpoint: 300,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      dots: true
                    }
                  },
                
            ]
        };
        return (
            <div className="rowes MentorshipSec">
                <div className="col-md-12 text-center SecHead">
                    <img src={Mentorshiptitle} className="MentorImg" />
                    <span>Connect one-on-one with our Menteers from top-universities</span>
                </div>
                <div className="col-md-12 text-center SliderSc">
                    {rootStore.MentorsList.loading?'Loading':null}
                    {!rootStore.MentorsList.loading && rootStore.MentorsList.data!=null?(
                        <Slider {...options}>
                            {rootStore.MentorsList.data.map((item)=>
                                <div className="col-md-12">
                                    <ExpertBox data={item} />
                                </div>
                            )}
                        </Slider>
                    ):null}
                </div>
                <div className="col-md-12 text-center">
                    <Link className="btn btn-primary BtnSets" to={`${process.env.PUBLIC_URL}/experts`}>View More</Link>
                </div>
            </div>
        );
    }
}

export default Mentorship;