import React from 'react';
import {observer} from 'mobx-react';
import Header from '../../../../components/Containers/Header/Header';
import './styles.scss';
import MenteereLoader from '../../../../components/MenteereLoader';
import notesStore from '../../../../stores/notesStore';
import { Link } from 'react-router-dom';
import NoteBox from '../../../../components/Notes/NoteBox';
import Footer from '../../../../components/Containers/Footer';
import Rating from '@material-ui/lab/Rating';


@observer class ExpertDetail extends React.Component {

    componentDidMount() {
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        });
        this.setPage();    
    }

    setPage() {
        notesStore.getExpertDetail(this.props.match.params.id, ()=> {
            notesStore.getNotesByExpertID(this.props.match.params.id);
        });
    }

    render() {
        const Model = notesStore.ExpertDetailModel.data;
        return (
            <div className="ExpertsDetailPage">
                <Header />
                <div className="HeaderMargin"></div>
                <div className="container">
                    <div className="row">
                        {notesStore.ExpertDetailModel.loading?(
                        <div className="col-md-12">
                            <MenteereLoader type="notesdetail" />
                        </div>
                        ):null}
                        {!notesStore.ExpertDetailModel.loading && notesStore.ExpertDetailModel.data!=null?(
                        <div className="row">
                            <div className="col-md-7 ExperPictureSide">
                                <div className="ExpertPic">
                                    <img src={Model.proPicURL} />
                                </div>
                                <div className="ExpertMeta">
                                    <h3>{Model.ExpertName}</h3>
                                    <p>{Model.universityName||'...'}</p>
                                    <p><b>Curricula: </b>{Model.curriculum||'...'}</p>
                                    <p><b>Curricula Score: </b>{Model.curriculumScore||'...'}</p>
                                </div>
                            </div>
                            <div className="col-md-5 ExpertBuySide">
                                <h3 style={{ opacity: 0 }}>{Model.ExpertName}</h3>
                                <Rating name="read-only" value={Model.rating} readOnly />
                                <p className="mt-2 mb-4"><b>Consultation Fee: </b> INR {Model.Price||'...'} / Hour</p>
                                {localStorage.getItem('token') != null?(
                                    <Link to={`${process.env.PUBLIC_URL}/bookexpert/${Model.id}`} className="btn btn-primary">Book Now</Link>
                                ):(
                                    <Link to={`${process.env.PUBLIC_URL}/login`} className="btn btn-primary">Book Now</Link>
                                )}
                            </div>
                            <div className="AboutSec col-md-12">
                                <div className="container-fluid">
                                    <h2>About</h2>
                                    <p>{Model.AboutExpert||'...'}</p>
                                    <br />
                                    <h2 className="mb-4">Qualifications</h2>
                                    <div className="row QualTable">
                                        <div className="col-md-3">
                                            <strong>Present University:</strong>
                                        </div>
                                        <div className="col-md-9">
                                            <p>{Model.universityName||'...'}</p>
                                        </div>
                                        <div className="col-md-3">
                                            <strong>High School Score:</strong>
                                        </div>
                                        <div className="col-md-9">
                                            <p>{Model.curriculumScore||'...'}</p>
                                        </div>
                                        <div className="col-md-3">
                                            <strong>University Accepted in:</strong>
                                        </div>
                                        <div className="col-md-9">
                                            <p>{Model.universityAccepted!=null?JSON.parse(Model.universityAccepted).map((item) =>
                                                <>{item.university}</>
                                            ):'...'}</p>
                                        </div>
                                        <div className="col-md-3">
                                            <strong>Other Qualifications:</strong>
                                        </div>
                                        <div className="col-md-9">
                                            <p>{Model.otherQualifications!=null?JSON.parse(Model.otherQualifications).map((item) =>
                                                <>{item.type}</>
                                            ):'...'}</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            {!notesStore.ExpertDetailModel.notesByExpert.loading&&notesStore.ExpertDetailModel.notesByExpert.data!=null?(
                                <div className="col-md-12 mt-5">
                                    <h2 className="text-center mb-5 mt-5">Notes by {Model.ExpertName}</h2>
                                    <div className="row">
                                        {notesStore.ExpertDetailModel.notesByExpert.data.map((item) => 
                                            <div className="col-md-3">
                                                <NoteBox data={item} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ):null}
                        </div>):null}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default ExpertDetail;