import React from 'react';

import './styles.scss';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import meetingStore from '../../../../stores/meetingStore';
import MenteereLoader from '../../../../components/MenteereLoader';
import noResultFound from '../../../../assets/images/noResultFound.svg';
import star from '../../../../assets/images/star.svg';
import moment from 'moment';
import Rating from '@material-ui/lab/Rating';
import { Modal } from 'react-bootstrap';
import notify from '../../../../components/notify';
import MenteeDashboardBody from '../../../../components/Containers/MenteeDashboardBody';

@observer class MenteeMyMeetings extends React.Component {

    componentWillMount() {
        meetingStore.getMyMeetings();
    }

    submitRating() {
        if (meetingStore.rateMeeting.inputs.area_1 == null) {
            notify('Please select rating for communication', 'danger');
            return false;
        }
        if (meetingStore.rateMeeting.inputs.area_2 == null) {
            notify('Please select rating for Teaching Approach', 'danger');
            return false;
        }
        if (meetingStore.rateMeeting.inputs.area_3 == null) {
            notify('Please select rating for Understanding', 'danger');
            return false;
        }
        if (meetingStore.rateMeeting.inputs.area_4 == null) {
            notify('Please select rating for Subject Knowledge', 'danger');
            return false;
        }
        if (meetingStore.rateMeeting.inputs.area_5 == null) {
            notify('Please select rating for Video Quality', 'danger');
            return false;
        }
        if (meetingStore.rateMeeting.inputs.review == '') {
            notify('Please add a review', 'danger');
            return false;
        }
        meetingStore.submitRating(() => {
            meetingStore.rateMeeting.popupVisible = false;
            notify('Review Submitted successfully', 'success');
        });
    }

    render() {
        let Model = meetingStore.myMeetingsModel;
        return (
            <MenteeDashboardBody>
                <div className="row MenteeMyMeetingsPage">
                    <div className="col-md-12">
                        <div className="pageTitle mb-4">
                            <h4>My Meetings</h4>
                        </div>
                    </div>
                    {Model.loading ? (
                        <div className="text-center mb-5 mt-5 col-md-12">
                            <MenteereLoader type="spinner" />
                        </div>
                    ) : null}
                    <div className="col-md-12">
                        {!Model.loading && Model.data != null ? (
                            <div className="row">
                                {Model.data.upcomingMeetings.length > 0 ? (<div className="col-md-12"><h4>Upcoming Meetings</h4></div>) : null}
                                {Model.data.upcomingMeetings.map((item) =>
                                    <div className="col-md-4">
                                        <div className="MeetingBox">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="UserImg">
                                                        <img src={item.UserImageURL} />
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <p className="MentorName">{item.UserName}</p>
                                                </div>
                                                <div className="col-md-6 midinfo">
                                                    <p>{moment(new Date(item.StartTime)).format('dddd - DD MMMM')}</p>
                                                </div>
                                                <div className="col-md-6 midinfo text-right">
                                                    <p>{moment(new Date(item.StartTime)).format('LT')} - {moment(new Date(item.EndTime)).format('LT')}</p>
                                                </div>

                                                {item.SlotStatus == 0 ? (
                                                    <div className="col-md-12 text-right mt-3">
                                                        <p>Waiting for Confirmation</p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {item.SlotStatus == 1 && item.isCompleted && item.Rating == null ? (
                                                            <div className="col-md-6" style={{ paddingRight: 0 }}>
                                                                <button className="rate-btn" onClick={() => {
                                                                    meetingStore.rateMeeting.selectedID = item.SlotID;
                                                                    meetingStore.rateMeeting.popupVisible = true;
                                                                }}><img src={star} /> Leave Rating</button>
                                                            </div>
                                                        ) : null}
                                                        <div className="col-md-6 text-right mt-3">
                                                            {item.SlotStatus == 1 && item.isCompleted ? (
                                                                <p className="joinCallBtn">Completed</p>
                                                            ) : null}
                                                            {item.SlotStatus == 1 && !item.isCompleted ? (
                                                                <Link className="joinCallBtn" to={`${process.env.PUBLIC_URL}/`}>Join Call</Link>
                                                            ) : null}
                                                            {item.SlotStatus == -1 ? (
                                                                <p style={{ color: 'red' }}>Rejected</p>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {Model.data.completedMeetings.length > 0 ? (<div className="col-md-12 mt-4 mb-4"><h4>Completed Meetings</h4></div>) : null}
                                {Model.data.completedMeetings.map((item) =>
                                    <div className="col-md-4">
                                        <div className="MeetingBox">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="UserImg">
                                                        <img src={item.UserImageURL} />
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <p className="MentorName">{item.UserName}</p>
                                                </div>
                                                <div className="col-md-6 midinfo">
                                                    <p>{moment(new Date(item.StartTime)).format('dddd - DD MMMM')}</p>
                                                </div>
                                                <div className="col-md-6 midinfo text-right">
                                                    <p>{moment(new Date(item.StartTime)).format('LT')} - {moment(new Date(item.EndTime)).format('LT')}</p>
                                                </div>

                                                {item.SlotStatus == 0 ? (
                                                    <div className="col-md-12 text-right mt-3">
                                                        <p>Waiting for Confirmation</p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {item.SlotStatus == 1 && item.isCompleted && item.Rating == null ? (
                                                            <div className="col-md-6" style={{ paddingRight: 0 }}>
                                                                <button className="rate-btn" onClick={() => {
                                                                    meetingStore.rateMeeting.selectedID = item.SlotID;
                                                                    meetingStore.rateMeeting.popupVisible = true;
                                                                }}><img src={star} /> Leave Rating</button>
                                                            </div>
                                                        ) : null}
                                                        <div className="col-md-6 text-right mt-3">
                                                            {item.SlotStatus == 1 && item.isCompleted ? (
                                                                <p className="joinCallBtn">Completed</p>
                                                            ) : null}
                                                            {item.SlotStatus == 1 && !item.isCompleted ? (
                                                                <Link className="joinCallBtn" to={`${process.env.PUBLIC_URL}/`}>Join Call</Link>
                                                            ) : null}
                                                            {item.SlotStatus == -1 ? (
                                                                <p style={{ color: 'red' }}>Rejected</p>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {Model.data.completedMeetings.length == 0 && Model.data.upcomingMeetings.length == 0 ? (
                                    <div className="noResultsFound col-md-12 text-center">
                                        <img src={noResultFound} />
                                        <h4>It's a bit empty here.... </h4>
                                        <p>Looks like you haven't booked any meeting yet</p>
                                        <Link to={`${process.env.PUBLIC_URL}/experts`} className="btn btn-primary">Explore Experts</Link>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>
                <Modal backdrop="static" show={meetingStore.rateMeeting.popupVisible} dialogClassName="RateMeetingsPopup">
                    <Modal.Body>
                        <div class="row">
                            <div class="col-md-10 mb-4">
                                <h4>Rate your experience</h4>
                            </div>
                            <div class="col-md-2 PopCloseIcon" onClick={() => { meetingStore.rateMeeting.popupVisible = false }}>
                                <span className="icon icon-close"></span>
                            </div>
                            <div class="col-md-12 mb-4">
                                <p className="midline">Rate the Menteer based on these skills</p>
                            </div>
                            <div class="col-md-12 RatingBoxes">
                                <div className="RateItm">
                                    <label>Teaching Approach</label>
                                    <Rating
                                        name="rate-item-1"
                                        value={meetingStore.rateMeeting.inputs.area_1}
                                        onChange={(event, newValue) => {
                                            meetingStore.rateMeeting.inputs.area_1 = newValue;
                                        }}
                                    />
                                </div>
                                <div className="RateItm">
                                    <label>Subject Knowledge</label>
                                    <Rating
                                        name="rate-item-2"
                                        value={meetingStore.rateMeeting.inputs.area_2}
                                        onChange={(event, newValue) => {
                                            meetingStore.rateMeeting.inputs.area_2 = newValue;
                                        }}
                                    />
                                </div>
                                <div className="RateItm">
                                    <label>Communication</label>
                                    <Rating
                                        name="rate-item-3"
                                        value={meetingStore.rateMeeting.inputs.area_3}
                                        onChange={(event, newValue) => {
                                            meetingStore.rateMeeting.inputs.area_3 = newValue;
                                        }}
                                    />
                                </div>
                                <div className="RateItm">
                                    <label>Friendliness</label>
                                    <Rating
                                        name="rate-item-4"
                                        value={meetingStore.rateMeeting.inputs.area_4}
                                        onChange={(event, newValue) => {
                                            meetingStore.rateMeeting.inputs.area_4 = newValue;
                                        }}
                                    />
                                </div>
                                <div className="RateItm">
                                    <label>Approachability</label>
                                    <Rating
                                        name="rate-item-5"
                                        value={meetingStore.rateMeeting.inputs.area_5}
                                        onChange={(event, newValue) => {
                                            meetingStore.rateMeeting.inputs.area_5 = newValue;
                                        }}
                                    />
                                </div>
                            </div>
                            <div class="col-md-12 mt-4">
                                <p className="midline">Add a review</p>
                                <textarea className="form-control" placeholder="Add a review to describe your experience"
                                    onChange={(e) => { meetingStore.rateMeeting.inputs.review = e.target.value }}
                                >{meetingStore.rateMeeting.inputs.review}</textarea>
                            </div>
                            <div class="col-md-12 mt-5 mb-2">
                                <button type="button" class="btn btn-primary btn-bit-wide" onClick={() => {
                                    this.submitRating();
                                }} disabled={meetingStore.rateMeeting.loading}>Submit</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </MenteeDashboardBody>
        );
    }
}
export default MenteeMyMeetings;

