import React from 'react';
import DashboardBody from '../../../components/Containers/DashboardBody/DashboardBody';
import uploadyourpicture from '../../../assets/images/uploadyourpicture.svg';
import './style.scss';
import { Link } from 'react-router-dom';
import verificationStore from '../../../stores/verificationStore';
import {observer} from 'mobx-react';
import BoundInput from '../../../components/Form/BoundInput';
import errorMessages from '../../../shared/errorMessages';
import notify from '../../../components/notify';

@observer class VerifyProfile3 extends React.Component {

    componentDidMount() {
        verificationStore.getPrefilledInfo();   
    }

    submit() {
        const prefilledInfo = verificationStore.verifyStep1Model.infoData.prefilledInfo;
        if(prefilledInfo.upi_id == '' || prefilledInfo.upi_id == null) {
            notify(errorMessages.UpiEmpty, 'danger');
            return false;
        }
        verificationStore.update(true, ()=> {
            this.props.history.push(`${process.env.PUBLIC_URL}/verify-profile-thank-you`);
        });
    }

    render() {
        return (
            <DashboardBody>
                <div className="VerifyProfilePage3">
                    <div className="col-md-12 text-center mt-4">
                        <h4>Bank and Payment details</h4>
                        <div className="verifyProgress">
                            <div className="sec">
                                <div className="dot"></div>
                                <p>Personal Details</p>
                            </div>
                            <div className="sec">
                                <div className="dot"></div>
                                <p>Academic Details</p>
                            </div>
                            <div className="sec active">
                                <div className="dot"></div>
                                <p>Bank Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="pageTitle">
                        <h4>Bank Details</h4>
                    </div>
                    {!verificationStore.verifyStep1Model.infoLoading && verificationStore.verifyStep1Model.infoData !== null?(
                    <div className="row">
                        <div className="col-md-4">
                            <label>UPI ID <span className="LabelRequired">*</span></label>
                            <BoundInput
                                placeholder="UPI ID"
                                model={verificationStore.verifyStep1Model.infoData.prefilledInfo}
                                property="upi_id"
                            />
                        </div>
                       
                        <div className="col-md-12 mt-3 mb-5 op-5">
                            <small>Clear frontal face photos help to personalize communication and show that you are reliable, authentic and committed</small>
                        </div>

                        <div className="col-md-6">
                            <Link className="btn btn-primary btn-bit-wide" to={`${process.env.PUBLIC_URL}/verify-profile-2`}>Prev</Link>
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-primary float-right btn-bit-wide" onClick={()=> { this.submit(); }} disabled={verificationStore.verifyStep1Model.submitLoading}>Submit for Review</button>
                        </div>
                    </div>
                ):('loading')}
                </div>
            </DashboardBody>
        );
    }
}

export default VerifyProfile3;