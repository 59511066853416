import React from 'react';
import MenteeDashboardBody from '../../../../components/Containers/MenteeDashboardBody';

import './styles.scss';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import dashboardStore from '../../../../stores/dashboardStore';
import subscriptionStore from '../../../../stores/subscriptionStore';
import MenteereLoader from '../../../../components/MenteereLoader';
import noResultFound from '../../../../assets/images/noResultFound.svg';
import ChooseYourPlan from '../../../../components/Home/ChooseYourPlan/ChooseYourPlan'


@observer class MenteeMySubscription extends React.Component {

    componentWillMount() {
        subscriptionStore.getMySubscriptions();
    }

    render() {
        let data = subscriptionStore.MySubscriptions.data;
        return (
            <MenteeDashboardBody>
                <div className="row MySUbscriptionsPage">
                    <div className="col-md-12">
                        <div className="pageTitle mb-4">
                            <h4>My Subscription</h4>
                        </div>
                    </div>
                    {subscriptionStore.MySubscriptions.loading ? (
                        <div className="text-center mb-5 mt-5">
                            <MenteereLoader type="spinner" />
                        </div>
                    ) : null}

                    <div className="col-md-12">
                        {!subscriptionStore.MySubscriptions.loading && subscriptionStore.MySubscriptions.data != null ? (
                            <div className="row">
                                {subscriptionStore.MySubscriptions.data.map((item) =>
                                    <div className="col-md-12 SubItem">
                                        <div className="row">
                                            <div className="col-md-9 upperInfo">
                                                <h2>${item.total}/ Month</h2>
                                                <p><b>{item.planDesc}</b></p>
                                            </div>
                                            <div className="col-md-3">
                                                <div class="statusSign">
                                                    Active <div className="dot"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 SubItems">
                                                <div className="subitm">
                                                    <strong>Subscription ID</strong>
                                                    <p>#{item.id}</p>
                                                </div>
                                                <div className="subitm">
                                                    <strong>Price</strong>
                                                    <p>${item.total}</p>
                                                </div>
                                                <div className="subitm">
                                                    <strong>Start Date</strong>
                                                    <p>{new Date(item.valid_from).toLocaleDateString("en-US")}</p>
                                                </div>
                                                <div className="subitm">
                                                    <strong>Renewal on</strong>
                                                    <p>{new Date(item.valid_upto).toLocaleDateString("en-US")}</p>
                                                </div>
                                                <div className="subitm">
                                                    <strong>Access</strong>
                                                    <p>{item.planDesc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {subscriptionStore.MySubscriptions.data.length == 0 ? (
                                    <div className="noResultsFound col-md-12 text-center">
                                        {/* <img src={noResultFound} /> */}

                                        <h4>You don't have any subscription right Now.... </h4>
                                        <ChooseYourPlan />
                                        {/* <p>Explore notes from the student of top universities</p>
                                        <Link to={`${process.env.PUBLIC_URL}/notes`} className="btn btn-primary">Explore Notes</Link> */}
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>
            </MenteeDashboardBody>
        );
    }
}

export default MenteeMySubscription;

