import React from 'react';
import { observer } from 'mobx-react';
import endcall from '../../../assets/images/endcall.svg';
import './styles.scss';
import adapter from 'webrtc-adapter';
//const QBVideoConferencingClient = require('quickblox/quickblox-multiparty-video-conferencing-client-0.8.6.min.js');


@observer class VideoConferencing extends React.Component {

   /*  componentDidMount() {
        const config = {
            server: "wss://janus.quickblox.com:8989",
            //debug: true, // optional
            //iceServers: [], // optional
        };
        //const QB = new QuickBlox();

        const client = new QBVideoConferencingClient(config);
        client
            .createSession()
            .then(() => {
                // session created
                const isRemote = false;
                const userId = null;
                client
                    .attachVideoConferencingPlugin(isRemote, userId)
                    .then((plugin) => {
                        const eventHandler = console.log; // use your own event handler(s)
                        Object.keys(plugin.events).forEach((key) =>
                            plugin.on(plugin.events[key], eventHandler)
                        );


                        const joinParams = {
                            roomId: "room_12311",
                            userId: parseInt(this.props.match.params.id),
                            // display: "Tarun Narula",
                            // onlyAudio: false,
                            // role: "publisher",
                            // video: "fhdres",
                        };

                        client
                            .join(joinParams)
                            .then(() => {
                                // joined successfully

                                client
                                    .listOnlineParticipants('room_123')
                                    .then((participants) => {
                                        // handle as necessary
                                        console.log('Participants List');
                                        console.log(participants);
                                    })
                                    .catch((error) => {
                                        // handle error
                                    });

                                client.on(client.events.PARTICIPANT_JOINED, (userId, userDisplayName) => {
                                    //alert('Participant Joined ' + userId + ' -- '+userDisplayName);
                                });

                                client.on(client.events.PARTICIPANT_LEFT, (userId, userDisplayName) => {
                                    //alert('Participant Left ' + userId + ' -- '+userDisplayName);
                                });

                                client.on(client.events.LOCAL_STREAM, (stream) => {
                                    const localVideo = document.querySelector("video#curUserVidElem");
                                    QBVideoConferencingClient.attachMediaStream(localVideo, stream);
                                });

                                client.on(client.events.REMOTE_STREAM, (stream, userId) => {
                                    const remoteVideo = document.querySelector("video#frontVideoElem");
                                    QBVideoConferencingClient.attachMediaStream(remoteVideo, stream);
                                });

                                client.on(client.events.SESSION_DESTROYED, () => {
                                    //alert('Session Destroyed');
                                });

                                client.on(client.events.ERROR, (error) => {
                                    // handle error
                                    console.log('error');
                                    console.log(error);
                                    console.log('error');
                                });
                            })
                            .catch((error) => {
                                // handle error
                            });

                    })
                    .catch((error) => {
                        // some error occurred
                    });
            })
            .catch((error) => {
                // some error occurred
            });
    } */

    consentDialog(on) {
        console.log('consentDialog event start');
        console.log(on);
        console.log('consentDialog event end');
    }

    mediaState(media, receiving) {
        console.log('mediaState event start');
        console.log(media);
        console.log(receiving);
        console.log('mediaState event end');
    }

    webrtcState(on, reason) {
        console.log('webrtcState event start');
        console.log(on);
        console.log(reason);
        console.log('webrtcState event end');
    }

    slowLink(uplink, lost) {
        console.log('slowLink event start');
        console.log(uplink);
        console.log(lost);
        console.log('slowLink event end');
    }

    iceState(state) {
        console.log('iceState event start');
        console.log(state);
        console.log('iceState event end');
    }

    detached() {
        console.log('detached event start');
        console.log('detached event end');
    }

    cleanup() {
        console.log('cleanup event start');
        console.log('cleanup event end');
    }


    render() {
        return (
            <div className="VideoConferencingPage">
                <div className="UpperSection">
                    <video id="frontVideoElem"></video>
                    <video id="curUserVidElem"></video>
                </div>
                <div className="ActionBtns">
                    <div className="StatusText">
                        <p>Status</p>
                    </div>
                    <div className="centerSec">
                        <button className={`MuteCallBtn`}>
                            <span className="icon icon-microphone"></span>
                        </button>
                        <button className="EndCallBtn">
                            <img src={endcall} />
                        </button>
                        <button className={`ToggleScreenCallBtn`}>
                            <span className="icon icon-camrecorder"></span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoConferencing;