import React, { Component } from 'react';
import './style.scss';
import LeftQuote from '../../../assets/images/LeftQuote.svg'
import hope from '../../../assets/images/hope.svg'

function AboutMetaInfo() {

    return(
        <div className="AboutMetaInfo col-md-12">
            <div className="row">
                <div className="container">                
                    <div className="row AboutHedding">
                        <div className="col-md-12 text-center">
                            <h4 className="SecTitle mt-5">About Menteere</h4>
                            <p className="AboutText"> Menteere is a pioneering ed-tech platform that, by harnessing the power of peer mentorship - 
                            facilitates to bridge the resource gap that subsists between students and mentors within the education industry. 
                            We endeavour to provide high-school students with affordable and transparent access to Menteers (peer mentors) 
                            from top-tier universities, who have academically excelled in their high-school curriculums and can share their 
                            high-quality resources with school students in need, worldwide. </p>                            
                        </div>
                    </div>                      
                </div>            
            </div>                    
            <div className="row BgSet"   style={{backgroundImage:`url(${hope})`,backgroundSize:'cover'}}>
                <div className="container">
                        <div className="row">                            
                        <div className="col-md-12 text-center">
                            <div className="AboutBottom ">
                                <div className="Quote" style={{backgroundImage:`url(${LeftQuote})`,backgroundSize:'cover' }} ></div>
                                <p> With Menteeré, we hope to build a community of empowered students who have access to quality resources and peer mentors.</p>
                                <h4>A service for students, by students.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>       
    );    
}

export default AboutMetaInfo;