import React, { Component } from 'react';
import {observer} from 'mobx-react';
import OwlCarousel from 'react-owl-carousel2';
import rootStore from '../../../stores/rootStore';
import {Link} from 'react-router-dom';
import './styles.scss';

@observer class ExploreUniversity extends React.Component {

    componentDidMount() {
        //if(rootStore.universities.data != null) {
            rootStore.getUniversities();
        //}
    }

    render() {
        const options = {
            items: 6,
            nav: true,
            navText:["<div class='nav-btn prev-slide'><span class='icon icon-arrow-left-circle'></span></div>","<div class='nav-btn next-slide'><span class='icon icon-arrow-right-circle'></span></div>"],
            rewind: false,
            autoplay: true,
            slideBy: 1,
            //loop: true,
            dots: false,
            dotsEach: false,
            dotData: false,
            margin: 50,
            responsive: {
                0: {
                    items: 1,
                },                
                767: {
                    items: 1,
                },
                768: {
                    items: 2,
                },
                1024: {
                    items: 3,
                },  
                1366: {
                    items:5,
                },              
            },    
        };
        return (
            <div className="rowes ExploreUniversitySec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center secndhead">
                            <h1 className="SecTitle">Explore University</h1>
                            <span>Our Menteer comes from the Best University all over the Globe</span>
                        </div>
                        <div className="col-md-12 text-center SliderSc ArrowSet">
                            {rootStore.universities.loading?'Loading':null}
                            {!rootStore.universities.loading && rootStore.universities.data!=null?(
                                <OwlCarousel ref="owl" options={options}>
                                    {rootStore.universities.data.map((item)=>
                                        <Link to={`${process.env.PUBLIC_URL}/experts?university_id=${item.id}`}><img src={item.universityImageURL} alt={item.title} className="img-fluid" /></Link>
                                    )}
                                </OwlCarousel>
                            ):null}
                            <a href="#" className="btn btn-primary BtnSets">View More</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExploreUniversity;