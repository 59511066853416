import React, { Component } from 'react';
import './style.scss';
import LeftQuote from '../../../assets/images/LeftQuote.svg'
import OBJECTS from '../../../assets/images/OBJECTS.svg'
import buttons from '../../../assets/images/buttons.svg'

function WhatSets() {
    return(
        <div className="row WhatSet col-md-12">
            <div className="container">                
                <div className="row textSet">
                    <div className="col-md-12 text-center">
                        <h4 className="SecTitle">What sets us apart</h4>
                    </div>
                </div> 
                <div className="row img-botm">
                    <div className="col-md-12">  
                        <img src={buttons} className="Max-Width "/>
                    </div>                        
                </div>
            </div>
        </div>
    );    
}

export default WhatSets;