import React from 'react';
import { observer } from 'mobx-react';
import './styles.scss';
import notibell from '../../assets/images/notibell.svg';
import greycross from '../../assets/images/greycross.svg';
import rootStore from '../../stores/rootStore';
import { Link } from 'react-router-dom';
import arrowDown from '../../assets/images/arrowDown.svg';

@observer class NotificationBell extends React.Component {

    componentDidMount() {
        rootStore.getMyNotifications();
    };

    checkNotification(id) {
        rootStore.updateMyNotifications(id, () => {
            rootStore.getMyNotifications();
        });

    };


    render() {

        return (
            <div className="NotificationBellContainer">
                <button className="NotificationBell" onClick={() => {
                    rootStore.notifications.popVisible = !rootStore.notifications.popVisible
                }}>
                    <img src={notibell} className="BellImg" />
                    <img src={arrowDown} className="bellIco" />
                </button>
                {rootStore.notifications.popVisible ? (
                    <div className="NotificationListPop">
                        <div className="NotificationListPopHeader">
                            <h5>Notifications</h5>
                            <img src={greycross} onClick={() => {
                                rootStore.notifications.popVisible = !rootStore.notifications.popVisible
                            }} />
                        </div>
                        <div className="NotificationItems">
                            {rootStore.notifications.loading ? (<p>Loading</p>) : (
                                <>
                                    {rootStore.notifications.data != null ? rootStore.notifications.data.map((item) =>
                                        <>
                                            {item.isRead == 1 ?
                                                <Link className="NotificationItem" to={`${process.env.PUBLIC_URL}/`} onClick={() => { this.checkNotification(item.id) }}>
                                                    <div>
                                                        <div className="upSide">
                                                            <p>{item.title}</p>
                                                        </div>
                                                        <a className="btmSide" >
                                                            <p>{item.description}</p>
                                                        </a>

                                                    </div>
                                                </Link>
                                                :
                                                <Link className="NotificationItemIsRead" to={`${process.env.PUBLIC_URL}/`} onClick={() => { this.checkNotification(item.id) }}>
                                                    <div>
                                                        <div className="upSide">
                                                            <p>{item.title}</p>
                                                        </div>
                                                        <a className="btmSide" >
                                                            <p>{item.description}</p>
                                                        </a>

                                                    </div>
                                                </Link>
                                            }
                                        </>
                                    ) : null}
                                    {rootStore.notifications.data.length == 0 || rootStore.notifications.data == null ? (
                                        <p className="noResult">No Notifications</p>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default NotificationBell;