import React from 'react';
// import './styles.scss';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import subscriptionStore from '../../../../stores/subscriptionStore';
import MySubscriptionBG from '../../../../assets/images/MySubscriptionBG.svg';
import noResultFound from '../../../../assets/images/noResultFound.svg';
import MenteereLoader from '../../../../components/MenteereLoader';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import earningStore from '../../../../stores/menteer/earningStore';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

@observer class MenteereMyEarnings extends React.Component {

    componentWillMount() {
        // earningStore.getMyEarnings();
        earningStore.getMyTotalEarnings();
    }

    render() {
        // let data = subscriptionStore.MySubscriptions.data;
        return (
            <DashboardBody>
                <Tabs>
                    <TabList>
                        <Tab>Notes Earning</Tab>
                        <Tab>Mentorship Earning</Tab>
                        <Tab>Total Earning</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="row MyEarningsPage">
                            <div className="col-md-12">
                                <div className="pageTitle mb-4">
                                    <h4>My Earnings</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <label>Date From</label>
                                        <br />
                                        <DatePicker selected={earningStore.MyEarningsModel.inputs.from} onChange={date => { earningStore.MyEarningsModel.inputs.from = date }} className="form-control" />
                                    </div>
                                    <div className="col-md-2">
                                        <label>Date Upto</label>
                                        <br />
                                        <DatePicker selected={earningStore.MyEarningsModel.inputs.upto} onChange={date => { earningStore.MyEarningsModel.inputs.upto = date }} className="form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label>&nbsp;</label>
                                        <br />
                                        <button type="button" className="btn btn-info" onClick={() => { earningStore.getMyEarnings() }}>Get Results</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-5">
                                        {earningStore.MyEarningsModel.infoLoading ? (
                                            <MenteereLoader type="spinner" />
                                        ) : null}
                                        {earningStore.MyEarningsModel.data != null ? (
                                            <div className="row">

                                                <div className="col-md-3">
                                                    <div className="MetaBox">
                                                        <p>Per Click Profit</p>
                                                        <strong>{earningStore.MyEarningsModel.data.perClickProfit}</strong>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-4">
                                                    <table class="table table-responsive-sm table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Note ID</th>
                                                                <th>Note Title</th>
                                                                <th>Total Clicks</th>
                                                                <th>Total Earnings</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {earningStore.MyEarningsModel.data.MentorRows.map((item) => {
                                                                return <tr>
                                                                    <td>{item.NoteId}</td>
                                                                    <td>{item.NoteTitle}</td>
                                                                    <td>{item.ClickCount}</td>
                                                                    <td>{item.TotalProfit}</td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="row MyEarningsPage">
                            <div className="col-md-12">
                                <div className="pageTitle mb-4">
                                    <h4>My Mentorship Earnings</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <label>Date From</label>
                                        <br />
                                        <DatePicker selected={earningStore.MyMentorshipEarningsModel.inputs.from} onChange={date => { earningStore.MyMentorshipEarningsModel.inputs.from = date }} className="form-control" />
                                    </div>
                                    <div className="col-md-2">
                                        <label>Date Upto</label>
                                        <br />
                                        <DatePicker selected={earningStore.MyMentorshipEarningsModel.inputs.upto} onChange={date => { earningStore.MyMentorshipEarningsModel.inputs.upto = date }} className="form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label>&nbsp;</label>
                                        <br />
                                        <button type="button" className="btn btn-info" onClick={() => { earningStore.getMyMentorshipEarnings() }}>Get Results</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-5">
                                        {earningStore.MyMentorshipEarningsModel.infoLoading ? (
                                            <MenteereLoader type="spinner" />
                                        ) : null}
                                        {earningStore.MyMentorshipEarningsModel.data != null ? (
                                            <div className="row">

                                                <div className="col-md-12 mt-4">
                                                    <table class="table table-responsive-sm table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Mentee ID</th>
                                                                <th>Mentee Name</th>
                                                                <th>MentorShip Hours</th>
                                                                <th>Slot Date</th>
                                                                <th>Slot Start At</th>
                                                                <th>Slot End At</th>
                                                                <th>Total Amount</th>
                                                                <th>Percent to Cut</th>
                                                                <th>Total Earnings</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {earningStore.MyMentorshipEarningsModel.data.total.map((item) => {
                                                                return <tr>
                                                                    <td>{item.menteeId}</td>
                                                                    <td>{item.takenBy}</td>
                                                                    <td>{item.totalHrsGiven}</td>
                                                                    <td>{item.slotDate}</td>
                                                                    <td>{item.slotStartTime}</td>
                                                                    <td>{item.slotEndTime}</td>
                                                                    <td>{item.totalPrice}</td>
                                                                    <td>{item.percentToCut}</td>
                                                                    <td>{item.finalAmount}</td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="row MyEarningsPage">
                            <div className="col-md-12">
                                <div className="pageTitle mb-4">
                                    <h4>Total Earnings</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <label>Date From</label>
                                        <br />
                                        <DatePicker selected={earningStore.MyMentorshipEarningsModel.inputs.from} onChange={date => { earningStore.MyMentorshipEarningsModel.inputs.from = date }} className="form-control" />
                                    </div>
                                    <div className="col-md-2">
                                        <label>Date Upto</label>
                                        <br />
                                        <DatePicker selected={earningStore.MyMentorshipEarningsModel.inputs.upto} onChange={date => { earningStore.MyMentorshipEarningsModel.inputs.upto = date }} className="form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label>&nbsp;</label>
                                        <br />
                                        <button type="button" className="btn btn-info" onClick={() => { earningStore.getMyMentorshipEarnings() }}>Get Results</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-5">
                                        {earningStore.MyMentorshipEarningsModel.infoLoading ? (
                                            <MenteereLoader type="spinner" />
                                        ) : null}
                                        {earningStore.MyMentorshipEarningsModel.data != null ? (
                                            <div className="row">

                                                <div className="col-md-12 mt-4">
                                                    <table class="table table-responsive-sm table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Mentee ID</th>
                                                                <th>Mentee Name</th>
                                                                <th>MentorShip Hours</th>
                                                                <th>Slot Date</th>
                                                                <th>Slot Start At</th>
                                                                <th>Slot End At</th>
                                                                <th>Total Amount</th>
                                                                <th>Percent to Cut</th>
                                                                <th>Total Earnings</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {earningStore.MyMentorshipEarningsModel.data.total.map((item) => {
                                                                return <tr>
                                                                    <td>{item.menteeId}</td>
                                                                    <td>{item.takenBy}</td>
                                                                    <td>{item.totalHrsGiven}</td>
                                                                    <td>{item.slotDate}</td>
                                                                    <td>{item.slotStartTime}</td>
                                                                    <td>{item.slotEndTime}</td>
                                                                    <td>{item.totalPrice}</td>
                                                                    <td>{item.percentToCut}</td>
                                                                    <td>{item.finalAmount}</td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </TabPanel>

                </Tabs>
            </DashboardBody>
        );
    }
}

export default MenteereMyEarnings;

