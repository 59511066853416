import { makeAutoObservable } from 'mobx';
import Service from '../../shared/service/service'

class Store {

    constructor() {
        makeAutoObservable(this);
    }

    MyEarningsModel = {
        infoLoading: true,
        data: null,
        inputs: {
            from: new Date(),
            upto: new Date()
        }
    }

    MyMentorshipEarningsModel = {
        infoLoading: true,
        data: null,
        inputs: {
            from: new Date(),
            upto: new Date()
        }
    }

    MyTotalEarningsModel = {
        infoLoading: true,
        data: null,
        listData: null,
        showBreakupPop: false,
        breakUpData: null,
        breakUpDataLoading: false,
        selectedFilter: 1,
        inputs: {
            from: new Date(),
            upto: new Date()
        }
    }

    getMyEarnings() {
        this.MyEarningsModel.infoLoading = true;
        Service.post(
            'getMyEarnings',
            this.MyEarningsModel.inputs,
            (status, response) => {
                this.MyEarningsModel.infoLoading = false;
                this.MyEarningsModel.data = response.data;
            },
        ).catch(error => {
            this.MyEarningsModel.infoLoading = false;
            Service.handleError(error);
        });
    }


    getMyMentorshipEarnings() {
        this.MyMentorshipEarningsModel.infoLoading = true;
        Service.post(
            'getMyMentorshipEarnings',
            this.MyMentorshipEarningsModel.inputs,
            (status, response) => {
                this.MyMentorshipEarningsModel.infoLoading = false;
                this.MyMentorshipEarningsModel.data = response.data;
            },
        ).catch(error => {
            this.MyMentorshipEarningsModel.infoLoading = false;
            Service.handleError(error);
        });
    }

    getMyTotalEarnings() {
        this.MyTotalEarningsModel.infoLoading = true;
        Service.post(
            'getMentorshipStats',
            this.MyTotalEarningsModel.inputs,
            (status, response) => {
                this.MyTotalEarningsModel.infoLoading = false;
                this.MyTotalEarningsModel.listData = response.data;
                this.MyTotalEarningsModel.data = response.data;
            },
        ).catch(error => {
            this.MyTotalEarningsModel.infoLoading = false;
            Service.handleError(error);
        });
    }


    getMyNewTotalEarnings() {
        this.MyTotalEarningsModel.infoLoading = true;
        Service.post(
            'getMentorshipStats',
            this.MyTotalEarningsModel.inputs,
            (status, response) => {
                this.MyTotalEarningsModel.infoLoading = false;
                this.MyTotalEarningsModel.listData = response.data;
                this.MyTotalEarningsModel.data = response.data;
            },
        ).catch(error => {
            this.MyTotalEarningsModel.infoLoading = false;
            Service.handleError(error);
        });
    }

    getEarningBreakup(startDate, endDate) {
        this.MyTotalEarningsModel.breakUpDataLoading = true;
        Service.post(
            'getEarningBreakup',
            { startDate, endDate },
            (status, response) => {
                this.MyTotalEarningsModel.breakUpDataLoading = false;
                this.MyTotalEarningsModel.breakUpData = response.data;
            },
        ).catch(error => {
            this.MyTotalEarningsModel.breakUpDataLoading = false;
            Service.handleError(error);
        });
    }
}

const earningStore = new Store();
export default earningStore;