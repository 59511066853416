import React, { Component } from 'react';
import rootStore from '../stores/rootStore';

class notFound extends React.Component {

    componentDidMount() {
        if(!rootStore.isLoggedIn())
        {
            window.location.href=`${process.env.PUBLIC_URL}/`;
        }
    }

    render() {
        return (
            <div className="c-body">
                <div className="c-subheader justify-content-between px-3">
                    <ol className="breadcrumb border-0 m-0 px-0 px-md-3">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item active">Users</li>
                    </ol>
                </div>
                <main className="c-main">
                    <div className="container-fluid">
                        <div className="fade-in">
                            <h1>404 Not Found</h1> 
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default notFound;