import React, { Component } from 'react';
import {observer} from 'mobx-react';
import rootStore from '../../../stores/rootStore'
import ScriptTag from 'react-script-tag';
import { Link } from 'react-router-dom';
import logoWhite from '../../../assets/images/logoWhite.png';
import dashBg from '../../../assets/images/dashBg.png';
import arrowDown from '../../../assets/images/arrowDown.svg';
import Snackbar from '@material-ui/core/Snackbar';

import notibell from '../../../assets/images/notibell.svg';
import VideoCallOperations from '../../VideoCallOperations';
import navdashboard from '../../../assets/images/navdashboard.svg';

import './style.scss';

import {Animated} from "react-animated-css";
import NotificationBell from '../../NotificationBell';
const $ = require('jquery');

@observer class MenteeDashboardBody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggleOn: true,
            windowWidth: window.innerWidth
        };
        this.handleClick = this.handleClick.bind(this);
        
      }

    componentDidMount() {
        $('a[href="'+window.location.pathname+'"]').addClass('active');
       
        if( this.state.windowWidth < 500){
            this.setState({isToggleOn:false});
        }
    }
    handleClick() {
        this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));
    }  
   
    render() {
        const pro_pic_url = localStorage.getItem('pro_pic_url');
        return (
            <div className="Dashbody">
                <VideoCallOperations />
                <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
                    {this.state.isToggleOn ? 
                        <div className="LeftPanel" style={{ backgroundImage: `url(${dashBg})` }}>
                            <div className="LeftHeader">
                                <img src={logoWhite} className="Logo" />
                            </div>
                            <div className="RightUserPanel UserNone">
                                    <nav id="navbarss" className="navbar12">
                                        <ul>
                                            <li  className="ball">
                                                <NotificationBell /> 
                                            </li>
                                            <li className="userInfo dropdown">
                                                <div className="userImg">
                                                    {pro_pic_url == 'null'?(
                                                        <div className="nameAlpha">{localStorage.getItem('name').charAt(0)}</div>
                                                    ):(
                                                        <img src={pro_pic_url} />
                                                    )}
                                                </div>
                                                <div className="infoSec">
                                                    <h5>{localStorage.getItem('name')} <img src={arrowDown} /></h5>
                                                    <span>{localStorage.getItem('is_mentor') == 'true'?'Menteer':'Mentee'}</span>
                                                    <Link to={`${process.env.PUBLIC_URL}/MenteeDashboard`}>Switch to Mentee View</Link>
                                                </div>
                                                <ul>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/dashboard`}>Dashboard</Link></li>
                                                    <li><a href="#about" className="logoutBtn" onClick={()=> {rootStore.signOut();}} disabled={rootStore.logout.loading}>Logout</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            <ul className="NavMenu">
                            <li className="NavNavis">
                                    <Link class="" to={`${process.env.PUBLIC_URL}/`}><img src={navdashboard}/>Home</Link>
                                    <Link to={`${process.env.PUBLIC_URL}/aboutus`}><img src={navdashboard}/>About</Link>
                                    <Link to={`${process.env.PUBLIC_URL}/experts`}><img src={navdashboard}/>Experts</Link>
                                    <Link to={`${process.env.PUBLIC_URL}/notes`}><img src={navdashboard}/>Notes</Link>
                                    <Link to={`${process.env.PUBLIC_URL}/contactus`}><img src={navdashboard}/>Contact</Link>                                
                                </li>
                                {rootStore.MenteeSidebar.items.map((item, index)=>
                                    <li>
                                        <Link  onClick={()=> {
                                            for(var j in rootStore.MenteereSidebar.items) {
                                                rootStore.MenteereSidebar.items[j].dropdownActive = false;
                                            }
                                            rootStore.MenteereSidebar.items[index].dropdownActive = !rootStore.MenteereSidebar.items[index].dropdownActive;
                                        }} to={item.link} className={`${item.hasDropdown?"hasDropdown":""}`}><img src={item.image} />{item.title} {item.hasDropdown?(<span className="icon icon-arrow-down float-right"></span>):null}</Link>
                                        {item.hasDropdown?(
                                            <ul className={`subMenu ${rootStore.MenteereSidebar.items[index].dropdownActive?"active":""}`}>
                                            {item.dropdownItems.map((initem)=>
                                                <li><Link to={initem.link}>{initem.title}</Link></li>
                                            )}
                                            </ul>
                                        ):null}
                                    </li>
                                )} 
                                <li className="NavNavis">
                                    <Link class="" to={`${process.env.PUBLIC_URL}/`}><img src={navdashboard}/>Log Out</Link>                                
                                </li>
                            </ul>
                        </div>
                    : null}
                <div className={`RightArea ${this.state.isToggleOn==true ? "" : "active-right"}`}>
                    <div className="RightHeader">
                        <div className="Leftlogo">
                            <img src={logoWhite} className="LogoHead"/>
                        </div>
                        <button onClick={this.handleClick} className="BaarNav">
                            <i class="fa fa-bars" aria-hidden="true"></i>
                        </button>
                        <div className="UpperMenu">
                            <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                            <Link to={`${process.env.PUBLIC_URL}/aboutus`}>About</Link>
                            <Link to={`${process.env.PUBLIC_URL}/experts`}>Experts</Link>
                            <Link to={`${process.env.PUBLIC_URL}/notes`}>Notes</Link>
                            <Link to={`${process.env.PUBLIC_URL}/contactus`}>Contact</Link>
                        </div>
                        <div className="RightUserPanel">
                            <nav id="navbar" className="navbar">
                                <ul>
                                    <li>
                                        <NotificationBell /> 
                                    </li>
                                    <li className="userInfo dropdown">
                                        <div className="userImg">
                                            {pro_pic_url == 'null'?(
                                                <div className="nameAlpha">{localStorage.getItem('name').charAt(0)}</div>
                                            ):(
                                                <img src={pro_pic_url} />
                                            )}
                                        </div>
                                        <div className="infoSec">
                                            <h5>{localStorage.getItem('name')} <img src={arrowDown} /></h5>
                                            <span>{localStorage.getItem('is_mentor') == 'true'?'Menteer':'Mentee'}</span>
                                            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>Switch to Menteer View</Link>
                                        </div>
                                        <ul>
                                            <li><Link to={`${process.env.PUBLIC_URL}/MenteeDashboard`}>Dashboard</Link></li>
                                            <li><a href="#about" className="logoutBtn" onClick={()=> {rootStore.signOut();}} disabled={rootStore.logout.loading}>Logout</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <Animated className="RightBody" animationIn="fadeIn" isVisible={true}>{this.props.children}</Animated>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: rootStore.toast.verpos, horizontal: rootStore.toast.horpos }}
                    open={rootStore.toast.visible}
                    onClose={()=> { rootStore.toast.visible = false }}
                    autoHideDuration={3000}
                    message={rootStore.toast.text}
                    key={'vertical + horizontal'}
                />
            </div>
        );
    }
}
export default MenteeDashboardBody;