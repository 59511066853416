import { makeAutoObservable } from 'mobx';
import Service from '../shared/service/service';
import videoCallStore from './videoCallStore';

class Store {

    constructor() {
        makeAutoObservable(this);
    }

    loginMod={
        loading: false,
        input:{
            email: '',
            password: ''
        }
    };

    registerMod={
        loading: false,
        input:{
            name: '',
            email: '',
            password: '',
            is_mentor: false
        },
        tncAccepted: true
    };

    forgotPassMod={
        loading: false,
        showOTPInput: false,
        otpVerified: false,
        input:{
            email: '',
            otp: '',
            password: '',
            confirmPassword: '',
        },
        initialInput:{
            email: '',
            otp: '',
            password: '',
            confirmPassword: '',
        }
    };

    getToken() {
        return localStorage.getItem('token');
    }

    setToken(token) {
        localStorage.setItem(token);
    }

    isLoggedIn() {
        const token = localStorage.getItem('token');
        if(!token || token == '' || token == null)
        {
            return false;
        }
        return true;
    }

    signIn(callback) {
        this.loginMod.loading = true;
        Service.post(
        'signin',
        this.loginMod.input,
        (status, response) => {
            this.loginMod.loading = false;
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('name', response.data.name);
            localStorage.setItem('email', response.data.email);
            localStorage.setItem('pro_pic_url', response.data.pro_pic_url);
            localStorage.setItem('is_mentor', response.data.is_mentor);
            localStorage.setItem('userID', response.data.id);
            localStorage.setItem('quickbloxId', response.data.quickbloxId);
            window.location.href=`${process.env.PUBLIC_URL}/`;
        },
        ).catch(error => {
            this.loginMod.loading = false;
            Service.handleError(error);
            //GLOBAL.CustomToast.show(this.errorMsg);
        });
    }

    async signUp(callback) {
        
        this.registerMod.loading = true;
        Service.post(
        'signup',
        this.registerMod.input,
        async (status, response) => {
            
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('name', response.data.name);
            localStorage.setItem('email', response.data.email);
            localStorage.setItem('pro_pic_url', response.data.pro_pic_url);
            localStorage.setItem('is_mentor', response.data.is_mentor);
            localStorage.setItem('userID', response.data.id);

            const QuickBloxUserID =  await videoCallStore.createUser(response.data.id, response.data.name);
            //const QuickBloxUserID =  12;
            Service.post(
            'updateQuickBloxID',
            {
                quickbloxId: parseInt(QuickBloxUserID),
                currentUserId: response.data.id,
            },
            (status, response) => {
                this.registerMod.loading = false;
                localStorage.setItem('quickbloxId', parseInt(QuickBloxUserID));
                window.location.href=`${process.env.PUBLIC_URL}/`;    
            },
            ).catch(error => {
                //Service.handleError(error);
            });
        },
        ).catch(error => {
            this.registerMod.loading = false;
            Service.handleError(error);
            //GLOBAL.CustomToast.show(this.errorMsg);
        });
    }

    submitForgotPasswordRequest() {
        this.forgotPassMod.loading = true;
        Service.post(
        'forgotpasswordrequest',
        { email: this.forgotPassMod.input.email },
        (status, response) => {
            this.forgotPassMod.loading = false;
            this.forgotPassMod.showOTPInput = true;
        },
        ).catch(error => {
            this.forgotPassMod.loading = false;
            Service.handleError(error);
            //GLOBAL.CustomToast.show(this.errorMsg);
        });
    }

    updatePasswordRequest(callback) {
        this.forgotPassMod.loading = true;
        Service.post(
        'updatePasswordRequest',
        this.forgotPassMod.input,
        (status, response) => {
            this.forgotPassMod.loading = false;
            this.forgotPassMod.showOTPInput = false;
            this.forgotPassMod.input = this.forgotPassMod.initialInput;
            return callback();
        },
        ).catch(error => {
            this.forgotPassMod.loading = false;
            Service.handleError(error);
            //GLOBAL.CustomToast.show(this.errorMsg);
        });
    }
}

const authStore = new Store();
export default authStore;