import React from 'react';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import './style.scss';
import { observer } from 'mobx-react';
import BoundInput from '../../../../components/Form/BoundInput';
import notesStore from '../../../../stores/notesStore';
import { Link } from 'react-router-dom';
import PDFEditor from '../../../../components/pdfEditor/pdfEditor';
import notify from '../../../../components/notify';

import GetBaseFromPdf from '../../../../components/Functions/GetBaseFromPdf';
import GetPreviewPDFBase64 from '../../../../components/Functions/GetPreviewPDFBase64';
import menteerProfileStore from '../../../../stores/menteer/profileStore';

let coverSelected = false;
let previewSelected = false;

@observer class EditNoteStep2 extends React.Component {

    async componentDidMount() {
        //this.getInfo();
        notesStore.getNoteInfo(this.props.match.params.id, (resp) => {

        });
        //const base = await GetBaseFromPdf('https://menteerebucket.s3.us-east-2.amazonaws.com/notes/16/pdf/944417700sample.pdf', 1);
        //const base = await GetPreviewPDFBase64('https://menteerebucket.s3.us-east-2.amazonaws.com/notes/16/pdf/944417700sample.pdf', '1,2');
    }

    submit() {
        if (!notesStore.myNoteInfo.data.feat_img_id) {
            notify('Please set cover image first', 'warning');
        } else if (!notesStore.myNoteInfo.data.preview_id) {
            notify('Please set Preview first', 'warning');
        } else {
            this.props.history.push(`${process.env.PUBLIC_URL}/AddNewNoteThankYou`);
        }
    }

    async GeneratePreview() {
        let pageCount = notesStore.setPreviewModel.input.split(',');
        if (pageCount.length > 5) {
            notify('maximum 5 pages allowed!');
        } else {

            if (notesStore.setPreviewModel.input != '') {
                notesStore.setPreviewModel.loading = true;

                const base = await GetPreviewPDFBase64(notesStore.myNoteInfo.data.doc, notesStore.setPreviewModel.input);
                if (base != '') {
                    notesStore.setNotePreview(notesStore.myNoteInfo.data.id, base, () => {
                        notify('Preview Generated successfully');
                        notesStore.myNoteInfo.data.preview_id = 1;
                    });
                } else {
                    notify('Invalid Page numbers', 'warning');
                    notesStore.setPreviewModel.loading = false;
                }
            }

            else {
                notify('Please enter page numbers first', 'warning');
            }
        }
    }

    async GenerateCover() {
        let coverCount = notesStore.setCoverPicModel.input.split(',');
        if (coverCount.length > 1) {
            notify('only 1 page is allowed to set as a cover')
        } else {
            if (notesStore.setCoverPicModel.input != '') {
                notesStore.setCoverPicModel.loading = true;
                const base = await GetBaseFromPdf(notesStore.myNoteInfo.data.doc, parseInt(notesStore.setCoverPicModel.input));
                if (base != '') {
                    notesStore.setCoverPic(notesStore.myNoteInfo.data.id, base, () => {
                        notesStore.myNoteInfo.data.feat_img_id = 1;
                        notify('Cover image updated successfully');
                    });
                } else {
                    notify('Invalid Page number', 'warning');
                    notesStore.setCoverPicModel.loading = false;
                }
            } else {
                notify('Please enter page number first', 'warning');
            }
        }
    }

    render() {
        const Data = notesStore.myNoteInfo.data;
        return (
            <DashboardBody>
                <div className="EditNoteStep2">
                    {!notesStore.myNoteInfo.loading && notesStore.myNoteInfo.data != null ? (
                        <div className="col-md-12">
                            <div className="pageTitle row">
                                <div className="col-md-9">
                                    <div className="row">
                                        {/* <div className="col-md-2">
                                            <img src={Data.cover} className="coverImg" />
                                        </div> */}
                                        <div className="col-md-10 contentSec">
                                            <h3>{Data.title}</h3>
                                            {/* <p>{Data.desc}</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 btnSec">
                                    <button className="btn btn-primary" onClick={() => {
                                        this.submit();
                                    }}>Submit for Review</button>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-6 StepFrm">
                                    <h4>Step 1 {notesStore.myNoteInfo.data.preview_id != null ? (<span class="icon icon-check greencheck"></span>) : null}</h4>
                                    <h6>Set Preview Of Your Notes</h6>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <BoundInput
                                                placeholder="Mention pages as 2, 6, 9 to set the notes preview"
                                                model={notesStore.setPreviewModel}
                                                property="input"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <button onClick={() => {
                                                this.GeneratePreview();
                                            }} className="btn btn-info" disabled={notesStore.setPreviewModel.loading}>Set Preview</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 StepFrm">
                                    <h4>Step 2 {notesStore.myNoteInfo.data.feat_img_id != null ? (<span class="icon icon-check greencheck"></span>) : null}</h4>
                                    <h6>Set Cover Of Your Notes</h6>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <BoundInput
                                                placeholder="Enter Page number like 1"
                                                model={notesStore.setCoverPicModel}
                                                property="input"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <button onClick={() => {
                                                this.GenerateCover();
                                            }} className="btn btn-info" disabled={notesStore.setCoverPicModel.loading}>Set Cover</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <PDFEditor pdfURL={Data.doc} noteID={this.props.match.params.id} callback={() => { this.getInfo() }} />

                        </div>
                    ) : 'loading'}
                </div>
            </DashboardBody>
        );
    }
}

export default EditNoteStep2;