import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import subscriptionStore from '../../../../stores/subscriptionStore';
import noResultFound from '../../../../assets/images/noResultFound.svg';
import MenteereLoader from '../../../../components/MenteereLoader';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';


@observer class MenteereMySubscription extends React.Component {

    componentWillMount() {
        subscriptionStore.getMySubscriptions();
    }

    render() {
        let data = subscriptionStore.MySubscriptions.data;
        return (
            <DashboardBody>
                <div className="row MySUbscriptionsPage">
                    <div className="col-md-12">
                        <div className="pageTitle mb-4">
                            <h4>My Subscription</h4>
                        </div>
                    </div>
                    {subscriptionStore.MySubscriptions.loading ? (
                        <div className="text-center mb-5 mt-5">
                            <MenteereLoader type="spinner" />
                        </div>
                    ) : null}
                    {subscriptionStore.MySubscriptions.data != null && subscriptionStore.MySubscriptions.data.length == 0 ? (
                        <div className="noResultsFound col-md-12 text-center">
                            <img src={noResultFound} />
                            <h4>It's a bit empty here.... </h4>
                            <p>Explore notes from the student of top universities</p>
                            <Link to={`${process.env.PUBLIC_URL}/notes`} className="btn btn-primary">Explore Notes</Link>
                        </div>
                    ) : null}
                    {!subscriptionStore.MySubscriptions.loading && subscriptionStore.MySubscriptions.data != null ? subscriptionStore.MySubscriptions.data.map((item) =>
                        <div className="col-md-12 SubItem">
                            <div className="row">
                                <div className="col-md-9 upperInfo">
                                    <h2>${item.total}/ Month</h2>
                                    <p>Unlimited Access to All Notes</p>
                                </div>
                                <div className="col-md-3">
                                    <div class="statusSign">
                                        Active <div className="dot"></div>
                                    </div>
                                </div>
                                <div className="col-md-12 SubItems">
                                    <div className="subitm">
                                        <strong>Subscription ID</strong>
                                        <p>#{item.id}</p>
                                    </div>
                                    <div className="subitm">
                                        <strong>Price</strong>
                                        <p>${item.total}</p>
                                    </div>
                                    <div className="subitm">
                                        <strong>Start Date</strong>
                                        <p>{new Date(item.valid_from).toLocaleDateString("en-US")}</p>
                                    </div>
                                    <div className="subitm">
                                        <strong>Renewal on</strong>
                                        <p>{new Date(item.valid_upto).toLocaleDateString("en-US")}</p>
                                    </div>
                                    <div className="subitm">
                                        <strong>Access</strong>
                                        <p>Unlimited access to all Notes</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </DashboardBody>
        );
    }
}

export default MenteereMySubscription;

