import React from 'react';
import {observer} from 'mobx-react';
import Header from '../../../components/Containers/Header/Header';
import Footer from '../../../components/Containers/Footer';
import './styles.scss';
import AboutMetaInfo from '../../../components/AboutUs/AboutMetaInfo';
import GlobalSchool from '../../../components/AboutUs/GlobalSchool';
import JoinMenteere from '../../../components/AboutUs/JoinMenteere';
import MeetOur from '../../../components/AboutUs/MeetOur';
import WhatSets from '../../../components/AboutUs/WhatSets';
import constants from '../../../shared/constants';

@observer class AboutUs extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className="AboutUsPage">
                <Header />
                    <div className="row">
                        <GlobalSchool />
                        <AboutMetaInfo />
                        <WhatSets />
                        <MeetOur />
                        <JoinMenteere />
                    </div>
                <Footer />
            </div>
        );
    }
}

export default AboutUs;