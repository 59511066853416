import React, { Component } from 'react';
import './style.scss';

import cycle from '../../../assets/images/cycle.svg'
import coaching from '../../../assets/images/coaching.svg'
import reading from '../../../assets/images/reading.svg'
import bookvector from '../../../assets/images/bookvector.svg'
import yellowBg from '../../../assets/images/yellowBg.svg'
import cap from '../../../assets/images/cap.svg'
import scribble from '../../../assets/images/scribble.svg'

function GlobalSchool() {
    return(
        <div className="col-md-12 SchoolPlan">
            <div className="row">
                <div className="container"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="SchoolText">                                
                                <p><sub className="arrow"><img src={scribble} className="Max-Width"/></sub>Globally, with over  1.2 billion students
                                 out of classrooms, there is a growing gap between what 
                                 the students of today need versus what schools can provide.</p>  
                            </div>
                        </div>
                        <div className="col-md-6 text-right">
                            <img src={cycle} className="Max-Width cycle"/>
                        </div> 
                    </div>
                </div> 
            </div>
                <div className="container ">
                    <div className="row ">
                        <div className="col-md-12">
                            <p className="Student"><sub className="cap"><img src={cap} className="Max-Width"/></sub>Students, worldwide, have untapped potential due to the lack of access to:</p>
                        </div>
                        <div className="col-md-4">
                            <div className="StudentText">
                                <div className="Coaching">
                                    <img src={coaching} className="Max-Width"/>
                                    <p>Able Educators</p>
                                </div>                            
                            </div>
                        </div>                        
                        <div className="col-md-4">
                            <div className="StudentText">
                                <div className="Coaching">
                                    <img src={reading} className="Max-Width"/>
                                    <p>Valuable Advisors</p>
                                </div>                            
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="StudentText">
                                <div className="Coaching">
                                    <img src={bookvector} className="Max-Width"/>
                                    <p>High-Quality Resources</p>
                                </div>                            
                            </div>
                        </div> 
                    </div> 
                </div>            
        </div>
    );    
}

export default GlobalSchool;