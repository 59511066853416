import React from 'react';

import './styles.scss';
import { Link } from 'react-router-dom';
import {observer} from 'mobx-react';
import meetingStore from '../../../../stores/meetingStore';
import MenteereLoader from '../../../../components/MenteereLoader';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import noResultFound from '../../../../assets/images/noResultFound.svg';
import moment from 'moment';
import mentorshipStore from '../../../../stores/mentorshipStore';

@observer class MentorshipList extends React.Component {

    componentWillMount() {
        mentorshipStore.myMentorshipList();
    }

    render() {
        let Model = mentorshipStore.MentorshipList;
        return (
            <DashboardBody>
                <div className="row MentorshipListPage">
                    <div className="col-md-12">
                        <div className="pageTitle mb-4">
                            <h4>Mentorship</h4>
                        </div>
                    </div>
                    {Model.loading?(
                        <div className="text-center mb-5 mt-5 col-md-12">
                            <MenteereLoader type="spinner" />
                        </div>
                    ):null} 

                    <div className="col-md-12">
                    {!Model.loading && Model.data != null?(
                        <div className="row">
                            {Model.data.map((item) => 
                                <div className="col-md-4">
                                    <div className="MeetingBox">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="UserImg">
                                                    <img src={item.UserProfilePicURL} />
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <p className="MentorName">{item.UserNiceName}</p>
                                            </div>
                                            <div className="col-md-6 midinfo mb-4">
                                                <p>{moment(new Date(item.SlotStartTime)).format('dddd - DD MMMM')}</p>
                                            </div>
                                            <div className="col-md-6 midinfo text-right">
                                                <p>{moment(new Date(item.SlotStartTime)).format('LT')} - {moment(new Date(item.SlotEndTime)).format('LT')}</p>
                                            </div>
                                            {item.ActionLoading?(
                                                <div className="col-md-12 text-right">
                                                    <MenteereLoader type='spinner-watch' width={25} height={25} />
                                                </div>
                                            ):(
                                                <>
                                                    <div className="col-md-6">
                                                        <Link to={`${process.env.PUBLIC_URL}/MentorshipDetail/${item.SlotID}`} className="viewBtn">View</Link>
                                                    </div>
                                                    <div className="col-md-6 text-right">
                                                        {/* <Link className="joinCallBtn" to={`${process.env.PUBLIC_URL}/`}>Join Call</Link> */}
                                                        {item.SlotApprovalStatus == 1?(
                                                            <Link className="joinCallBtn" to={`${process.env.PUBLIC_URL}/VideoCall?call=${item.UserQuickBloxID}`}>Call Now</Link>
                                                        ):null}
                                                        {item.SlotApprovalStatus == 0?(
                                                            <div className="ActionBtns">
                                                                <button type="button" className="SuccessBtn" onClick={()=> {
                                                                    mentorshipStore.approveSlot(item);
                                                                }}>Approve</button>
                                                                <span>|</span>
                                                                <button type="button" className="RejectBtn" onClick={()=> {
                                                                    mentorshipStore.rejectSlot(item);
                                                                }}>Reject</button>
                                                            </div>
                                                        ):null}
                                                        {item.SlotApprovalStatus == -1?(
                                                            <p style={{ color: 'red' }}>Rejected</p>
                                                        ):null}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {Model.data.length==0?(
                                <div className="noResultsFound col-md-12 text-center">
                                    <img src={noResultFound} />
                                    <h4>It's a bit empty here.... </h4>
                                    <p>Looks like you haven't booked any meeting yet</p>
                                    <Link to={`${process.env.PUBLIC_URL}/experts`} className="btn btn-primary">Explore Experts</Link>
                                </div>
                            ):null}
                        </div>
                    ):null}
                    </div>
                </div>
            </DashboardBody>
        );
    }
}
export default MentorshipList;

