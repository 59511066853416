import React from 'react';
import {observer} from 'mobx-react';
import Header from '../../../../components/Containers/Header/Header';
import './styles.scss';
import PDFViewer from '../../../../components/pdfViewer/pdfViewer';
import MenteereLoader from '../../../../components/MenteereLoader';
import notesStore from '../../../../stores/notesStore';
import { Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import selectedCircle from '../../../../assets/images/selectedCircle.svg';
import check2 from '../../../../assets/images/check2.svg';
import NoteBox from '../../../../components/Notes/NoteBox';
import Footer from '../../../../components/Containers/Footer';
import rootStore from '../../../../stores/rootStore';
import notify from '../../../../components/notify';
import Rating from '@material-ui/lab/Rating';
import expertStore from '../../../../stores/expertStore';
import Calendar from 'react-calendar';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';
import mentorshipStore from '../../../../stores/mentorshipStore';


@observer class BookExpertThankYou extends React.Component {

    render() {
        return (
            <div className="BookExpertThankYouPage">
                <Header />
                <div className="HeaderMargin"></div>
                <div className="container">
                    <div className="ThankyouPage">
                        <img src={check2} />
                        <h1>Thank You!</h1>
                        <p>Consultation Booked Successfully.<br />We Will Notify the Mentor and Confirm the Consultation </p>
                        {localStorage.getItem('is_mentor') == 'true'?(
                            <Link className="btn btn-primary btn-bit-wide" to={`${process.env.PUBLIC_URL}/dashboard`}>Go to Dashboard</Link>
                        ):(
                            <Link className="btn btn-primary btn-bit-wide" to={`${process.env.PUBLIC_URL}/MenteeDashboard`}>Go to Dashboard</Link>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default BookExpertThankYou;