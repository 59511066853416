import React from 'react';
import {observer} from 'mobx-react';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';
import MenteereLoader from '../../../../components/MenteereLoader';
import ProfilePictureBadge from '../../../../components/ProfilePictureBadge';
import { Link } from 'react-router-dom';
import './styles.scss';
import myprofilebanner from '../../../../assets/images/myprofilebanner.svg';
import pencil from '../../../../assets/images/pencil.svg';
import menteerProfileStore from '../../../../stores/menteer/profileStore';
import {Modal} from 'react-bootstrap';
import BoundInput from '../../../../components/Form/BoundInput';
import notify from '../../../../components/notify';
import linkedin from'../../../../assets/images/linkedin.svg';

@observer class MenteerMyProfile extends React.Component {

    componentDidMount() {
        this.getMyProfile();
    }

    getMyProfile() {
        menteerProfileStore.getMyProfile();
    }

    changePassword() {
        if(menteerProfileStore.myprofile.ChangePassInput.oldPassword == '') {
            notify('Please enter Old Password', 'danger');
            return false;
        }
        if(menteerProfileStore.myprofile.ChangePassInput.newPassword == '') {
            notify('Please enter New Password', 'danger');
            return false;
        }
        if(menteerProfileStore.myprofile.ChangePassInput.confirmPassword == '') {
            notify('Please Confirm Password', 'danger');
            return false;
        }
        menteerProfileStore.updatePassword(()=> {
            notify('Passwords Updated Successfully');
            menteerProfileStore.myprofile.showChangePasswordPop = false;
        });
    }

    render() {
        const Model = menteerProfileStore.myprofile.data;
        return (
            <DashboardBody>
                <div className="MenteerMyProfile">
                {!menteerProfileStore.myprofile.infoLoading && menteerProfileStore.myprofile.data != null?(
                    <div className="row">                        
                        <div className="col-md-12">                            
                            <div className=" row welcomeBg">
                                <div className="col-md-3">
                                    <img src={myprofilebanner} className="img-responsive MiniPic"/>
                                </div>
                                <div className="col-md-9 padd25">
                                    <h4 className="WelText">Welcome</h4> 
                                    <p className="paraWel">View/ Edit your profile</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 mb-4">
                            <h3>Personal Information</h3>
                        </div>
                        <div className="col-md-4 text-right align-items-center">
                            <button className="editbtn" onClick={()=> { menteerProfileStore.myprofile.showChangePasswordPop = true }}><img src={pencil} /> Change Password</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Link className="editbtn" to={`${process.env.PUBLIC_URL}/EditMyProfile`}><img src={pencil} /> Edit</Link>
                        </div>
                        <div className="col-md-2">
                            <ProfilePictureBadge />
                        </div>
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="">&nbsp;</p>
                                </div>
                                <div className="col-md-4 ProfileMeta mb-4">
                                    <p><strong>Name:</strong> {Model.userMeta.name}</p>
                                    <p><strong>Tagline:</strong> {Model.userMeta.tagline}</p>
                                    <p><strong>Account Type:</strong> {Model.userMeta.is_mentor?'Menteer':'Mentee'}</p>
                                </div>
                                <div className="col-md-8 ProfileMeta">
                                    <p><strong>Email:</strong> {Model.userMeta.email}</p>
                                    <p><strong>Password:</strong> *****</p>
                                    <p><strong>About:</strong> {Model.userMeta.about_me}</p>
                                </div>
                                <div className="col-md-12">
                                    <p className="">&nbsp;</p>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label>Personal Document</label>
                                    <input type="text" className="form-control" value={Model.verificationInfo.personal_doc_type} readOnly />
                                </div>
                                <div className="col-md-4">       
                                    <label><img src={linkedin} className="linkimg"/> Linkedin Profile</label>
                                    <input type="text" className="form-control" value={Model.verificationInfo.linkedin_url} readOnly />
                                </div>
                                <div className="col-md-4">
                                    &nbsp;
                                </div>
                                
                                <div className="divider"></div>

                                <div className="col-md-4 mb-4">
                                    <label>Present University <span className="StarRed">*</span></label>
                                    <input type="text" className="form-control" value={Model.verificationInfo.universityName} readOnly />
                                </div>
                                <div className="col-md-4">
                                    <label>&nbsp;</label>
                                    <input type="text" className="form-control" value={Model.verificationInfo.major} readOnly />
                                </div>
                                <div className="col-md-4">
                                    &nbsp;
                                </div>
                                
                                <div className="divider"></div>

                                <div className="col-md-4 mb-4">
                                    <label>High School</label>
                                    <input type="text" className="form-control" value={Model.verificationInfo.highschool_grade_type} readOnly />
                                    {/* <Link className="view-btn">View</Link>
                                    <Link className="edit-btn">Edit</Link> */}
                                </div>
                                <div className="col-md-4">
                                    <label>&nbsp;</label>
                                    <input type="text" className="form-control" value={Model.verificationInfo.highschool_score} readOnly />
                                </div>
                                <div className="col-md-4">
                                    &nbsp;
                                </div>

                                <div className="divider"></div>

                                <div className="col-md-12 mb-2">
                                    <label>Other Qualifications (Optional</label>
                                </div>

                                
                                {Model.verificationInfo.other_ql_json!=null?JSON.parse(Model.verificationInfo.other_ql_json).map((item) => 
                                    <>
                                     <div className="col-md-4 mb-4">
                                        <input type="text" className="form-control" value={item.type} readOnly />
                                        {/* <Link className="view-btn">View</Link>
                                        <Link className="edit-btn">Edit</Link> */}
                                    </div>
                                    <div className="col-md-4">
                                        <input type="text" className="form-control" value={item.score} readOnly />
                                    </div>
                                    <div className="col-md-4">
                                        &nbsp;
                                    </div>
                                    </>
                                ):null}
                               
                                <div className="divider"></div>

                                <div className="col-md-12 mb-2">
                                    <label>University Accepted to (Optional)</label>
                                </div>

                                {Model.verificationInfo.university_accepted_json != null?JSON.parse(Model.verificationInfo.university_accepted_json).map((item) => 
                                    <>
                                    <div className="col-md-4">
                                        <input type="text" className="form-control" value={item.university} readOnly />
                                        {/* <Link className="view-btn">View</Link>
                                        <Link className="edit-btn">Edit</Link> */}
                                    </div>
                                    <div className="col-md-4">
                                        &nbsp;
                                    </div>
                                    <div className="col-md-4">
                                        &nbsp;
                                    </div>
                                    </>
                                ):null}
                                
                            </div>
                        </div>
                    </div>
                    ):(
                        <MenteereLoader />
                    )}
                    <Modal backdrop="static" show={menteerProfileStore.myprofile.showChangePasswordPop} dialogClassName="VerifyEmailPopup">
                        <Modal.Body>
                            <div class="row">
                                <div class="col-md-10 mb-4">
                                    <h4>Change Password</h4>
                                </div>
                                <div class="col-md-2 PopCloseIcon" onClick={()=>{ menteerProfileStore.myprofile.showChangePasswordPop = false }}>
                                    <span className="icon icon-close"></span>
                                </div>
                                <div class="col-md-12 mt-2">
                                    <label>Old Password</label>
                                    <BoundInput
                                        model={menteerProfileStore.myprofile.ChangePassInput}
                                        property="oldPassword"
                                    />
                                </div>
                                <div class="col-md-12 mt-2">
                                    <label>New Password</label>
                                    <BoundInput 
                                        model={menteerProfileStore.myprofile.ChangePassInput}
                                        property="newPassword"
                                    />
                                </div>
                                <div class="col-md-12 mt-2">
                                    <label>Confirm Password</label>
                                    <BoundInput 
                                        model={menteerProfileStore.myprofile.ChangePassInput}
                                        property="confirmPassword"
                                    />
                                </div>
                                <div class="col-md-12 mt-4 mb-2">
                                    <button type="button" class="btn btn-primary btn-bit-wide" onClick={()=> {
                                        this.changePassword();
                                    }} disabled={menteerProfileStore.myprofile.changePassLoading}>Update Password</button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </DashboardBody>
        );
    }
}

export default MenteerMyProfile;