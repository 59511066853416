import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams } from "react-router-dom";
import notFound from '../screens/notFound';

import Login from '../screens/Auth/Login/Login';
import Signup from '../screens/Auth/Signup/Signup';
import Home from '../screens/Inner/Home';
import Dashboard from '../screens/Internal/Dashboard/Dashboard';
import VerifyProfile from '../screens/Internal/VerifyProfile/VerifyProfile';
import VerifyProfile2 from "../screens/Internal/VerifyProfile2/VerifyProfile2";
import VerifyProfile3 from "../screens/Internal/VerifyProfile3/VerifyProfile3";
import VerifyProfileThankYou from "../screens/Internal/VerifyProfileThankYou/VerifyProfileThankYou";

import MyNotes from "../screens/Internal/Notes/MyNotes/MyNotes";
import AddNewNote from "../screens/Internal/Notes/AddNewNote/AddNewNote";
import EditNoteStep2 from "../screens/Internal/Notes/EditNoteStep2/EditNoteStep2";
import AddNewNoteThankYou from "../screens/Internal/Notes/AddNewNoteThankYou/AddNewNoteThankYou";
import EditNoteStep1 from "../screens/Internal/Notes/EditNoteStep1/EditNoteStep1";
import SetAvailability from "../screens/Internal/MyMentorship/SetAvailability";
import MenteerMyProfile from "../screens/Internal/Menteer/MyProfile";
import EditMyProfile from "../screens/Internal/Menteer/EditMyProfile";

import MenteeDashboard from "../screens/Internal/Mentee/Dashboard";
import MenteeMyProfile from "../screens/Internal/Mentee/MyProfile";
import NotesList from "../screens/Inner/Notes/NotesList";
import NotesDetail from "../screens/Inner/Notes/NotesDetail";
import AboutUs from "../screens/Inner/AboutUs";
import ContactUs from "../screens/Inner/Contact";
import Termsofuse from "../screens/Inner/Termsofuse";
import PrivacyPolicy from "../screens/Inner/PrivacyPolicy";
import MenteeMySubscription from "../screens/Internal/Mentee/MenteeMySubscription.js";
import MenteeLastReadNotes from "../screens/Internal/Mentee/MenteeLastReadNotes";
import MenteereLastReadNotes from "../screens/Internal/Menteer/MenteereLastReadNotes";
import MenteereMySubscription from "../screens/Internal/Menteer/MenteereMySubscription.js";
import MenteeMyPlaylist from "../screens/Internal/Mentee/MenteeMyPlaylist";
import MenteeMyPlaylistDetail from "../screens/Internal/Mentee/MenteeMyPlaylistDetail";
import MenteereMyPlaylist from "../screens/Internal/Menteer/MenteereMyPlaylist";
import MenteereMyPlaylistDetail from "../screens/Internal/Menteer/MenteereMyPlaylistDetail";
import ExpertsList from "../screens/Inner/Experts/ExpertsList";
import ExpertDetail from "../screens/Inner/Experts/ExpertDetail";
import BookExpert from "../screens/Inner/Experts/BookExpert";
import BookExpertThankYou from "../screens/Inner/Experts/BookExpertThankYou";
import MenteereMyMeetings from "../screens/Internal/Menteer/MenteereMyMeetings";
import MenteereMyEarningsNew from '../screens/Internal/Menteer/MenteerMyEarningNew';
import MentorshipList from "../screens/Internal/MyMentorship/MentorshipList";
import MentorshipDetail from "../screens/Internal/MyMentorship/MentorshipDetail.js";
import RatingsAndReviews from "../screens/Internal/MyMentorship/RatingsAndReviews";
import ForgotPassword from "../screens/Auth/ForgotPassword";
import MenteeMyMeetings from "../screens/Internal/Mentee/MenteeMyMeetings";
import VideoCall from "../screens/Internal/Menteer/VideoCall";
import MenteeVideoCall from "../screens/Internal/Mentee/MenteeVideoCall";
import MenteereMyEarnings from "../screens/Internal/Menteer/MenteereMyEarnings/MenteereMyEarnings"
import VideoConferencing from "../screens/Internal/VideoConferencing";



function Routes() {
  /* if(authStore.isLoggedIn())
  { */
  return (
    <Router>
      <Switch>
        <Route path={`${process.env.PUBLIC_URL}/`} component={Home} exact />
        <Route path={`${process.env.PUBLIC_URL}/notes/:id`} component={NotesDetail} />
        <Route path={`${process.env.PUBLIC_URL}/notes`} component={NotesList} />
        <Route path={`${process.env.PUBLIC_URL}/experts/:id`} component={ExpertDetail} />
        <Route path={`${process.env.PUBLIC_URL}/experts`} component={ExpertsList} />
        <Route path={`${process.env.PUBLIC_URL}/bookexpert/:id`} component={BookExpert} />
        <Route path={`${process.env.PUBLIC_URL}/BookExpertThankYou`} component={BookExpertThankYou} />


        <Route path={`${process.env.PUBLIC_URL}/aboutus`} component={AboutUs} />
        <Route path={`${process.env.PUBLIC_URL}/contactus`} component={ContactUs} />
        <Route path={`${process.env.PUBLIC_URL}/termsofuse`} component={Termsofuse} />
        <Route path={`${process.env.PUBLIC_URL}/privacypolicy`} component={PrivacyPolicy} />



        <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
        <Route path={`${process.env.PUBLIC_URL}/signup`} component={Signup} />
        <Route path={`${process.env.PUBLIC_URL}/forgotpass`} component={ForgotPassword} />


        <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />
        <Route path={`${process.env.PUBLIC_URL}/verify-profile-1`} component={VerifyProfile} />
        <Route path={`${process.env.PUBLIC_URL}/verify-profile-2`} component={VerifyProfile2} />
        <Route path={`${process.env.PUBLIC_URL}/verify-profile-3`} component={VerifyProfile3} />
        <Route path={`${process.env.PUBLIC_URL}/verify-profile-thank-you`} component={VerifyProfileThankYou} />

        <Route path={`${process.env.PUBLIC_URL}/mynotes`} component={MyNotes} />
        <Route path={`${process.env.PUBLIC_URL}/addNewNote`} component={AddNewNote} />
        <Route path={`${process.env.PUBLIC_URL}/editNoteStep1/:id`} component={EditNoteStep1} />
        <Route path={`${process.env.PUBLIC_URL}/editNoteStep2/:id`} component={EditNoteStep2} />

        <Route path={`${process.env.PUBLIC_URL}/SetAvailability`} component={SetAvailability} />



        <Route path={`${process.env.PUBLIC_URL}/AddNewNoteThankYou`} component={AddNewNoteThankYou} />

        <Route path={`${process.env.PUBLIC_URL}/MenteerMyProfile`} component={MenteerMyProfile} />
        <Route path={`${process.env.PUBLIC_URL}/MenteereMySubscription`} component={MenteereMySubscription} />
        <Route path={`${process.env.PUBLIC_URL}/MenteereLastReadNotes`} component={MenteereLastReadNotes} />
        <Route path={`${process.env.PUBLIC_URL}/MenteereMyPlaylist`} component={MenteereMyPlaylist} />
        <Route path={`${process.env.PUBLIC_URL}/MenteereMyPlaylistDetail/:id`} component={MenteereMyPlaylistDetail} />
        <Route path={`${process.env.PUBLIC_URL}/MenteereMyMeetings`} component={MenteereMyMeetings} />
        <Route path={`${process.env.PUBLIC_URL}/RatingsAndReviews`} component={RatingsAndReviews} />
        <Route path={`${process.env.PUBLIC_URL}/MentorshipList`} component={MentorshipList} />
        <Route path={`${process.env.PUBLIC_URL}/MentorshipDetail/:id`} component={MentorshipDetail} />


        <Route path={`${process.env.PUBLIC_URL}/EditMyProfile`} component={EditMyProfile} />

        <Route path={`${process.env.PUBLIC_URL}/MenteeDashboard`} component={MenteeDashboard} />
        <Route path={`${process.env.PUBLIC_URL}/MenteeMyProfile`} component={MenteeMyProfile} />
        <Route path={`${process.env.PUBLIC_URL}/MenteeMySubscription`} component={MenteeMySubscription} />
        <Route path={`${process.env.PUBLIC_URL}/MenteeLastReadNotes`} component={MenteeLastReadNotes} />
        <Route path={`${process.env.PUBLIC_URL}/MenteeMyPlaylist`} component={MenteeMyPlaylist} />
        <Route path={`${process.env.PUBLIC_URL}/MenteeMyMeetings`} component={MenteeMyMeetings} />
        <Route path={`${process.env.PUBLIC_URL}/MenteeMyPlaylistDetail/:id`} component={MenteeMyPlaylistDetail} />
        <Route path={`${process.env.PUBLIC_URL}/VideoCall`} component={VideoCall} />
        <Route path={`${process.env.PUBLIC_URL}/MenteeVideoCall`} component={MenteeVideoCall} />
        <Route path={`${process.env.PUBLIC_URL}/MenteereMyEarnings`} component={MenteereMyEarnings} />
        <Route path={`${process.env.PUBLIC_URL}/MenteereMyEarningsNew`} component={MenteereMyEarningsNew} />

        <Route path={`${process.env.PUBLIC_URL}/VideoConferencing/:id`} component={VideoConferencing} />

        <Route component={notFound} />
      </Switch>
    </Router>
  );
}

export default Routes;