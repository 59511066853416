import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import Service from '../shared/service/service';
import CONSTANTS from '../shared/constants';
import notify from '../components/notify';

const baseUrl = CONSTANTS.API_BASE;

class Store {

    constructor() {
        makeAutoObservable(this);
    }

    bookExpertPage={
        loading: false,
        data: null,
        calendarSlots:{
            loading: false,
            selectedDate: null,
            selectedDateUTC: null,
            dayScheduleData: [],
        }
    };

    bookExpertModel={
        loading: false,
        data: null,
        hrs_in_wallet: 0,
        Input: {
            selectedSlotIDs: '',
            selected_curriculum: '',
            selected_subject: '',
            description: ''
        },
        selectedSlots: [],
        selectedSlotIDs: [],
        CurriculumList: {
            loading: false,
            data: null
        },
        SubjectList: {
            loading: false,
            data: null
        }
    };

    getBookExpertPageInfo(id, callback) {
        this.bookExpertPage.loading = true;
        Service.get(
        'getExpertDetail/'+id,
        (status, response) => {
            this.bookExpertPage.loading = false;
            this.bookExpertPage.data = response.data;
            return callback(response.data);
        },
        ).catch(error => {
            this.bookExpertPage.loading = false;
            Service.handleError(error);
        });
    }

    initSlots(id, callback) {
        this.bookExpertPage.calendarSlots.loading = true;
        Service.post(
        `getExpertFreeSlots/${id}`,
        {
            date: this.bookExpertPage.calendarSlots.selectedDateUTC
        },
        (status, response) => {
            let _this = this;
            _this.bookExpertPage.calendarSlots.loading = false;
            _this.bookExpertPage.calendarSlots.dayScheduleData = response.data;
            return callback();
        },
        ).catch(error => {
            this.bookExpertPage.calendarSlots.loading = false;
            Service.handleError(error);
        });
    }

    getCurriculums() {
        this.bookExpertModel.CurriculumList.loading = true;
        Service.get(
        'getCurriculums',
        (status, response) => {
            this.bookExpertModel.CurriculumList.loading = false;
            this.bookExpertModel.CurriculumList.data = response.data;
        },
        ).catch(error => {
            this.bookExpertModel.CurriculumList.loading = false;
            Service.handleError(error);
        });
    }

    getSubjects() {
        this.bookExpertModel.SubjectList.loading = true;
        Service.get(
        'getSubjects',
        (status, response) => {
            this.bookExpertModel.SubjectList.loading = false;
            this.bookExpertModel.SubjectList.data = response.data;
        },
        ).catch(error => {
            this.bookExpertModel.SubjectList.loading = false;
            Service.handleError(error);
        });
    }

    setWalletHrs() {
        Service.get(
        'myProfile',
        (status, response) => {
            this.bookExpertModel.hrs_in_wallet = response.data.userMeta.hrs;
        },
        ).catch(error => {
            Service.handleError(error);
        });
    }

    submitBookingRequest(callback) {
        this.bookExpertModel.loading = true;
        Service.post(
        'submitBookingRequest/'+this.bookExpertPage.data.id,
        this.bookExpertModel.Input,
        (status, response) => {
            this.bookExpertModel.loading = false;
            this.bookExpertModel.data = response.data;
            return callback(response.data);
        },
        ).catch(error => {
            this.bookExpertModel.loading = false;
            Service.handleError(error);
        });
    }

    completeBookingPayment(id, callback) {
        this.bookExpertModel.loading = true;
        Service.post(
        'completeBookingPayment/'+id,
        {
            amount: 500,
            txn_id: 'test12345',
            gateway_response: '{{BOUNDJSON}}',
            is_paid: true
        },
        (status, response) => {
            this.bookExpertModel.loading = false;
            return callback();
        },
        ).catch(error => {
            this.bookExpertModel.loading = false;
            Service.handleError(error);
        });
    }

}

const expertStore = new Store();
export default expertStore;