import React, { Component } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import noteslistbannerside from '../../../assets/images/noteslistbannerside.svg';

function NotesBanner() {
        return (
            <div className="row NotesListBanner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 bannerContent">
                            <h1>Notes from our Accomplished Menteers</h1>
                            <p>Explore notes from Menteers. You can download, annotate, and share your playlists with other students!</p>
                            <Link className="btn btn-primary d-none">Subscribe</Link>
                        </div>
                        <div className="col-md-5">                            
                            <img src={noteslistbannerside} className="bannerImg" />
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default NotesBanner;