import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import Service from '../shared/service/service';
import CONSTANTS from '../shared/constants';
import notify from '../components/notify';

const baseUrl = CONSTANTS.API_BASE;

class Store {

    constructor() {
        makeAutoObservable(this);
    }

    MentorshipList = {
        loading: false,
        data: null
    }

    RatingsAndReviewsList = {
        loading: false,
        data: null
    }

    MentorshipDetail = {
        loading: false,
        data: null
    }

    setAvailPageModel = {
        loading: false,
        selectedDate: null,
        selectedDateFull: null,
        selectedDateUTC: '',
        addModalVisible: false,
        addSlotForm: {
            formSubmitting: false,
            formInputs: {
                from_date: new Date().toString(),
                to_date: new Date().toString(),
                from_time: '',
                to_time: '',
                from_time_full: '',
                to_time_full: '',
                repeat: false,
                selectedDays: [0, 1, 2, 3, 4, 5, 6]
            },
            daysSelectOption: [
                {
                    value: 0,
                    label: 'Sunday'
                },
                {
                    value: 1,
                    label: 'Monday'
                },
                {
                    value: 2,
                    label: 'Tuesday'
                },
                {
                    value: 3,
                    label: 'Wednesday'
                },
                {
                    value: 4,
                    label: 'Thursday'
                },
                {
                    value: 5,
                    label: 'Friday'
                },
                {
                    value: 6,
                    label: 'Saturday'
                }
            ],
            timeSelectItems: [
                {
                    value: '00:00:00',
                    label: '12:00 AM',
                },
                /* {
                    value: '00:30:00',
                    label: '12:30 AM',
                }, */
                {
                    value: '01:00:00',
                    label: '01:00 AM',
                },
                /* {
                    value: '01:30:00',
                    label: '01:30 AM',
                }, */
                {
                    value: '02:00:00',
                    label: '02:00 AM',
                },
                /* {
                    value: '02:30:00',
                    label: '02:30 AM',
                }, */
                {
                    value: '03:00:00',
                    label: '03:00 AM',
                },
                /* {
                    value: '03:30:00',
                    label: '03:30 AM',
                }, */
                {
                    value: '04:00:00',
                    label: '04:00 AM',
                },
                /* {
                    value: '04:30:00',
                    label: '04:30 AM',
                }, */
                {
                    value: '05:00:00',
                    label: '05:00 AM',
                },
                /* {
                    value: '05:30:00',
                    label: '05:30 AM',
                }, */
                {
                    value: '06:00:00',
                    label: '06:00 AM',
                },
                /* {
                    value: '06:30:00',
                    label: '06:30 AM',
                }, */
                {
                    value: '07:00:00',
                    label: '07:00 AM',
                },
                /* {
                    value: '07:30:00',
                    label: '07:30 AM',
                }, */
                {
                    value: '08:00:00',
                    label: '08:00 AM',
                },
                /* {
                    value: '08:30:00',
                    label: '08:30 AM',
                }, */
                {
                    value: '09:00:00',
                    label: '09:00 AM',
                },
                /* {
                    value: '09:30:00',
                    label: '09:30 AM',
                }, */
                {
                    value: '10:00:00',
                    label: '10:00 AM',
                },
                /* {
                    value: '10:30:00',
                    label: '10:30 AM',
                }, */
                {
                    value: '11:00:00',
                    label: '11:00 AM',
                },
                /* {
                    value: '11:30:00',
                    label: '11:30 AM',
                }, */
                {
                    value: '12:00:00',
                    label: '12:00 PM',
                },
                /* {
                    value: '12:30:00',
                    label: '12:30 PM',
                }, */
                {
                    value: '13:00:00',
                    label: '01:00 PM',
                },
                /* {
                    value: '13:30:00',
                    label: '01:30 PM',
                }, */
                {
                    value: '14:00:00',
                    label: '02:00 PM',
                },
                /* {
                    value: '14:30:00',
                    label: '02:30 PM',
                }, */
                {
                    value: '15:00:00',
                    label: '03:00 PM',
                },
                /* {
                    value: '15:30:00',
                    label: '03:30 PM',
                }, */
                {
                    value: '16:00:00',
                    label: '04:00 PM',
                },
                /* {
                    value: '16:30:00',
                    label: '04:30 PM',
                }, */
                {
                    value: '17:00:00',
                    label: '05:00 PM',
                },
                /* {
                    value: '17:30:00',
                    label: '05:30 PM',
                }, */
                {
                    value: '18:00:00',
                    label: '06:00 PM',
                },
                /* {
                    value: '18:30:00',
                    label: '06:30 PM',
                }, */
                {
                    value: '19:00:00',
                    label: '07:00 PM',
                },
                /* {
                    value: '19:30:00',
                    label: '07:30 PM',
                }, */
                {
                    value: '20:00:00',
                    label: '08:00 PM',
                },
                /* {
                    value: '20:30:00',
                    label: '08:30 PM',
                }, */
                {
                    value: '21:00:00',
                    label: '09:00 PM',
                },
                /* {
                    value: '21:30:00',
                    label: '09:30 PM',
                }, */
                {
                    value: '22:00:00',
                    label: '10:00 PM',
                },
                /* {
                    value: '22:30:00',
                    label: '10:30 PM',
                }, */
                {
                    value: '23:00:00',
                    label: '11:00 PM',
                },
                /* {
                    value: '23:30:00',
                    label: '11:30 PM',
                }, */
                {
                    value: '24:00:00',
                    label: '11:59 PM',
                },
            ]
        },
        dayScheduleData: [],
        daySchedule: [
            {
                time: '12:00 AM',
            },
            {
                time: '01:00 AM',
            },
            {
                time: '02:00 AM',
            },
            {
                time: '03:00 AM',
            },
            {
                time: '04:00 AM',
            },
            {
                time: '05:00 AM',
            },
            {
                time: '06:00 AM',
            },
            {
                time: '07:00 AM',
            },
            {
                time: '08:00 AM',
            },
            {
                time: '09:00 AM',
            },
            {
                time: '10:00 AM',
            },
            {
                time: '11:00 AM',
            },
            {
                time: '12:00 PM',
            },
            {
                time: '01:00 PM',
            },
            {
                time: '02:00 PM',
            },
            {
                time: '03:00 PM',
            },
            {
                time: '04:00 PM',
            },
            {
                time: '05:00 PM',
            },
            {
                time: '06:00 PM',
            },
            {
                time: '07:00 PM',
            },
            {
                time: '08:00 PM',
            },
            {
                time: '09:00 PM',
            },
            {
                time: '10:00 PM',
            },
            {
                time: '11:00 PM',
            }
        ]
    };

    submitSlotForm(callback) {
        this.setAvailPageModel.addSlotForm.formSubmitting = true;
        //alert(JSON.stringify(this.setAvailPageModel.addSlotForm.formInputs));
        //return false;
        Service.post(
            'BookNewSlot',
            this.setAvailPageModel.addSlotForm.formInputs,
            (status, response) => {
                this.setAvailPageModel.addSlotForm.formSubmitting = false;
                return callback();
            },
        ).catch(error => {
            this.setAvailPageModel.addSlotForm.formSubmitting = false;
            Service.handleError(error);
            //GLOBAL.CustomToast.show(this.errorMsg);
        });
    }

    deleteSlot(id, callback) {
        Service.get(
            'deleteSlot/' + id,
            (status, response) => {
                return callback();
            },
        ).catch(error => {
            Service.handleError(error);
        });
    }

    initSlots(callback) {
        this.setAvailPageModel.loading = true;
        Service.post(
            'getSlots',
            {
                date: this.setAvailPageModel.selectedDateUTC
            },
            (status, response) => {
                let _this = this;
                //setTimeout(function(){
                _this.setAvailPageModel.loading = false;
                _this.setAvailPageModel.dayScheduleData = response.data;
                return callback();
                //}, 1000);
            },
        ).catch(error => {
            this.setAvailPageModel.loading = false;
            Service.handleError(error);
            //GLOBAL.CustomToast.show(this.errorMsg);
        });
    }

    myMentorshipList() {
        this.MentorshipList.loading = true;
        Service.get(
            'getMyMentorshipList',
            (status, response) => {
                this.MentorshipList.loading = false;
                this.MentorshipList.data = response.data;
            },
        ).catch(error => {
            this.MentorshipList.loading = false;
            Service.handleError(error);
        });
    }

    myMentorshipDetail(id) {
        this.MentorshipDetail.loading = true;
        Service.get(
            `getMyMentorshipDetail/${id}`,
            (status, response) => {
                this.MentorshipDetail.loading = false;
                this.MentorshipDetail.data = response.data;
            },
        ).catch(error => {
            this.MentorshipDetail.loading = false;
            Service.handleError(error);
        });
    }

    approveSlot(item) {
        item.ActionLoading = true;
        Service.get(
            `approveSlot/${item.SlotID}`,
            (status, response) => {
                item.SlotApprovalStatus = 1;
                item.ActionLoading = false;
                notify('Meeting successfully Approved', 'snack');
            },
        ).catch(error => {
            item.ActionLoading = false;
            Service.handleError(error);
        });
    }

    rejectSlot(item) {
        item.ActionLoading = true;
        Service.get(
            `rejectSlot/${item.SlotID}`,
            (status, response) => {
                item.SlotApprovalStatus = -1;
                item.ActionLoading = false;
                notify('You rejected the Meeting', 'snack');
            },
        ).catch(error => {
            item.ActionLoading = false;
            Service.handleError(error);
        });
    }

    getMyRatings() {
        this.RatingsAndReviewsList.loading = true;
        Service.get(
            'getMyRatings',
            (status, response) => {
                this.RatingsAndReviewsList.loading = false;
                this.RatingsAndReviewsList.data = response.data;
            },
        ).catch(error => {
            this.RatingsAndReviewsList.loading = false;
            Service.handleError(error);
        });
    }


}

const mentorshipStore = new Store();
export default mentorshipStore;