import React, { Component } from 'react';
import {observer} from 'mobx-react';
import OwlCarousel from 'react-owl-carousel2';
import rootStore from '../../../stores/rootStore';
import './styles.scss';
import NoteBox from '../../Notes/NoteBox';
import HomeNoteBox from '../../Notes/HomeNoteBox';

@observer class ExploreNotes extends React.Component {

    componentDidMount() {
        rootStore.getCurriculums((resp)=> {
            rootStore.NotesList.selectedCurriculum = resp[0].id;
            rootStore.getNotes();
        });
    }

    render() {
        const options = {
            items: 3,
            nav: true,
            navText:["<div class='nav-btn prev-slide'><span class='icon icon-arrow-left-circle'></span></div>","<div class='nav-btn next-slide'><span class='icon icon-arrow-right-circle'></span></div>"],
            rewind: true,
            autoplay: false,
            slideBy: 1,
            loop: true,
            dots: false,
            dotsEach: false,
            dotData: false,
            margin: 30,
            responsive: {
                0: {
                    items: 1,
                },                
                767: {
                    items: 1,
                },
                1024: {
                    items: 2,
                },
                1366: {
                    items: 3,
                },
                
            },    
        };
        
        return (
            <div className="rowes ExploreUniversitySec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center secndhead">
                            <h1 className="SecTitle">Explore Notes</h1>
                            <span>Explore Menteers from Top Universities Around the World</span>
                        </div>
                        <div className="CurriculumList">
                            {/* <div className="Flabel">{rootStore.CurriculumList.loading?'loading':'International Baccalaureate'}</div> */}
                            <div className="fItems">
                                {!rootStore.CurriculumList.loading && rootStore.CurriculumList.data != null?(
                                    <>
                                        {rootStore.CurriculumList.data.map((item) =>
                                            <div onClick={()=>{
                                                rootStore.NotesList.selectedCurriculum=item.id;
                                                rootStore.getNotes();
                                            }}  className={`${rootStore.NotesList.selectedCurriculum == item.id?'active fItem':'fItem'}`}>{item.title}</div>
                                        )}
                                    </>
                                ):null}
                            </div>
                        </div>
                        <div className="col-md-12 SliderSc WidthSet">
                            {rootStore.NotesList.loading?'Loading':null}
                            {!rootStore.NotesList.loading && rootStore.NotesList.data!=null?(
                                <>
                                {rootStore.NotesList.data.length > 0?(
                                <OwlCarousel ref="owl" options={options}>
                                    {rootStore.NotesList.data.map((item, index)=>
                                        <div className="paper" key={index}>
                                            <HomeNoteBox data={item} />
                                        </div>
                                    )}
                                </OwlCarousel>
                                ):null}
                                {rootStore.NotesList.data.length == 0?(
                                    <p>No Notes Found</p>
                                ):null}
                                </>
                            ):null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExploreNotes;