import React from 'react';
import DashboardBody from '../../../components/Containers/DashboardBody/DashboardBody';
import './style.scss';
import { Link } from 'react-router-dom';
import verificationStore from '../../../stores/verificationStore';
import {observer} from 'mobx-react';
import BoundInput from '../../../components/Form/BoundInput';
import MenteereLoader from '../../../components/MenteereLoader';
import notify from '../../../components/notify';
import errorMessages from '../../../shared/errorMessages';
var $ = require('jquery');

@observer class VerifyProfile2 extends React.Component {

    componentDidMount() {
        verificationStore.getPrefilledInfo();   
    }

    submit() {
        const prefilledInfo = verificationStore.verifyStep1Model.infoData.prefilledInfo;
        if(prefilledInfo.university == '' || prefilledInfo.university == null) {
            notify(errorMessages.UniversityEmpty, 'danger');
            return false;
        }
        if(prefilledInfo.major == '' || prefilledInfo.major == null) {
            notify(errorMessages.MajorEmpty, 'danger');
            return false;
        }
        if(prefilledInfo.highschool_grade_type == '' || prefilledInfo.highschool_grade_type == null) {
            notify(errorMessages.HighschoolGradeType, 'danger');
            return false;
        }
        if(prefilledInfo.highschool_score == '' || prefilledInfo.highschool_score == null) {
            notify(errorMessages.HighschoolScore, 'danger');
            return false;
        }
        if($('.HighSchoolDocs').length == 0) {
            notify(errorMessages.HighSchoolDocUpload, 'danger');
            return false;
        }

        verificationStore.update(true, ()=> {
            this.props.history.push(`${process.env.PUBLIC_URL}/verify-profile-3`);
        });
    }

    uploadJSONVDoc(file, field, index) {
        verificationStore.uploadJSONVDoc(file, field, index, (object)=> {
            notify('Document uploaded successfully');
            switch(field) {
                case 'other_ql_json':
                    verificationStore.verifyStep1Model.infoData.prefilledInfo.other_ql_json[index].docs.push(object.data);
                    var ids = verificationStore.verifyStep1Model.infoData.prefilledInfo.other_ql_json[index].docids;
                    if(ids == '' || ids == null) {
                        ids = object.data.doc_id
                    } else {
                        ids = ids.toString();
                        ids = ids.split(',');
                        ids.push(object.data.doc_id.toString());
                        ids = ids.join(',');
                    }
                    verificationStore.verifyStep1Model.infoData.prefilledInfo.other_ql_json[index].docids = ids;
                break;
                case 'university_accepted_json':
                    verificationStore.verifyStep1Model.infoData.prefilledInfo.university_accepted_json[index].docs.push(object.data);
                    var ids = verificationStore.verifyStep1Model.infoData.prefilledInfo.university_accepted_json[index].docids;
                    if(ids == '' || ids == null) {
                        ids = object.data.doc_id
                    } else {
                        ids = ids.split(',');
                        ids.push(object.data.doc_id.toString());
                        ids = ids.join(',');
                    }
                    verificationStore.verifyStep1Model.infoData.prefilledInfo.university_accepted_json[index].docids = ids;
                break;
            }
        });
    }

    uploadDoc(file, field) {
        verificationStore.uploadVdoc(file, field, (object)=> {
            notify('Document uploaded successfully');
            switch(field) {
                case 'highschool_doc_ids':
                    verificationStore.verifyStep1Model.infoData.prefilledInfo.highSchoolDocs.push(object.data);
                break;
                case 'personal_doc_ids':
                    verificationStore.verifyStep1Model.infoData.prefilledInfo.personalDocs.push(object.data);
                break;
            }
        });
    }

    addQualificationRow() {
        verificationStore.verifyStep1Model.infoData.prefilledInfo.other_ql_json.push({
            type: null, 
            score: null, 
            docids: "",
            docs: []
        });
    }

    addUAcceptRow() {
        verificationStore.verifyStep1Model.infoData.prefilledInfo.university_accepted_json.push({
            university: null, 
            docids: "",
            docs: []
        });
    }

    render() {
        return (
            <DashboardBody>
                <div className="VerifyProfilePage2">
                    <div className="col-md-12 text-center mt-4">
                        <h4>Academics Details</h4>
                        <div className="verifyProgress">
                            <div className="sec">
                                <div className="dot"></div>
                                <p>Personal Details</p>
                            </div>
                            <div className="sec active">
                                <div className="dot"></div>
                                <p>Academic Details</p>
                            </div>
                            <div className="sec">
                                <div className="dot"></div>
                                <p>Bank Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="pageTitle">
                        <h4>Academics Details</h4>
                    </div>
                    {!verificationStore.verifyStep1Model.infoLoading && verificationStore.verifyStep1Model.infoData !== null?(
                    <div className="row">
                        <div className="col-md-4">
                            <label>Present University <span className="LabelRequired">*</span></label>
                            <select className="form-control" 
                                value={verificationStore.verifyStep1Model.infoData.prefilledInfo.university}
                                onChange={(e)=> {
                                    verificationStore.verifyStep1Model.infoData.prefilledInfo.university = e.target.value;
                                }}
                            >
                                <option value={null}>--Select University--</option>
                                {verificationStore.verifyStep1Model.infoData.metainfo.map((item) =>
                                {
                                    if(item.type == 'university')
                                    {
                                        return <option value={item.id}>{item.title}</option>;
                                    }
                                }
                                    
                                )}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label>&nbsp;</label>
                            <BoundInput
                                placeholder="Major"
                                model={verificationStore.verifyStep1Model.infoData.prefilledInfo}
                                property="major"
                            />
                        </div>
                        <div className="col-md-12 mt-3 mb-5 op-5">
                        </div>
                        <div className="col-md-4">
                            <label>High School/ Grade 12 <span className="LabelRequired">*</span></label>
                            <select className="form-control" 
                                value={verificationStore.verifyStep1Model.infoData.prefilledInfo.highschool_grade_type}
                                onChange={(e)=> {
                                    verificationStore.verifyStep1Model.infoData.prefilledInfo.highschool_grade_type = e.target.value;
                                }}
                            >
                                <option value={null}>--Select Curriculum--</option>
                                {verificationStore.verifyStep1Model.infoData.metainfo.map((item) =>
                                {
                                    if(item.type == 'curriculum')
                                    {
                                        return <option value={item.id}>{item.title}</option>;
                                    }
                                }  
                                )}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label>&nbsp;</label>
                            <BoundInput
                                placeholder="Score" 
                                model={verificationStore.verifyStep1Model.infoData.prefilledInfo}
                                property="highschool_score"
                            />
                        </div>
                        <div className="col-md-4">
                            <label className="col-md-12">&nbsp;</label>
                            <label className="UploadFileInput form-control cursor-pointer">
                                <input type="file" id="personalDocInput" onChange={(e)=>{ this.uploadDoc(e.target.files[0], 'highschool_doc_ids') }} style={{ display: 'none' }} />
                                <p>+ Upload Transcript &nbsp;&nbsp;&nbsp; <span className="icon icon-link"></span>
                                    {verificationStore.verifyStep1Model.loaders.highschool_doc_ids?(
                                        <MenteereLoader type="spinner" color="#000" />
                                    ):null}
                                </p>
                            </label>
                            {verificationStore.verifyStep1Model.infoData.prefilledInfo.highSchoolDocs.map((item, index)=>
                                <div className="AttachItem HighSchoolDocs">
                                    <div className="titleSide">
                                        <p>{item.path}</p>
                                    </div>
                                    <div className="ActionBtns">
                                        <a href={item.url} target="_blank">View</a>
                                        <button onClick={()=>{
                                            verificationStore.verifyStep1Model.infoData.prefilledInfo.highSchoolDocs.splice(index, 1);
                                            verificationStore.removeVdoc(item.doc_id, 'highschool_doc_ids');
                                        }}>Delete</button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-12 mt-3 mb-5 op-5">
                        </div>

                        <div className="col-md-12 CusLabel">
                            <label>Other Qualifications (Optional)</label>
                            {verificationStore.verifyStep1Model.loaders.other_ql_json?(
                                <MenteereLoader type="spinner" color="#000" width={20} height={20} />
                            ):null}
                        </div>
                        
                        {verificationStore.verifyStep1Model.infoData.prefilledInfo.other_ql_json.map((item, index) =>
                        <div className="col-md-12 mb-2">
                            <div className="row">
                                <div className="col-md-4">
                                    <select className="form-control" 
                                        value={item.type}
                                        onChange={(e)=> {
                                            item.type = e.target.value;
                                        }}
                                    >
                                        <option value={null}>--Select--</option>
                                        {verificationStore.verifyStep1Model.infoData.metainfo.map((item) =>
                                        {
                                            if(item.type == 'qualification_type')
                                            {
                                                return <option value={item.title}>{item.title}</option>;
                                            }
                                        }
                                        )}
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <BoundInput
                                        placeholder="Score" 
                                        model={item}
                                        property="score"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label className="UploadFileInput form-control cursor-pointer">
                                        <input type="file" onChange={(e)=>{ this.uploadJSONVDoc(e.target.files[0], 'other_ql_json', index) }} style={{ display: 'none' }} />
                                        <p>+ Proof Picture &nbsp;&nbsp;&nbsp; <span className="icon icon-link"></span></p>
                                    </label>
                                    {item.docs.map((item, index)=>
                                        <div className="AttachItem">
                                            <div className="titleSide">
                                                <p>{item.path}</p>
                                            </div>
                                            <div className="ActionBtns">
                                                <a href={item.url} target="_blank">View</a>
                                                <button onClick={()=>{
                                                    verificationStore.verifyStep1Model.infoData.prefilledInfo.highSchoolDocs.splice(index, 1);
                                                    verificationStore.removeJSONVdoc(item.doc_id, 'other_ql_json', index);
                                                }}>Delete</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        )}
                        <div className="col-md-12 op-5">
                            <small>Clear frontal face photos help to personalize communication and show that you are reliable, authentic and committed</small>
                        </div>
                        <div className="col-md-12 mb-5">
                            <p className="float-right addMoreBtn" onClick={()=> {
                                this.addQualificationRow();
                            }}>+ Add More</p>
                        </div>

                        <div className="col-md-12 CusLabel">
                            <label>University Accepted to (Optional)</label>
                            {verificationStore.verifyStep1Model.loaders.university_accepted_json?(
                                <MenteereLoader type="spinner" color="#000" width={20} height={20} />
                            ):null}
                        </div>
                        
                        {verificationStore.verifyStep1Model.infoData.prefilledInfo.university_accepted_json.map((item, index) =>
                        <div className="col-md-12 mb-2">
                            <div className="row">
                                <div className="col-md-4">
                                    <select className="form-control" 
                                        value={item.university}
                                        onChange={(e)=> {
                                            item.university = e.target.value;
                                        }}
                                    >
                                        <option value={null}>--Select University--</option>
                                        {verificationStore.verifyStep1Model.infoData.metainfo.map((item) =>
                                        {
                                            if(item.type == 'university')
                                            {
                                                return <option value={item.title}>{item.title}</option>;
                                            }
                                        }
                                        )}
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label className="UploadFileInput form-control cursor-pointer">
                                        <input type="file" onChange={(e)=>{ this.uploadJSONVDoc(e.target.files[0], 'university_accepted_json', index) }} style={{ display: 'none' }} />
                                        <p>+ Upload Accpetance Letter &nbsp;&nbsp;&nbsp; <span className="icon icon-link"></span></p>
                                    </label>
                                    {item.docs.map((initem, inindex)=>
                                        <div className="AttachItem">
                                            <div className="titleSide">
                                                <p>{initem.path}</p>
                                            </div>
                                            <div className="ActionBtns">
                                                <a href={initem.url} target="_blank">View</a>
                                                <button onClick={()=>{
                                                    var id = item.docids.toString();
                                                    id = id.split(',');
                                                    var inddex = id.indexOf(initem.doc_id.toString());
                                                    if( inddex > -1 ) {
                                                        id.splice(inddex, 1);
                                                        item.docids = id.join(',');
                                                    }
                                                    item.docs.splice(inindex, 1);
                                                    verificationStore.removeJSONVdoc(initem.doc_id);
                                                }}>Delete</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        )}

                        <div className="col-md-12 op-5">
                            <small>Clear frontal face photos help to personalize communication and show that you are reliable, authentic and committed</small>
                        </div>
                        <div className="col-md-12 mb-5">
                            <p className="float-right addMoreBtn" onClick={()=> {
                                this.addUAcceptRow();
                            }}>+ Add More</p>
                        </div>


                        <div className="col-md-6">
                            <Link className="btn btn-primary btn-bit-wide" to={`${process.env.PUBLIC_URL}/verify-profile-1`}>Prev</Link>
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-primary float-right btn-bit-wide" onClick={()=> { this.submit(); }} disabled={verificationStore.verifyStep1Model.submitLoading}>Save & Continue</button>
                        </div>
                    </div>
                    ):('loading')}
                </div>
            </DashboardBody>
        );
    }
}

export default VerifyProfile2;