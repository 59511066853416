import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import './Signup.scss';
import authStore from '../../../stores/authStore';
import LoginLeftBg from '../../../assets/images/LoginLeftBg.png';
import LogoCircle from '../../../assets/images/LogoCircle.svg';
import notify from '../../../components/notify';
import logoWhite from '../../../assets/images/logoWhite.png';
const $ = require('jquery');

@observer class Signup extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         isToggleOn: true,
         windowWidth: window.innerWidth
      };
      this.handleClick = this.handleClick.bind(this);

   }
   componentDidMount() {
      $('a[href="' + window.location.pathname + '"]').addClass('active');
   }
   handleClick() {
      this.setState(state => ({
         isToggleOn: !state.isToggleOn
      }));
   }

   signUp() {
      if (authStore.registerMod.input.password.length > 3 && authStore.registerMod.input.password.length < 11) { } else {
         notify('Password should be between 4 to 11 characters', 'warning');
         return false;
      }
      if (!this.hasUpperCase(authStore.registerMod.input.password)) {
         notify('Password should contain uppercase letters', 'warning');
         return false;
      }
      if (!this.hasLowerCase(authStore.registerMod.input.password)) {
         notify('Password should contain lowercase letters', 'warning');
         return false;
      }
      if (!this.hasNumber(authStore.registerMod.input.password)) {
         notify('Password should contain numbers', 'warning');
         return false;
      }
      if (!authStore.registerMod.tncAccepted) {
         notify('Please accept Terms and condition first', 'warning');
         return false;
      }
      authStore.signUp();
   }

   hasUpperCase(str) {
      return (/[A-Z]/.test(str));
   }

   hasLowerCase(str) {
      return (/[a-z]/.test(str));
   }

   hasNumber(str) {
      return /\d/.test(str);
   }

   render() {
      const { innerWidth: width, innerHeight: height } = window;

      return (
         <div className="rowes SignupSec">
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
            <div className="col-md-6 CoverArea" style={{ backgroundImage: `url(${LoginLeftBg})` }}>
               <img src={LogoCircle} className="LogoCircle" />
            </div>
            <div className="col-md-6 MainArea">
               <img src={logoWhite} className="Logo" />
               <a onClick={this.handleClick} className="MyBaar">
                  <i class="fa fa-bars"></i>
               </a>
               <div className={`UpperMenu ${this.state.isToggleOn == true ? "" : "active-right"}`}>
                  <li><a href="#" onClick={this.handleClick} className="nav-link"><i class="fa fa-times TimesNone"></i></a></li>
                  <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                  <Link to={`${process.env.PUBLIC_URL}/aboutus`}>About</Link>
                  <Link to={`${process.env.PUBLIC_URL}/experts`}>Experts</Link>
                  <Link to={`${process.env.PUBLIC_URL}/notes`}>Notes</Link>
                  <Link to={`${process.env.PUBLIC_URL}/contactus`}>Contact</Link>
                  <Link to={`${process.env.PUBLIC_URL}/login`}>Sign In</Link>
               </div>
               <div className="Form col-md-12">
                  <div className="FormBody">
                     <h2 className="FormTitle">Register With us</h2>
                     <div className="form-group">
                        <label>Name</label>
                        <input
                           className="form-control"
                           type="text"
                           value={authStore.registerMod.input.name}
                           onChange={(e) => {
                              authStore.registerMod.input.name = e.target.value;
                           }}
                        />
                     </div>
                     <div className="form-group">
                        <label>Email Address</label>
                        <input
                           className="form-control"
                           type="text"
                           value={authStore.registerMod.input.email}
                           onChange={(e) => {
                              authStore.registerMod.input.email = e.target.value;
                           }}
                        />
                     </div>
                     <div className="form-group">
                        <label>Password</label>
                        <input
                           className="form-control"
                           type="password"
                           value={authStore.registerMod.input.password}
                           onChange={(e) => {
                              authStore.registerMod.input.password = e.target.value;
                           }}
                        />
                     </div>
                     <div className="form-group">
                        <ul className="signupValidationList active">
                           <li className={authStore.registerMod.input.password.length > 3 && authStore.registerMod.input.password.length < 11 ? 'active' : ''}>Between 4-10 characters</li>
                           <li className={this.hasUpperCase(authStore.registerMod.input.password) ? 'active' : ''}>Uppercase characters (A-Z)</li>
                           <li className={this.hasLowerCase(authStore.registerMod.input.password) ? 'active' : ''}>Lowercase characters (A-Z)</li>
                           <li className={this.hasNumber(authStore.registerMod.input.password) ? 'active' : ''}>Numbers (0-9)</li>
                        </ul>
                     </div>
                     <div className="form-group">
                        <label>Register as</label>
                        <select value={authStore.registerMod.input.is_mentor} className="form-control" onChange={(e) => {
                           authStore.registerMod.input.is_mentor = e.target.value
                        }}>
                           <option value={false}>Mentee</option>
                           <option value={true}>Menteere</option>
                        </select>
                     </div>

                     <div className="form-group">
                        <label className="TncGroup"><input type="checkbox" className="CheckBox" checked={authStore.registerMod.tncAccepted} onChange={() => { authStore.registerMod.tncAccepted = !authStore.registerMod.tncAccepted }} /> I agree with all the <Link className="color" to={`${process.env.PUBLIC_URL}/termsofuse`}>Terms & Conditions</Link> of Menteere</label>
                     </div>
                     <div className="form-group text-center">
                        <button className="btn btn-primary px-4 SubmitBtns" type="button" onClick={() => {
                           this.signUp();
                        }} disabled={authStore.registerMod.loading}>Submit</button>
                     </div>
                     <p className="text-center">Already have an account? <Link className="btm-link" to={`${process.env.PUBLIC_URL}/login`}>Sign In</Link></p>
                  </div>

               </div>
            </div>
         </div>
      );
   }
}
export default Signup;