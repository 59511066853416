import React, { Component } from 'react';
import './style.scss';
import image from '../../../assets/images/becomeAMenteere.svg';
import mentorMobile from '../../../assets/images/mentorMobile.png';

function BecomeAMenteere() {
        return (
            <div className="rowes BecomeAMenteere">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center secndhead">
                            <h1 className="SecTitle">Become a Menteer</h1>
                            <p className="SecMeta">Menteers have flexible schedules and competitive pay. If you’re interested in helping other students and brushing up on some skills while in college, you might be a great Menteer! </p>
                            <img src={image} className="ImgSet" />
                            <img src={mentorMobile} className="ImgMobile" />
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default BecomeAMenteere;