import React, { Component } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';

function NoteBox(props) {
    //alert(JSON.stringify(props));
        return (
            <Link class="NoteItem" to={`${process.env.PUBLIC_URL}/notes/${props.data.id}`}>
                <div class="NoteItemUp">
                    <div class="cover">
                        <img src={props.data.cover} />
                    </div>
                    <div class="authorMeta">
                        <img src={props.data.universityImageURL} className="universityImg" />
                        <div className="AuthorImg">
                            <img src={props.data.authorImageURL} />
                        </div>
                        <span>{props.data.curriculum} Score</span>
                        <p>{props.data.curriculum_score}</p>
                    </div>
                </div>
                <div class="NoteItemBtm">
                    <div className="NoteName">
                        <h5>{props.data.title}</h5>
                        {/* <Rating name="read-only" value={2} readOnly /> */}
                    </div>
                    <div className="NoteAction">
                        View <span className="icon-arrow-right-circle"></span>
                    </div>
                </div>    
            </Link>
        );
}

export default NoteBox;