import React from 'react';
import jsPDF from "jspdf";
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// The dimensions are in millimeters.
const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297,
};
const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

// Calculates the best possible position of an image on the A4 paper format,
// so that the maximal area of A4 is used and the image ratio is preserved.
const imageDimensionsOnA4 = (dimensions) => {
  const isLandscapeImage = dimensions.width >= dimensions.height;

  // If the image is in landscape, the full width of A4 is used.
  if (isLandscapeImage) {
    return {
      width: A4_PAPER_DIMENSIONS.width,
      height:
        A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
    };
  }

  // If the image is in portrait and the full height of A4 would skew
  // the image ratio, we scale the image dimensions.
  const imageRatio = dimensions.width / dimensions.height;
  if (imageRatio > A4_PAPER_RATIO) {
    const imageScaleFactor =
      (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

    const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

    return {
      height: scaledImageHeight,
      width: scaledImageHeight * imageRatio,
    };
  }

  // The full height of A4 can be used without skewing the image ratio.
  return {
    width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
    height: A4_PAPER_DIMENSIONS.height,
  };
};



let GetPreviewPDFBase64 = async (url, pageNums) => {

  let pageNumsArray = pageNums.split(',');
  var baseURLs = [];

  const loadingTask = pdfjs.getDocument(url);
  const pdf = await loadingTask.promise;

  for (var i in pageNumsArray) {
    var canvas = document.createElement('canvas');
    try {
      var page = await pdf.getPage(parseInt(pageNumsArray[i]));
      var thumbnailWidth = 800;
      var w = thumbnailWidth;
      var scale = w / page.getViewport({ scale: 1 }).width;
      var viewport = page.getViewport({ scale: scale });
      var pageWidth = viewport.width;
      var pageHeight = viewport.height;
      var h = w / (pageWidth / pageHeight);
      canvas.height = h;
      canvas.width = w;
      canvas.style.height = h + "px";
      canvas.style.width = w + "px";
      var i = await page.render({
        canvasContext: canvas.getContext('2d'),
        viewport: viewport
      });
      await i.promise.then(function () {
        baseURLs.push(canvas.toDataURL());
      });
    } catch (e) {

    }
  }

  if (baseURLs.length == 0) {
    return '';
  }

  //const doc = new jsPDF();
  const doc = new jsPDF('p', 'mm', 'a4', true);
  // We let the images add all pages,
  // therefore the first default page can be removed.
  doc.deletePage(1);
  for (var item in baseURLs) {
    var img = new Image();
    img.src = baseURLs[item];
    const imageDimensions = imageDimensionsOnA4({
      width: viewport.width,
      height: viewport.height,
    });

    doc.addPage();
    doc.addImage(
      img.src,
      'PNG',
      // Images are vertically and horizontally centered on the page.
      (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
      (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
      imageDimensions.width,
      imageDimensions.height,
      '',
      'FAST'
    );
  }
  return doc.output("datauristring");
  //const pdfURL = doc.output("bloburl");
  //window.open(pdfURL, "_blank");
}

export default GetPreviewPDFBase64;