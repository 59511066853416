import React from 'react';
import MenteeDashboardBody from '../../../../components/Containers/MenteeDashboardBody';

import './styles.scss';
import { Link } from 'react-router-dom';
import {observer} from 'mobx-react';
import dashboardStore from '../../../../stores/dashboardStore';
import MySubscriptionBG from '../../../../assets/images/MySubscriptionBG.svg';
import MenteereLoader from '../../../../components/MenteereLoader';
import noResultFound from '../../../../assets/images/noResultFound.svg';
import playlistIcon from '../../../../assets/images/playlistIcon.svg';

import notesStore from '../../../../stores/notesStore';
import DashboardBody from '../../../../components/Containers/DashboardBody/DashboardBody';


@observer class MenteereMyPlaylistDetail extends React.Component {

    componentWillMount() {
        notesStore.getMyPlaylistDetail(this.props.match.params.id);
    }

    render() {
        const Model = notesStore.MyPlaylistDetailPage.data;
        return (
            <DashboardBody>
                <div className="row MyPlaylistDetailPage">
                    {notesStore.MyPlaylistDetailPage.loading?(
                        <div className="text-center mb-5 mt-5">
                            <MenteereLoader type="spinner" />
                        </div>
                    ):null}

                    <div className="col-md-12">
                    {!notesStore.MyPlaylistDetailPage.loading && notesStore.MyPlaylistDetailPage.data != null?(
                        <div className="row">
                            <div className="col-md-4">
                                <div className="CoverContainer">
                                    <img src={Model.playlistInfo.cover} />
                                </div>
                                <h4>{Model.playlistInfo.title}</h4>
                                <p>By {localStorage.getItem('name')}</p>
                                <p className="play-btn">{Model.playlistInfo.count} Notes</p>
                            </div>
                            <div className="col-md-8 notelist">
                                {notesStore.MyPlaylistDetailPage.data.data.map((item) => 
                                    <Link className="notItem" to={`${process.env.PUBLIC_URL}/notes/${item.note_id}`}>
                                        <div className="coverSide"><img src={item.cover} /></div>
                                        <div className="contSide">
                                            <h5>{item.title}</h5>
                                            <p>{item.desc}</p>
                                            <strong>By {item.authorName}</strong>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                    ):null}
                    </div>
                </div>
            </DashboardBody>
        );
    }
}

export default MenteereMyPlaylistDetail;

