import React from 'react';
import MenteeDashboardBody from '../../../../components/Containers/MenteeDashboardBody';
import rightArrowWhite from '../../../../assets/images/rightArrowWhite.svg';
import check from '../../../../assets/images/check.svg';
import './styles.scss';
import {observer} from 'mobx-react';
import dashboardStore from '../../../../stores/dashboardStore';

@observer class MenteeDashboard extends React.Component {

    componentWillMount() {
        dashboardStore.getDashboard();
    }

    render() {
        let data = dashboardStore.dashModel.data;
        return (
            <MenteeDashboardBody>
                {!dashboardStore.dashModel.infoLoading && dashboardStore.dashModel.data!=null?(
                <div className="DashboardPage">
                    <div className="pageTitle">
                    <h4>Welcome {data.userInfo.name} {data.userInfo.verification_status == 1?(<img src={check} className="checkIcon" />):null}</h4>
                    </div>
                    <div className="row statsBoxes">
                            <div className="statsBox col-md-4">
                                <div className="statsInBox">
                                    <p>{dashboardStore.dashModel.data.meta.notesReadCount||0}</p>
                                    <span>Notes Read</span>
                                    <div className="arrow">
                                        <img src={rightArrowWhite} />
                                    </div>
                                </div>
                            </div>
                            <div className="statsBox col-md-4">
                                <div className="statsInBox">
                                    <p>{dashboardStore.dashModel.data.meta.mentorshipTakenCount||0}</p>
                                    <span>Mentorship Taken</span>
                                    <div className="arrow">
                                        <img src={rightArrowWhite} />
                                    </div>
                                </div>
                            </div>
                            <div className="statsBox col-md-4">
                                <div className="statsInBox">
                                    <p>{dashboardStore.dashModel.data.meta.upcomingMeetingsCount||0}</p>
                                    <span>Upcoming Meetings</span>
                                    <div className="arrow">
                                        <img src={rightArrowWhite} />
                                    </div>
                                </div>
                            </div>                           
                    </div>                    
                </div>
                ):'loading'}
            </MenteeDashboardBody>
        );
    }
}
export default MenteeDashboard;

