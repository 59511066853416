import React from 'react';
import { observer } from 'mobx-react';
import Header from '../../../../components/Containers/Header/Header';
import './styles.scss';
import PDFViewer from '../../../../components/pdfViewer/pdfViewer';
import MenteereLoader from '../../../../components/MenteereLoader';
import notesStore from '../../../../stores/notesStore';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import planbg from '../../../../assets/images/planbg.png';
import greenplanbg from '../../../../assets/images/greenplanbg.png';
import selectedCircle from '../../../../assets/images/selectedCircle.svg';
import unselectedCircle from '../../../../assets/images/unselectedCircle.svg';
import NoteBox from '../../../../components/Notes/NoteBox';
import Footer from '../../../../components/Containers/Footer';
import rootStore from '../../../../stores/rootStore';
import notify from '../../../../components/notify';


@observer class NotesDetail extends React.Component {

    componentDidMount() {
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        });
        this.setPage();
    }

    setPage() {
        notesStore.getNoteDetail(this.props.match.params.id, () => {
            notesStore.getSimilarNotes(this.props.match.params.id);
        });
    }

    subscribeMe() {
        notesStore.subscribeMe(notesStore.PlansListModel.selectedID, () => {
            notesStore.NotesDetailModel.showPlansVisible = false;
            this.setPage();
        });
    }

    render() {
        const Model = notesStore.NotesDetailModel.data;
        return (
            <div className="NotesDetailPage">
                <Header />
                <div className="HeaderMargin"></div>
                <div className="container">
                    <div className="row">
                        {notesStore.NotesDetailModel.loading ? (
                            <div className="col-md-12">
                                <MenteereLoader type="notesdetail" />
                            </div>
                        ) : null}
                        {!notesStore.NotesDetailModel.loading && notesStore.NotesDetailModel.data != null ? (
                            <div className="row">
                                <div className="col-md-6 PreviewSide">
                                    {Model.previewURL != '' && Model.previewURL != null?(
                                        <PDFViewer pdfURL={Model.previewURL} />
                                    ):null}
                                </div>
                                <div className="col-md-6 NoteDetail mb-5">
                                    <h1>{Model.title}</h1>
                                    <div className="divider"></div>
                                    <p><strong>By</strong> <Link className="blue-link" to={`${process.env.PUBLIC_URL}/experts/${Model.authorID}`}>{Model.authorName}</Link></p>
                                    <p><strong>Present University:</strong> {Model.universityName}</p>
                                    <p><strong>High School Score:</strong> {Model.curriculum_score}</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p><strong>Curriculum:</strong> {Model.curriculum}</p>
                                            <p></p>
                                        </div>
                                        <div className="col-md-6">
                                            <p><strong>Subject:</strong> {Model.subjectTitle}</p>
                                            <p></p>
                                        </div>
                                    </div>
                                    <p><strong>Theme:</strong> {Model.themeTitle}</p>
                                    <p><strong>Topic:</strong> {Model.topicTitle}</p>
                                    <p><strong>Description of The Notes</strong></p>
                                    <p>{Model.desc}</p>
                                    <div className="actionButtons">
                                        {!Model.isSubscribed ? (
                                            <button type="button" className="btn btn-primary" onClick={() => {
                                                notesStore.NotesDetailModel.showPlansVisible = true;
                                                notesStore.getPlans();
                                            }}>Subscribe to Read</button>
                                        ) : null}
                                        {Model.isSubscribed ? (
                                            <button type="button" className="btn btn-primary" onClick={() => {
                                                notesStore.NotesDetailModel.isReading = !notesStore.NotesDetailModel.isReading;
                                                notesStore.recordLastReadNote(Model.id);
                                                notesStore.updateClickCount(Model.id);
                                            }}>Read Notes</button>
                                        ) : null}
                                        {Model.isSubscribed ? (
                                            <a className="btn btn-primary" target="_blank" href={Model.docURL} onClick={() => { notesStore.updateClickCount(Model.id) }} download>Download Notes</a>
                                        ) : null}
                                        <button type="button" className="btn btn-primary" onClick={() => {
                                            rootStore.showAddToPlaylist(Model.id);
                                        }}>Add to Playlist</button>
                                    </div>
                                </div>
                                {!notesStore.NotesDetailModel.similarNotes.loading && notesStore.NotesDetailModel.similarNotes.data != null ? (
                                    <div className="col-md-12 mt-5">
                                        <h2 className="text-center mb-5 mt-5">You May also like</h2>
                                        <div className="row">
                                            {notesStore.NotesDetailModel.similarNotes.data.map((item) =>
                                                <div className="col-md-3">
                                                    <NoteBox data={item} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                                {notesStore.NotesDetailModel.isReading ? (
                                    <div className="NotesReader">
                                        <div className="ReaderHeader row">
                                            <div className="col-md-8">
                                                <h4>{Model.title} by {Model.authorName}</h4>
                                            </div>
                                            <div className="col-md-4">
                                                <button className="btn btn-primary float-right" onClick={() => {
                                                    notesStore.NotesDetailModel.isReading = false;
                                                }}>Close</button>
                                            </div>
                                        </div>
                                        <PDFViewer pdfURL={Model.docURL} />
                                    </div>
                                ) : null}
                            </div>) : null}
                    </div>
                </div>
                <Modal backdrop="static" show={notesStore.NotesDetailModel.showPlansVisible} dialogClassName="showPlansModal">
                    <Modal.Body>
                        <div class="row">
                            <div class="col-md-10 mb-4">
                                <h4>Choose Your Plan</h4>
                            </div>
                            <div class="col-md-2 PopCloseIcon" onClick={() => { notesStore.NotesDetailModel.showPlansVisible = false }}>
                                <span className="icon icon-close"></span>
                            </div>
                            <div class="col-md-12">
                                {notesStore.PlansListModel.loading ? (
                                    <div className="text-center">
                                        <MenteereLoader type="spinner" />
                                    </div>
                                ) : null}
                                {notesStore.PlansListModel.data != null && !notesStore.PlansListModel.loading ? notesStore.PlansListModel.data.map((item) => {
                                    return <div className="PlanItem" style={{ backgroundImage: item.id == notesStore.PlansListModel.selectedID ? `url(${greenplanbg})` : `url(${planbg})` }} onClick={() => {
                                        notesStore.PlansListModel.selectedID = item.id
                                    }}>
                                        <div className="infos">
                                            <p className="planTitle">${item.price}<span>/ Month</span></p>
                                            <span className="planDesc">Unlimited Access to All Notes</span><br />
                                            {item.mentorship_hrs > 0 ? (
                                                <span className="planDesc">{item.mentorship_hrs} hrs of Menteership</span>
                                            ) : null}
                                        </div>
                                        <div className="selectedSignify">
                                            {item.id == notesStore.PlansListModel.selectedID ? (
                                                <img src={selectedCircle} />
                                            ) : (
                                                <img src={unselectedCircle} />
                                            )}
                                        </div>
                                    </div>
                                }) : null}
                            </div>

                            <div className="col-md-12">
                                <button type="button" class="btn btn-primary float-right" onClick={() => {
                                    this.subscribeMe();
                                }} disabled={notesStore.BuySubscriptionModel.submitLoading}>Make Payment and Read Notes</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Footer />
            </div>
        );
    }
}

export default NotesDetail;